import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          
          <path d="M5055 9554 c-71 -7 -164 -18 -205 -23 l-75 -10 98 5 c56 2 96 1 93
-4 -3 -5 -82 -11 -176 -14 -110 -3 -175 -9 -183 -17 -8 -8 5 -10 53 -6 l65 5
-54 -15 c-41 -12 -62 -13 -92 -5 -41 11 -93 4 -83 -11 3 -5 10 -6 16 -3 7 4 8
2 4 -4 -4 -7 -14 -12 -22 -12 -8 0 -14 -4 -14 -10 0 -6 -32 -10 -75 -10 -43 0
-75 -4 -75 -10 0 -6 58 -10 155 -10 146 0 155 -1 155 -19 0 -11 -6 -21 -12
-23 -24 -8 -303 4 -306 13 -2 5 -13 9 -24 9 -14 0 -18 5 -14 15 3 9 0 15 -8
15 -20 0 -26 -11 -15 -31 9 -17 6 -19 -33 -18 -42 1 -42 1 -15 12 15 6 27 15
27 19 0 5 -12 5 -27 2 -37 -9 -44 -14 -38 -24 3 -5 1 -11 -5 -15 -15 -9 -13
-142 3 -147 6 -2 134 -6 282 -7 149 -1 290 -6 315 -11 51 -9 96 -8 106 3 4 4
21 6 38 6 l31 -2 -35 -7 c-28 -6 -20 -8 40 -9 55 -2 70 -5 55 -12 -13 -5 32
-7 119 -3 126 6 142 9 158 28 9 12 17 15 18 9 0 -7 11 -13 24 -13 13 0 27 6
29 13 4 10 6 10 6 0 1 -9 24 -13 82 -13 44 0 78 4 75 8 -2 4 6 8 20 8 13 0 23
-5 21 -10 -2 -7 -59 -13 -143 -16 l-139 -6 165 -2 c116 -1 160 2 148 9 -26 15
-22 32 4 18 13 -7 49 -10 95 -7 40 3 73 1 73 -4 0 -13 44 -10 59 5 11 10 14
10 18 0 3 -8 21 -12 46 -12 l42 2 -35 7 c-30 6 -26 8 28 9 38 0 62 -3 62 -10
0 -5 4 -8 9 -5 4 3 57 7 117 10 60 2 97 2 82 -1 -16 -2 -28 -9 -28 -14 0 -5
-19 -9 -42 -9 -23 0 -39 -3 -35 -6 12 -13 139 8 153 24 12 15 42 17 243 16
l229 0 6 28 c5 23 11 28 36 28 16 0 30 -4 30 -10 0 -5 9 -10 20 -10 28 0 26
-36 -2 -44 -13 -3 -35 -6 -50 -6 -18 0 -28 -5 -28 -15 0 -13 -24 -15 -155 -15
-97 0 -155 -4 -155 -10 0 -6 32 -10 75 -10 43 0 75 -4 75 -10 0 -5 12 -10 26
-10 14 0 23 -4 19 -9 -3 -5 5 -12 17 -14 17 -3 14 -5 -10 -6 -18 0 -31 3 -28
8 4 5 -3 7 -15 4 -12 -3 -25 0 -29 7 -6 10 -19 11 -62 2 l-53 -11 50 4 c28 3
43 2 35 -2 -9 -4 15 -12 55 -20 45 -8 73 -9 79 -3 13 13 76 13 76 1 0 -5 -12
-11 -27 -14 -16 -2 -7 -4 19 -4 27 0 54 -6 62 -12 8 -7 26 -10 40 -7 15 3 24
1 21 -5 -4 -5 3 -9 14 -9 10 0 21 5 23 11 3 8 9 6 20 -4 8 -9 27 -19 42 -23
21 -5 25 -3 20 10 -3 9 -1 16 6 16 6 0 9 -4 6 -9 -10 -15 17 -29 78 -40 67
-12 94 -13 56 -1 l-25 7 25 12 c21 9 16 10 -31 5 -46 -5 -57 -3 -62 10 -4 10
-1 16 9 16 9 0 12 -5 8 -12 -5 -8 -2 -9 12 -4 10 4 59 11 107 16 95 9 111 14
78 25 -36 11 -23 26 17 20 33 -5 35 -4 12 6 -14 6 -33 15 -42 20 -10 5 -34 9
-53 9 -19 0 -35 3 -35 8 0 4 18 6 40 4 26 -3 40 0 40 8 0 7 8 6 22 -4 12 -9
32 -16 43 -16 15 1 11 6 -16 20 -27 14 -44 17 -72 10 -21 -4 -39 -4 -43 1 -3
5 9 9 27 9 38 0 28 19 -12 24 -13 1 -40 12 -59 24 -19 12 -38 22 -42 22 -13 0
-9 -12 9 -25 14 -10 8 -12 -36 -10 -36 1 -58 8 -74 23 -12 11 -31 22 -42 24
-14 2 -20 11 -20 30 1 22 -6 28 -40 39 -22 6 -59 11 -83 10 -30 -2 -44 2 -48
13 -3 9 -18 16 -33 17 -34 1 -63 12 -61 24 0 6 -7 12 -17 14 -15 3 -16 1 -4
-13 12 -15 11 -16 -18 -14 -17 1 -31 6 -31 10 0 17 6 23 20 20 13 -2 13 -2 0
6 -8 5 -33 12 -55 16 -31 4 -35 4 -17 -4 25 -10 21 -33 -3 -23 -8 3 -15 0 -15
-5 0 -6 8 -13 18 -15 9 -3 -49 -6 -130 -7 -82 -1 -176 -3 -210 -4 -35 -1 -69
2 -75 7 -9 6 -13 36 -13 82 0 64 -2 73 -17 70 -10 -1 -24 2 -31 8 -8 7 -16 7
-20 1 -11 -15 -92 -15 -92 0 0 16 -49 23 -195 30 -81 4 -89 3 -45 -4 30 -5 88
-12 128 -16 40 -4 74 -10 77 -13 5 -8 -94 -6 -115 2 -8 3 -49 8 -90 11 -47 3
-66 2 -50 -4 21 -7 17 -8 -22 -9 -28 -1 -48 3 -48 10 0 5 -6 7 -12 3 -9 -5
-10 -4 -3 4 6 5 19 12 30 15 28 6 -168 -4 -237 -12 -50 -7 -56 -6 -48 8 7 14
2 15 -38 12 -26 -3 -37 -7 -24 -9 12 -2 22 -7 22 -12 0 -5 -7 -7 -16 -3 -9 3
-19 2 -23 -4 -3 -6 -38 -7 -86 -4 l-80 7 78 8 c42 4 77 12 77 17 0 5 -1 8 -2
8 -2 -1 -61 -8 -133 -15z m423 -31 c-21 -2 -57 -2 -80 0 -24 2 -7 4 37 4 44 0
63 -2 43 -4z m-918 -63 c0 -5 10 -10 22 -10 12 0 19 -3 15 -6 -9 -9 -68 7 -61
17 7 12 24 12 24 -1z m160 0 c0 -5 -4 -10 -9 -10 -5 0 -24 -2 -42 -5 l-34 -5
35 14 c43 18 50 19 50 6z m2120 -168 c24 -10 24 -11 -10 -11 -50 -1 -110 10
-105 19 6 10 85 5 115 -8z m80 -82 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0
6 9 10 20 10 11 0 20 -4 20 -10z m-1860 -19 c25 -7 25 -7 3 -13 -13 -3 -23 -2
-23 2 0 5 -10 11 -22 13 -19 4 -20 4 -3 5 11 0 31 -3 45 -7z m140 -8 c2 -2
-21 -3 -52 -2 -32 1 -55 6 -53 9 5 8 96 1 105 -7z m1535 -73 c3 -6 -5 -10 -19
-10 -14 0 -28 5 -31 10 -3 6 5 10 19 10 14 0 28 -4 31 -10z m65 -20 c0 -13
-11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z"/>
<path d="M5310 9561 c9 -5 48 -6 90 -2 l75 8 -90 1 c-57 1 -84 -2 -75 -7z"/>
<path d="M6090 9501 c21 -12 129 -24 110 -13 -8 5 -40 12 -70 15 -32 4 -49 3
-40 -2z"/>
<path d="M6093 9473 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6203 9467 c-24 -6 -43 -13 -43 -16 0 -3 60 -6 133 -7 124 -1 128 2
36 20 -63 12 -87 13 -126 3z m62 -7 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0
6 4 10 9 10 6 0 13 -4 16 -10z"/>
<path d="M4468 9465 c-3 -3 -25 -10 -49 -15 l-44 -9 49 4 c49 5 64 10 54 20
-2 3 -7 3 -10 0z"/>
<path d="M6093 9443 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4300 9410 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6250 9410 c0 -6 30 -10 70 -10 40 0 70 4 70 10 0 6 -30 10 -70 10
-40 0 -70 -4 -70 -10z"/>
<path d="M4050 9340 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M6735 9340 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M3985 9319 c-10 -15 0 -21 27 -17 l23 3 -22 13 c-15 8 -24 9 -28 1z"/>
<path d="M4058 9313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3942 9301 c-8 -4 -13 -11 -13 -15 0 -3 -2 -13 -6 -21 -3 -9 -3 -18
0 -22 9 -9 41 27 42 48 2 21 -3 23 -23 10z"/>
<path d="M3898 9283 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3988 9260 c2 -16 6 -30 8 -30 2 0 4 14 4 30 0 17 -4 30 -8 30 -5 0
-7 -13 -4 -30z"/>
<path d="M4033 9283 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6960 9261 c0 -4 12 -13 26 -19 14 -7 22 -17 19 -22 -3 -6 -2 -10 4
-10 19 0 21 17 3 32 -21 19 -52 30 -52 19z"/>
<path d="M3795 9245 c-16 -7 -23 -14 -15 -14 8 0 26 6 40 14 32 18 14 18 -25
0z"/>
<path d="M3723 9218 c-13 -6 -23 -14 -23 -18 0 -4 -16 -11 -35 -14 -24 -5 -39
-14 -44 -29 -5 -13 -27 -32 -50 -42 -22 -11 -41 -23 -41 -27 0 -5 34 -8 75 -8
67 0 75 -2 75 -19 0 -15 11 -22 50 -30 62 -13 63 -13 55 -1 -3 6 4 10 16 10
27 0 23 -4 -27 -31 -36 -19 -37 -20 -9 -14 17 3 60 10 98 16 51 9 65 14 57 24
-7 8 -5 12 5 12 8 0 15 -4 15 -9 0 -13 40 -9 45 5 2 6 5 46 7 88 l3 75 -30 -2
c-16 -1 -49 -5 -73 -9 -39 -7 -44 -6 -49 14 -3 11 -10 21 -15 21 -6 0 -8 -7
-4 -15 6 -18 -15 -20 -32 -3 -8 8 -15 8 -26 -1 -9 -7 -21 -11 -28 -8 -8 4 -7
6 5 6 9 1 17 6 17 11 0 12 -8 12 -37 -2z m-43 -58 c0 -5 -9 -10 -21 -10 -11 0
-17 5 -14 10 3 6 13 10 21 10 8 0 14 -4 14 -10z m-10 -30 c11 -7 6 -10 -20
-10 -26 0 -31 3 -20 10 8 5 17 10 20 10 3 0 12 -5 20 -10z m230 -92 c0 -5 -6
-8 -14 -8 -8 0 -20 -2 -28 -3 -7 -2 -9 -2 -4 1 4 2 3 8 -4 12 -7 5 1 7 19 6
17 0 31 -4 31 -8z"/>
<path d="M6960 9220 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M4595 9178 c21 -5 42 -13 46 -19 3 -6 35 -12 70 -15 57 -3 60 -2 29
7 -19 6 -51 13 -70 17 l-35 6 45 6 c33 5 22 6 -40 6 -71 0 -78 -1 -45 -8z"/>
<path d="M6088 9183 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4753 9173 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5620 9176 c0 -2 14 -6 30 -8 17 -3 30 -1 30 4 0 4 -13 8 -30 8 -16
0 -30 -2 -30 -4z"/>
<path d="M5715 9169 c-4 -5 24 -9 68 -9 41 0 90 -2 108 -5 34 -5 34 -5 4 4
-46 14 -174 21 -180 10z"/>
<path d="M6013 9173 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4860 9160 l-65 -7 65 0 c36 0 79 3 95 7 l30 8 -30 -1 c-16 0 -59 -3
-95 -7z"/>
<path d="M4163 9142 c-5 -21 -38 -37 -110 -53 -47 -11 -53 -15 -53 -38 0 -15
4 -21 8 -15 13 20 49 33 83 30 19 -2 24 -1 12 1 -13 2 -23 9 -23 14 0 12 67
12 75 -1 6 -10 44 -7 105 7 22 5 20 7 -20 19 -39 12 -40 13 -10 9 19 -2 55 -7
80 -10 25 -3 65 -1 90 4 42 9 41 10 -17 5 -53 -5 -63 -4 -63 10 0 13 13 16 75
16 43 0 75 4 75 10 0 6 -57 10 -151 10 -136 0 -151 -2 -156 -18z m40 -49 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M5968 9153 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4560 9140 c0 -12 17 -12 45 0 17 7 15 9 -12 9 -18 1 -33 -3 -33 -9z"/>
<path d="M6048 9143 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6205 9140 c27 -12 35 -12 35 0 0 6 -12 10 -27 9 -25 0 -26 -1 -8 -9z"/>
<path d="M3540 9130 c-12 -8 -11 -10 7 -10 12 0 25 5 28 10 8 13 -15 13 -35 0z"/>
<path d="M6128 9133 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3496 9105 c-11 -8 -26 -15 -34 -15 -18 0 -63 -36 -55 -44 12 -12 32
-6 27 8 -3 9 9 17 40 26 26 7 46 19 46 26 0 17 0 17 -24 -1z"/>
<path d="M4000 9110 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M6383 9113 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7300 9090 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M7360 9071 c0 -6 -6 -8 -14 -5 -8 3 -17 0 -21 -6 -10 -17 1 -23 22
-12 20 10 46 5 38 -8 -3 -4 4 -6 16 -3 21 6 19 9 -23 34 -12 6 -18 6 -18 0z"/>
<path d="M3502 9063 c-6 -2 -10 -8 -8 -11 2 -4 -6 -8 -17 -9 -82 -9 -117 -16
-117 -23 0 -5 -9 -6 -20 -3 -11 3 -20 0 -20 -6 0 -13 2 -13 113 -11 57 1 87
-2 87 -9 0 -7 28 -11 75 -11 43 0 75 4 75 10 0 6 -32 10 -75 10 -79 0 -83 3
-76 58 1 6 1 12 -1 11 -2 0 -9 -3 -16 -6z"/>
<path d="M7463 9007 c21 -18 24 -26 15 -35 -9 -9 -9 -12 3 -12 8 0 19 4 25 10
6 6 15 6 24 0 10 -7 11 -10 3 -10 -7 0 -13 -9 -13 -20 0 -14 7 -20 21 -20 16
0 20 5 16 20 -3 10 -1 20 5 22 10 4 -59 44 -102 59 -25 9 -25 9 3 -14z"/>
<path d="M3688 9013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3256 8974 c-11 -8 -16 -14 -10 -14 13 0 39 18 34 24 -3 2 -14 -2
-24 -10z"/>
<path d="M3290 8985 c0 -3 10 -10 23 -15 17 -7 18 -9 5 -9 -28 -1 -21 -21 6
-21 33 0 43 18 20 35 -19 14 -54 20 -54 10z"/>
<path d="M3680 8981 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"/>
<path d="M7440 8980 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3378 8953 c-18 -5 -25 -23 -9 -23 5 0 22 7 37 15 22 11 24 15 9 14
-11 -1 -28 -3 -37 -6z"/>
<path d="M3475 8953 c22 -3 43 -10 47 -16 4 -6 10 -8 15 -4 13 14 -20 27 -62
25 l-40 -1 40 -4z"/>
<path d="M3550 8950 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M7305 8951 c-3 -5 -22 -8 -43 -5 -42 5 -28 -2 63 -29 82 -25 118 -31
111 -18 -4 6 -16 11 -26 11 -10 0 -26 3 -35 6 -13 6 -14 9 -3 16 9 7 7 8 -8 3
-25 -7 -56 3 -48 16 3 5 3 9 0 9 -3 0 -7 -4 -11 -9z"/>
<path d="M7338 8953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7388 8953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3233 8928 c-42 -26 -43 -42 -2 -34 17 3 35 6 40 6 5 0 9 11 9 25 0
29 -5 30 -47 3z"/>
<path d="M3163 8916 c-34 -23 -36 -37 -4 -29 16 4 46 41 39 48 -2 2 -18 -7
-35 -19z"/>
<path d="M3424 8928 c-2 -3 2 -9 8 -11 7 -3 -5 -6 -28 -6 -28 -1 -41 -5 -41
-15 0 -10 6 -12 21 -6 12 4 41 14 66 21 63 17 72 29 21 26 -23 -1 -45 -5 -47
-9z"/>
<path d="M7410 8930 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"/>
<path d="M7614 8912 c-6 -4 -13 -17 -16 -29 -5 -20 -1 -23 38 -29 24 -3 44
-11 44 -16 0 -5 -14 -7 -32 -3 -27 5 -30 4 -15 -5 10 -7 30 -9 44 -5 20 5 24
3 20 -9 -7 -19 4 -21 20 -5 17 17 -33 59 -68 59 -15 0 -31 5 -34 10 -4 6 7 10
27 10 31 1 32 1 14 15 -22 17 -27 18 -42 7z"/>
<path d="M3180 8880 c0 -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 0 -11 49 -14
74 -4 8 3 12 12 9 20 -6 16 -23 19 -23 4z"/>
<path d="M3243 8865 c0 -9 9 -10 36 -3 58 15 58 16 10 16 -31 0 -46 -4 -46
-13z"/>
<path d="M7481 8876 c10 -10 57 -14 70 -5 9 6 0 9 -30 9 -24 0 -42 -2 -40 -4z"/>
<path d="M3055 8846 c-25 -20 -27 -26 -17 -39 9 -10 10 -17 2 -22 -5 -3 -13 1
-16 9 -10 26 -37 20 -29 -5 6 -19 4 -21 -19 -17 -14 3 -23 1 -20 -3 3 -5 -4
-9 -14 -9 -11 0 -25 -7 -33 -16 -11 -13 -9 -14 17 -8 16 4 36 11 45 15 9 5 39
16 65 25 27 9 52 21 55 26 3 5 20 12 38 14 17 3 49 12 69 20 33 12 34 13 7 9
-16 -3 -48 -7 -70 -9 -22 -3 -45 -12 -51 -21 -14 -19 -34 -20 -34 -2 0 8 11
19 25 25 14 6 25 16 25 22 0 16 -12 12 -45 -14z"/>
<path d="M7725 8850 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M7578 8843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7757 8826 c-4 -10 -1 -16 8 -16 17 0 20 -16 5 -25 -5 -3 -10 -1 -10
4 0 6 -10 11 -22 11 -22 0 -22 0 2 -13 14 -8 34 -16 45 -19 11 -3 29 -9 40
-13 11 -3 7 2 -10 11 -17 10 -25 19 -17 21 17 6 15 23 -3 23 -7 0 -18 7 -23
16 -8 15 -10 15 -15 0z"/>
<path d="M7820 8786 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M7850 8752 c0 -4 14 -13 31 -19 31 -12 31 -12 14 7 -18 20 -45 27
-45 12z"/>
<path d="M5328 8643 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4965 8620 l-50 -7 63 -1 c55 -2 62 -4 62 -22 0 -17 7 -20 40 -20 33
0 40 3 40 20 0 17 -7 20 -40 20 -22 0 -40 5 -40 10 0 6 -6 9 -12 8 -7 0 -35
-4 -63 -8z"/>
<path d="M5600 8621 c0 -6 11 -11 24 -11 14 0 28 -4 31 -10 4 -6 -7 -10 -24
-10 -17 0 -31 -4 -31 -10 0 -11 42 -13 89 -4 36 8 41 21 9 27 -13 2 -6 5 17 6
22 0 53 -6 69 -14 35 -19 44 -19 62 0 13 13 12 14 -15 9 -18 -4 -37 -2 -44 5
-8 6 -46 10 -89 9 -41 0 -80 2 -87 6 -6 4 -11 2 -11 -3z"/>
<path d="M4877 8594 c6 -16 -72 -27 -185 -25 -52 1 -99 -2 -105 -6 -14 -8 -26
-11 -87 -19 -62 -8 -75 -24 -19 -25 41 -1 42 -1 14 -10 -27 -8 -48 -6 -100 8
-17 5 -18 4 -7 -10 8 -9 32 -18 55 -20 26 -2 18 -4 -20 -3 -47 0 -63 4 -63 14
0 12 -3 12 -15 2 -8 -7 -15 -23 -15 -36 0 -23 3 -24 75 -24 67 0 75 -2 75 -19
0 -25 -12 -28 -100 -25 -41 2 -126 5 -187 6 -62 2 -113 6 -113 11 0 4 -4 7
-10 7 -5 0 -7 -7 -4 -15 5 -12 -2 -15 -29 -15 -19 0 -50 -7 -68 -15 l-34 -14
35 -1 c24 0 31 -3 21 -9 -7 -5 -10 -14 -7 -20 4 -6 0 -11 -8 -11 -9 0 -15 3
-13 8 1 4 0 9 -3 12 -7 6 -110 -43 -110 -52 0 -5 34 -8 75 -8 67 0 75 -2 75
-19 0 -25 -13 -29 -88 -24 -64 4 -111 25 -112 51 0 14 -19 16 -40 2 -12 -8
-10 -10 10 -10 28 0 17 -14 -19 -25 -16 -5 -21 -4 -17 4 8 13 -1 13 -29 1 -22
-9 -12 -15 35 -23 15 -3 8 -5 -17 -6 -24 0 -43 3 -43 9 0 5 -8 6 -17 3 -11 -4
-12 -8 -5 -11 22 -7 12 -21 -28 -42 -47 -24 -51 -32 -11 -24 26 6 28 4 24 -20
-5 -24 -9 -26 -63 -26 -38 0 -62 -5 -70 -15 -10 -12 -8 -15 12 -19 47 -11 113
-1 116 17 4 21 42 23 42 2 0 -10 11 -15 38 -16 20 -1 55 -3 77 -5 22 -2 77 -4
122 -4 45 -1 82 -5 82 -8 -4 -23 2 -42 11 -37 6 3 7 17 4 30 -5 22 -4 23 56
22 66 0 71 -3 72 -47 1 -16 5 -27 10 -24 4 3 8 0 8 -6 0 -16 -39 -2 -64 23
-12 11 -15 16 -7 12 10 -6 12 -4 7 4 -6 8 -12 9 -20 2 -7 -6 -16 -8 -19 -4 -4
3 -7 1 -7 -5 0 -7 5 -12 11 -12 5 0 7 -4 4 -10 -3 -5 -1 -10 5 -10 8 0 8 -6 0
-21 -7 -11 -9 -23 -6 -26 3 -3 8 2 12 11 4 11 19 16 51 16 39 0 44 -2 38 -19
-9 -28 5 -51 31 -51 21 0 24 -4 24 -40 0 -38 -2 -40 -30 -40 -29 0 -30 2 -30
42 0 54 -6 46 -13 -16 -3 -29 -1 -43 4 -35 5 9 8 1 7 -24 -1 -23 5 -42 14 -50
21 -16 31 -74 15 -81 -7 -3 -2 -3 11 0 12 3 22 1 22 -4 0 -5 -10 -17 -22 -26
-22 -15 -22 -16 -5 -29 18 -15 24 -53 7 -42 -7 4 -8 -3 -4 -22 9 -37 13 -43
27 -42 9 0 9 2 0 6 -7 2 -13 17 -12 31 0 25 1 25 10 7 7 -15 8 -11 3 15 -4 26
-3 31 5 20 6 -8 11 -10 11 -4 0 15 43 26 64 18 10 -4 16 -18 16 -36 0 -17 -4
-27 -10 -23 -5 3 -10 0 -10 -9 0 -8 6 -17 13 -19 9 -4 8 -8 -3 -16 -16 -11
-44 5 -35 19 3 5 -4 6 -15 3 -19 -5 -19 -7 9 -24 16 -10 31 -28 33 -41 2 -15
10 -23 23 -24 67 -3 75 -5 75 -19 0 -8 -8 -15 -18 -15 -22 0 -52 -30 -52 -51
0 -8 -6 -21 -12 -28 -10 -11 -10 -13 0 -7 8 5 12 0 12 -13 0 -12 4 -21 9 -21
5 0 17 -3 27 -7 12 -4 15 -3 10 5 -5 8 -2 10 8 6 9 -3 16 -14 16 -23 1 -14 3
-13 15 3 11 15 17 17 29 7 9 -7 17 -8 20 -2 3 5 0 12 -6 14 -8 4 -7 6 4 6 9 1
18 -5 21 -11 2 -7 7 -10 11 -6 4 3 3 15 -3 26 -16 30 -14 62 4 62 10 0 15 -10
15 -29 0 -17 5 -33 10 -36 16 -10 23 21 10 46 -12 21 -11 22 21 17 l34 -6 -3
43 -4 42 44 6 c23 3 57 5 75 3 21 -2 30 1 26 10 -2 6 0 15 6 19 6 3 11 11 11
16 0 6 -4 8 -8 5 -5 -3 -10 19 -11 49 l-2 55 41 0 c29 0 40 4 40 15 0 11 11
15 40 15 37 0 39 2 46 35 3 19 10 32 15 29 13 -8 11 9 -5 47 -7 18 -22 34 -32
35 -17 3 -18 2 -2 -10 9 -6 17 -18 18 -26 5 -36 -1 -68 -14 -73 -21 -8 -73 7
-58 17 7 4 12 12 12 17 0 6 -5 7 -11 3 -8 -4 -8 0 0 15 16 28 6 41 -29 41 -15
0 -30 6 -33 13 -3 9 -9 9 -28 0 -46 -23 -69 -25 -75 -9 -8 22 -84 22 -84 1 0
-11 -11 -15 -40 -15 -36 0 -42 4 -39 26 1 9 -23 24 -38 24 -7 0 -13 -4 -13
-10 0 -5 7 -10 15 -10 8 0 15 -4 15 -9 0 -13 -99 -8 -105 7 -6 16 -46 15 -71
-2 -27 -19 -44 -7 -41 29 2 23 7 30 25 31 19 1 19 1 2 -3 -11 -2 -20 -9 -20
-14 0 -12 3 -11 37 3 15 6 32 10 36 9 5 -1 6 3 2 9 -3 5 3 10 14 10 12 0 21 5
21 11 0 6 -9 8 -20 4 -11 -3 -20 -2 -20 3 0 13 62 22 76 11 5 -4 23 -5 39 -1
36 10 20 22 -30 24 -19 1 -35 5 -35 10 0 4 -4 8 -10 8 -5 0 -9 -6 -7 -12 1 -7
-4 -13 -12 -13 -27 0 -22 43 10 74 31 32 49 39 49 21 0 -15 77 -12 83 3 2 7 8
8 13 3 5 -5 4 -11 -3 -13 -7 -3 -13 -11 -13 -19 0 -12 4 -12 20 -2 16 10 24
10 40 0 11 -7 20 -9 20 -4 0 4 17 12 38 18 40 11 102 1 102 -16 0 -6 5 -10 10
-10 14 0 12 28 -2 33 -7 2 -4 3 7 1 11 -2 56 -5 100 -7 44 -2 59 -2 33 0 -36
3 -48 8 -49 21 0 10 -3 12 -6 5 -2 -7 -12 -13 -21 -13 -15 0 -15 2 -2 11 12 8
10 9 -7 4 -42 -12 -122 -14 -128 -4 -12 18 16 39 51 39 19 0 34 5 34 10 0 6 9
10 19 10 11 0 22 4 25 9 5 7 59 11 211 15 31 1 40 -3 43 -18 3 -15 -1 -18 -22
-14 -17 3 -26 1 -26 -7 0 -12 31 -11 70 1 13 5 13 6 0 14 -8 6 -10 10 -5 10 6
0 17 -6 24 -12 10 -11 12 -10 9 2 -2 10 6 16 25 18 22 3 27 -1 27 -17 0 -12
-7 -21 -15 -21 -13 0 -21 -22 -12 -33 1 -2 -4 -11 -12 -21 -16 -19 -6 -35 11
-18 13 13 50 15 86 6 15 -4 22 -14 22 -31 0 -14 4 -22 11 -18 5 4 22 4 36 0
19 -4 24 -11 20 -25 -3 -10 0 -21 5 -22 6 -2 12 -28 15 -58 4 -51 3 -54 -11
-36 -23 29 -26 18 -12 -49 10 -47 14 -55 18 -35 3 14 5 -2 3 -35 l-2 -61 66 1
c36 1 72 -1 79 -3 9 -3 12 -45 12 -163 0 -87 -3 -160 -7 -160 -5 -1 -39 -2
-76 -3 -73 -2 -94 11 -81 51 4 10 -10 -6 -30 -36 -28 -43 -33 -58 -24 -69 10
-11 14 -10 29 9 17 22 17 22 24 -20 6 -37 4 -46 -16 -68 -21 -22 -30 -24 -71
-20 -43 5 -47 4 -57 -21 -5 -15 -11 -36 -13 -47 -2 -11 -12 -23 -23 -27 -19
-6 -19 -6 3 -13 31 -10 28 -21 -9 -29 -17 -3 -28 -10 -25 -15 3 -5 -1 -11 -9
-15 -10 -3 -15 1 -15 11 0 14 -2 14 -20 -2 -13 -11 -20 -31 -20 -52 0 -30 -3
-34 -22 -33 -43 4 -48 0 -48 -34 0 -19 -3 -37 -7 -39 -5 -3 -35 -8 -67 -12
-47 -6 -57 -5 -51 5 6 10 4 12 -9 7 -10 -4 -20 -7 -22 -7 -2 0 -4 -4 -4 -10 0
-5 6 -7 13 -5 9 4 13 -14 15 -70 2 -55 -1 -75 -10 -76 -7 -1 -41 0 -75 1 -46
1 -63 -2 -70 -14 -7 -12 -10 -10 -20 10 -10 22 -10 25 3 20 8 -3 14 1 14 9 0
18 -14 19 -28 2 -8 -9 -5 -25 9 -57 15 -32 19 -56 15 -82 -4 -27 -2 -38 6 -39
7 -1 43 -2 80 -3 37 -1 69 -4 72 -6 2 -3 6 -87 8 -188 1 -101 1 -173 -2 -161
-3 12 -12 25 -20 27 -12 4 -13 2 -4 -9 6 -8 14 -27 18 -43 6 -24 4 -27 -9 -22
-9 3 -13 2 -10 -4 3 -6 -2 -11 -12 -11 -54 -4 -145 -6 -400 -7 -156 -1 -283
-5 -283 -9 0 -12 683 -4 694 7 15 15 26 12 26 -7 0 -17 -21 -18 -325 -15 -178
2 -331 0 -339 -5 -8 -4 126 -8 297 -8 301 0 388 -6 357 -25 -7 -4 -23 -6 -34
-4 -12 2 -86 5 -166 7 -79 1 -136 1 -127 -1 13 -3 17 -14 17 -46 0 -24 3 -58
6 -75 6 -27 11 -31 38 -31 21 0 27 -3 18 -9 -10 -6 -7 -10 12 -13 14 -3 26 -2
26 2 0 17 53 20 82 5 17 -8 38 -15 47 -14 10 0 12 2 3 6 -20 8 -3 23 26 22 15
0 21 -3 14 -6 -27 -11 2 -23 51 -21 l52 1 -45 6 c-44 6 -44 7 29 14 53 6 76 5
81 -3 5 -9 17 -9 44 -2 20 6 34 7 31 2 -3 -4 6 -11 20 -14 18 -5 25 -13 25
-30 0 -30 -10 -39 -28 -23 -13 10 -14 8 -7 -12 5 -17 4 -22 -6 -18 -8 2 -13 9
-12 14 1 5 -7 10 -18 10 -10 1 -19 -3 -19 -8 0 -6 4 -8 8 -5 9 5 37 -52 29
-60 -2 -3 -14 11 -26 31 -31 53 -76 68 -183 63 -97 -5 -106 0 -26 13 l53 8
-64 -4 c-40 -3 -69 -1 -78 6 -7 7 -16 9 -20 5 -4 -3 2 -12 12 -20 19 -14 12
-41 -9 -33 -17 7 -43 -10 -33 -20 5 -4 13 -1 18 7 8 11 9 9 4 -8 -4 -13 -4
-40 0 -60 6 -31 9 -34 15 -17 4 11 6 36 4 55 l-4 35 11 -30 c5 -16 11 -36 13
-43 4 -23 29 -32 85 -32 39 0 58 5 68 18 14 16 14 16 9 0 -3 -12 0 -18 9 -18
8 0 15 -3 15 -7 0 -5 -36 -8 -79 -8 -68 0 -84 -3 -111 -24 l-32 -23 7 23 c6
23 6 23 -9 5 -15 -19 -16 -18 -16 21 0 101 -58 154 -169 156 -67 1 -98 -8
-104 -31 -3 -9 -4 24 -4 73 1 89 1 89 6 23 5 -57 8 -66 21 -57 11 8 12 12 3
16 -8 3 -13 23 -13 49 0 60 -16 85 -47 73 -16 -6 -32 -5 -44 2 -27 14 -49 5
-49 -20 0 -14 -5 -19 -20 -16 -24 5 -37 -13 -16 -21 14 -5 16 -36 17 -223 1
-119 4 -227 8 -241 6 -22 14 -26 61 -31 51 -5 52 -6 13 -7 -23 -1 -44 -5 -47
-10 -9 -15 79 0 92 16 8 11 12 55 12 136 0 95 3 123 16 137 9 10 22 15 30 12
21 -8 17 -23 -6 -23 -11 0 -22 -6 -25 -12 -2 -7 -5 -74 -7 -148 -3 -95 -1
-125 6 -102 5 17 13 32 17 32 5 0 8 33 7 73 l-1 72 -7 -65 -7 -65 -1 90 c-2
96 5 119 31 109 13 -5 17 0 17 21 0 27 1 28 20 10 17 -15 20 -33 22 -144 l3
-126 63 -3 c62 -3 62 -3 63 25 2 27 2 27 6 3 8 -50 12 -54 33 -35 20 18 20 17
18 -8 -2 -22 -7 -27 -30 -27 -15 0 -28 4 -28 9 0 4 -18 3 -40 -2 -33 -9 -42
-8 -58 6 -23 21 -32 22 -32 2 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -4
15 -10 15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -4 10 -10 0 -5 5 -10 10
-10 12 0 12 84 0 120 -6 19 -8 11 -9 -34 -1 -47 -4 -57 -16 -52 -12 4 -15 -3
-16 -32 -1 -37 -1 -37 -9 -7 -7 29 -14 18 -11 -17 2 -16 -9 -17 -98 -17 -57 1
-101 5 -101 11 0 5 -6 6 -12 1 -7 -4 -35 -9 -63 -10 -39 -1 -46 1 -32 9 11 5
30 8 45 5 31 -6 61 18 43 36 -8 8 -11 7 -11 -6 0 -14 -11 -17 -70 -17 -38 0
-70 -4 -70 -9 0 -5 10 -7 23 -4 20 4 20 3 4 -7 -23 -14 -44 1 -31 22 6 8 30
13 69 13 l60 0 5 268 c4 200 2 267 -7 267 -6 0 -13 4 -15 9 -2 6 -24 5 -55 -1
-31 -6 -50 -15 -46 -21 3 -5 1 -7 -5 -4 -6 5 -9 46 -6 118 4 114 8 134 25 124
12 -8 11 6 -1 25 -8 13 -10 12 -10 -2 0 -13 -8 -18 -26 -18 -14 0 -23 4 -20 9
3 5 -1 11 -9 15 -8 3 -19 -2 -24 -11 -8 -15 -13 -15 -36 -5 -36 17 -53 15 -29
-3 19 -14 19 -14 -2 -15 -11 0 -29 -7 -40 -15 -17 -13 -17 -14 -1 -15 9 0 17
-6 17 -14 0 -15 -58 -30 -82 -21 -8 3 -21 -1 -28 -10 -7 -8 -19 -15 -26 -15
-8 0 -14 -4 -14 -10 0 -6 32 -10 75 -10 73 0 75 -1 75 -25 0 -14 -5 -24 -12
-23 -18 4 -22 -87 -5 -121 12 -25 12 -32 -2 -55 -11 -19 -13 -29 -5 -34 7 -4
14 -16 17 -27 3 -11 3 0 0 24 -4 30 -2 41 6 37 6 -4 11 -1 11 6 0 10 17 13 68
12 37 -1 70 -2 75 -3 4 0 6 -81 6 -178 -1 -98 -4 -168 -6 -155 -7 33 -20 27
-15 -8 3 -18 0 -30 -7 -30 -6 0 -11 11 -11 25 0 14 -5 25 -11 25 -6 0 -9 -8
-6 -17 2 -10 6 -25 8 -33 2 -8 9 -18 14 -22 6 -5 5 -8 -2 -8 -15 0 -33 32 -33
59 0 11 -11 37 -25 57 -14 20 -25 45 -25 55 0 10 -4 21 -10 24 -13 8 -13 35 0
35 6 0 10 -8 10 -17 1 -10 10 -36 21 -58 l20 -40 -7 35 c-3 19 -10 39 -15 44
-12 13 -11 46 1 46 6 0 10 -6 11 -12 0 -7 4 -4 9 7 8 19 9 19 9 -3 1 -32 19
-62 36 -62 8 0 15 5 15 10 0 6 -4 10 -10 10 -5 0 -10 10 -10 23 0 40 -14 51
-49 39 -20 -7 -31 -7 -31 -1 0 6 -8 6 -20 -1 -11 -6 -20 -7 -19 -3 1 4 -12 39
-30 77 -22 47 -30 77 -26 93 4 18 0 30 -16 44 -12 10 -18 19 -13 19 5 0 -2 7
-14 16 -16 11 -31 14 -50 8 -15 -4 -31 -8 -37 -8 -5 -1 -16 -8 -22 -16 -11
-13 -14 -13 -23 0 -8 13 -10 13 -10 -2 0 -10 4 -18 9 -18 5 0 7 -9 4 -20 -3
-11 -9 -20 -14 -20 -5 0 -8 -3 -7 -7 0 -5 -7 -24 -16 -43 l-17 -35 5 38 c7 53
-7 46 -21 -10 -11 -50 -8 -68 7 -43 14 23 12 10 -9 -44 -11 -29 -21 -43 -24
-35 -3 8 -6 3 -6 -12 -1 -14 -17 -63 -36 -110 -21 -48 -33 -69 -29 -49 3 19
13 48 21 63 8 16 16 36 18 45 2 10 7 27 11 40 4 13 2 22 -5 22 -6 0 -11 -11
-11 -25 0 -37 -20 -30 -21 8 -1 30 -1 30 -8 7 -5 -14 -4 -29 1 -34 11 -13 10
-53 -1 -60 -10 -7 -19 -48 -26 -123 l-5 -53 61 0 61 0 14 38 15 37 97 0 c87 0
97 2 94 18 -4 23 15 22 28 -3 8 -15 7 -32 -5 -66 -9 -26 -13 -49 -10 -53 9 -9
25 21 25 49 0 20 2 22 10 10 12 -19 12 -41 1 -59 -11 -17 -61 -7 -61 13 0 16
-19 25 -37 19 -7 -3 -13 -11 -13 -18 0 -7 -6 -15 -14 -18 -21 -8 -56 4 -56 19
0 8 -7 14 -15 14 -8 0 -15 6 -16 13 -1 6 -4 1 -9 -13 -6 -22 -8 -23 -15 -7 -5
12 -1 25 10 37 23 25 118 28 142 5 18 -18 38 -15 38 6 0 11 -20 14 -90 15 -82
2 -91 0 -102 -20 -7 -11 -13 -34 -13 -51 0 -23 -3 -26 -10 -14 -10 17 -96 25
-119 10 -9 -6 3 -7 31 -4 25 3 54 1 63 -5 13 -7 -8 -9 -75 -8 -70 1 -95 4 -98
15 -3 8 0 11 5 8 14 -9 33 37 23 53 -6 10 -9 6 -13 -11 -3 -17 -5 -14 -6 13 0
20 -4 45 -7 55 -5 17 -5 17 6 0 11 -18 13 -17 26 8 12 22 12 30 1 46 -8 10
-17 16 -20 12 -6 -6 -9 17 -11 100 -1 24 -7 22 117 34 58 5 59 6 53 31 -3 15
-9 47 -12 72 l-6 45 -86 3 c-47 1 -88 5 -90 7 -3 2 3 16 12 29 21 33 21 60 0
52 -9 -3 -17 -6 -19 -6 -3 0 -9 -18 -15 -41 -10 -36 -14 -40 -33 -34 -12 4
-33 9 -47 11 -23 4 -23 3 -5 -6 17 -9 14 -10 -13 -5 -18 2 -31 1 -28 -3 3 -5
-11 -8 -30 -7 -18 1 -34 -2 -34 -7 0 -5 -5 -6 -10 -3 -6 4 -27 1 -48 -5 -72
-22 -68 -20 -52 -30 21 -13 38 -12 20 1 -10 7 -11 9 -2 7 8 -2 13 -7 12 -12
-1 -5 4 -13 12 -17 10 -7 10 -9 1 -9 -8 0 -13 -15 -13 -40 0 -38 -2 -40 -27
-38 -16 1 -48 -1 -73 -5 -25 -4 -61 -3 -80 1 l-35 9 28 13 c49 24 32 35 -50
33 -43 -1 -105 -6 -138 -11 l-60 -9 45 -3 c37 -2 31 -4 -37 -12 -46 -6 -81
-13 -79 -17 3 -4 -11 -7 -29 -7 -18 0 -31 4 -29 8 3 4 15 8 26 8 40 0 13 8
-47 13 -39 4 -50 3 -30 -2 17 -5 24 -9 17 -10 -7 0 -11 -5 -8 -10 7 -10 -13
-9 -29 1 -5 3 -32 4 -60 2 -103 -8 -125 14 -111 110 l7 42 42 -2 c23 -1 79 -5
125 -9 69 -6 82 -5 82 7 0 8 7 15 15 15 8 0 12 -5 8 -10 -3 -5 3 -7 13 -5 16
3 19 14 22 80 l3 76 -28 5 c-15 3 -83 4 -150 3 -66 0 -119 2 -116 6 2 5 -3 8
-11 8 -9 0 -16 -4 -16 -8 0 -13 -44 -12 -52 1 -6 9 -10 8 -17 -2 -8 -12 -16
-13 -44 -4 -21 6 -41 7 -51 1 -13 -8 -16 -4 -16 25 0 50 -11 98 -21 91 -5 -3
-5 -13 1 -24 5 -10 10 -37 10 -59 0 -56 -23 -49 -31 9 l-6 45 -1 -46 -2 -45
-89 0 c-49 1 -86 3 -83 6 12 9 14 62 3 55 -6 -3 -11 -1 -11 6 0 54 -55 -118
-98 -311 -29 -126 -33 -150 -22 -150 6 0 10 14 10 31 0 37 20 109 30 109 12 0
17 -56 19 -216 2 -126 0 -152 -12 -157 -23 -9 -27 1 -27 75 0 90 -18 88 -22
-4 -2 -42 -8 -73 -14 -75 -8 -3 -10 19 -8 73 5 92 -3 95 -11 5 -3 -35 -8 -67
-10 -72 -3 -4 0 -9 7 -11 12 -4 18 -88 6 -88 -5 0 -8 14 -8 32 0 38 -9 32 -14
-10 -3 -16 -12 -42 -21 -56 -10 -14 -13 -26 -7 -26 5 0 16 10 25 23 8 12 18
19 22 15 4 -4 3 -14 -3 -21 -7 -8 -9 -17 -5 -20 3 -4 1 -7 -5 -7 -5 0 -17 -3
-26 -6 -10 -4 -19 3 -27 20 -11 23 -18 26 -67 26 l-55 0 5 70 c3 39 1 67 -4
64 -4 -3 -6 -10 -3 -15 4 -5 1 -9 -5 -9 -6 0 -13 7 -16 15 -5 12 -8 12 -19 2
-8 -9 -38 -13 -84 -12 l-71 1 -1 -35 c-1 -35 -2 -35 -10 9 -9 51 -1 190 12
190 14 0 21 41 8 56 -6 8 -9 18 -5 21 4 4 11 1 16 -7 6 -8 10 -10 10 -5 -1 16
-26 45 -33 38 -4 -3 -7 1 -7 9 0 9 -7 21 -15 28 -12 10 -15 10 -15 -2 0 -8 5
-19 10 -24 12 -12 15 -142 4 -175 l-8 -24 -6 25 c-3 14 -6 -10 -5 -52 0 -43 3
-69 6 -60 3 9 9 -19 14 -63 5 -55 4 -80 -4 -83 -7 -2 -10 -29 -9 -70 l1 -67 6
60 c5 47 8 54 13 35 4 -14 4 -36 1 -49 -4 -17 -3 -22 5 -17 8 5 12 -3 12 -23
0 -21 -5 -31 -15 -31 -10 0 -15 -9 -14 -27 2 -24 2 -25 6 -5 2 12 9 20 14 17
15 -10 10 -55 -6 -61 -8 -4 -15 -11 -15 -16 0 -6 6 -5 15 2 13 11 15 7 15 -28
0 -22 -4 -42 -10 -44 -5 -1 -6 -14 -3 -28 l7 -25 7 25 c5 16 8 -5 8 -55 0 -94
11 -185 21 -185 5 0 7 28 5 63 l-4 62 11 -47 c6 -27 15 -48 20 -48 4 0 9 -12
9 -27 1 -19 3 -22 6 -10 3 10 -2 36 -11 58 -25 61 -22 65 31 63 27 -1 51 -2
56 -3 4 0 6 -99 6 -218 -1 -120 -3 -210 -6 -200 -6 21 -19 22 -27 1 -11 -28
-7 -46 9 -40 10 3 15 -1 15 -13 0 -11 3 -26 6 -35 4 -9 1 -16 -6 -16 -6 0 -14
9 -17 20 -3 11 -9 20 -14 20 -4 0 -2 -15 6 -32 8 -18 19 -32 25 -31 5 2 10 -2
10 -7 0 -6 -5 -10 -11 -10 -6 0 -7 -5 -2 -12 4 -7 8 -17 9 -23 0 -5 2 -16 2
-22 1 -8 16 -13 37 -13 26 0 35 -4 36 -17 0 -11 3 -13 6 -5 2 6 13 12 24 12
18 0 19 -8 19 -110 0 -71 4 -110 11 -110 6 0 8 -9 4 -20 -7 -22 10 -28 19 -7
3 6 4 -6 1 -28 -3 -22 -2 -35 2 -28 5 7 9 -7 11 -35 1 -33 -1 -43 -8 -32 -5 8
-10 23 -10 33 0 9 -4 17 -10 17 -14 0 -14 1 20 -71 19 -39 29 -53 27 -36 -2
15 -1 27 3 27 11 0 22 -68 14 -82 -4 -7 -3 -8 4 -4 7 4 12 26 13 49 1 40 1 41
8 11 10 -41 19 -54 37 -54 9 0 14 -10 14 -28 0 -16 8 -34 20 -42 11 -8 20 -24
20 -35 0 -40 16 -50 80 -51 33 -1 65 -2 71 -3 6 -1 9 -49 8 -140 -1 -109 -5
-138 -15 -134 -7 2 -15 11 -18 19 -3 8 -10 14 -15 14 -6 0 -1 -16 10 -35 12
-19 25 -35 30 -35 5 0 8 -3 7 -7 -1 -5 8 -24 20 -43 l23 -35 -6 40 -6 40 20
-25 c14 -17 28 -24 45 -22 25 3 26 2 26 -57 0 -35 -4 -61 -10 -61 -6 0 -1 -14
12 -30 27 -37 47 -40 23 -4 -12 19 -14 29 -6 37 8 8 11 8 11 -2 0 -7 5 -9 13
-4 6 4 27 8 44 8 32 1 33 0 33 -42 l0 -43 40 0 40 0 -1 -62 c-1 -35 -4 -53 -6
-41 -3 15 -12 23 -24 23 -19 0 -19 -1 -5 -16 22 -22 20 -27 -9 -20 -19 5 -23
3 -19 -8 3 -9 14 -18 25 -21 11 -4 17 -10 15 -14 -3 -4 8 -23 25 -42 22 -26
29 -29 27 -15 -2 11 -7 18 -12 17 -8 -3 -26 17 -26 29 0 3 16 7 37 8 40 3 53
-9 53 -50 0 -24 4 -28 28 -28 34 0 59 -39 39 -59 -9 -9 -9 -14 2 -20 9 -6 11
-18 7 -36 -4 -17 -3 -24 4 -20 5 3 10 1 10 -4 0 -6 11 -11 25 -11 14 0 25 -5
25 -11 0 -6 -9 -8 -22 -3 -21 6 -21 6 -5 -10 9 -9 24 -24 33 -33 16 -16 16
-16 9 6 -6 20 -4 23 9 18 10 -4 16 -1 16 7 0 17 49 31 62 18 12 -12 -1 -62
-16 -62 -16 0 -14 -46 3 -62 11 -11 12 -9 7 9 -4 12 -2 24 4 28 5 3 10 -2 10
-13 0 -23 30 -52 53 -52 11 0 17 -8 17 -22 0 -13 12 -35 25 -50 23 -27 43 -38
30 -19 -3 6 4 9 15 8 11 -1 20 -6 21 -12 3 -47 18 -65 55 -65 29 0 74 -23 74
-37 0 -18 -14 -16 -40 7 -13 11 -28 20 -33 20 -6 0 4 -12 22 -27 55 -46 151
-41 151 7 0 17 42 31 68 22 16 -5 16 -164 1 -179 -14 -14 44 -53 79 -53 12 0
22 -6 22 -13 0 -7 13 -23 30 -35 33 -24 40 -27 40 -12 0 6 -6 10 -13 10 -7 0
-18 9 -25 20 -10 16 -10 20 2 20 8 0 16 6 19 13 2 6 8 9 12 5 4 -4 2 -14 -5
-23 -8 -10 -9 -15 -1 -15 6 0 13 -11 17 -25 5 -21 12 -25 40 -25 27 0 36 -5
44 -24 13 -34 48 -56 80 -49 15 3 31 0 34 -6 4 -6 18 -11 32 -11 21 0 23 -2
12 -14 -11 -12 -16 -12 -33 0 -11 8 -29 14 -40 14 -14 -1 -7 -7 20 -20 22 -10
43 -16 47 -14 5 3 8 1 8 -5 0 -6 11 -13 25 -17 14 -3 25 -10 25 -14 0 -12 128
-70 154 -70 17 0 18 2 6 10 -8 5 -25 10 -36 10 -23 0 -51 25 -37 32 4 3 3 3
-4 2 -6 -1 -24 1 -40 7 -20 6 -9 7 41 4 49 -4 73 -11 82 -23 13 -16 13 -16 14
1 0 13 9 17 35 18 19 1 50 2 68 3 44 3 57 -3 57 -25 0 -17 -8 -19 -75 -19 -43
0 -75 -4 -75 -10 0 -5 6 -10 14 -10 7 0 19 -7 26 -15 8 -10 20 -13 33 -9 20 6
20 6 4 -10 -9 -10 -17 -20 -17 -23 0 -4 3 -3 7 0 3 4 14 0 23 -8 10 -9 41 -18
71 -22 30 -3 62 -10 71 -15 11 -5 35 -4 62 3 24 6 52 9 62 7 12 -3 2 -9 -26
-15 -35 -8 -41 -12 -27 -18 12 -5 32 -2 53 9 39 18 54 20 54 7 0 -14 -62 -36
-95 -32 -16 2 -27 -1 -22 -5 9 -9 72 -33 107 -41 14 -3 30 -9 35 -13 17 -13
78 -23 71 -12 -3 6 -21 14 -39 18 -19 4 -26 9 -17 12 8 2 30 20 48 39 l32 33
-22 9 c-13 5 -26 7 -29 5 -3 -2 -4 8 -1 21 2 15 0 25 -7 25 -15 0 -14 55 2 81
10 17 10 23 0 26 -7 3 -13 17 -13 31 1 26 1 26 15 -7 16 -35 20 -75 8 -68 -5
3 -8 -6 -8 -19 0 -13 3 -24 8 -24 4 0 7 -8 7 -19 0 -20 23 -44 46 -47 8 -1 14
2 14 7 0 5 -7 9 -15 9 -16 0 -49 63 -42 82 3 7 12 -3 20 -23 9 -19 23 -41 31
-48 9 -8 15 -26 13 -44 -1 -17 3 -32 11 -34 8 -3 10 1 5 13 -3 9 -2 15 3 12 5
-2 24 -10 42 -17 30 -12 44 -31 22 -31 -5 0 -10 5 -10 10 0 19 -32 10 -37 -10
-4 -14 -14 -20 -34 -20 -16 0 -29 -4 -29 -10 0 -5 9 -10 21 -10 26 0 61 19 55
29 -9 13 76 9 111 -5 51 -22 180 -24 206 -4 30 22 57 32 57 19 0 -6 9 -8 23
-4 22 7 22 7 2 -9 -11 -8 -27 -16 -37 -16 -9 0 -18 -4 -20 -9 -5 -13 -78 -24
-78 -11 0 5 -18 10 -40 10 -33 0 -40 -3 -40 -20 0 -15 -7 -20 -25 -20 -14 0
-28 -7 -32 -15 -7 -20 -5 -22 32 -29 26 -6 27 -5 8 3 -23 11 -33 37 -10 28 6
-3 21 -8 32 -12 16 -6 17 -5 5 4 -12 9 -10 11 12 11 15 0 39 -5 53 -12 23 -10
28 -10 36 5 6 9 19 17 29 17 11 0 20 7 20 15 0 12 16 15 80 15 64 0 80 -3 80
-15 0 -17 -1 -17 80 -20 83 -3 80 -3 80 -25 0 -19 -7 -20 -155 -20 -97 0 -155
-4 -155 -10 0 -6 32 -10 75 -10 58 0 75 -3 75 -14 0 -11 -14 -13 -62 -10 -63
3 -63 3 -14 -6 26 -5 54 -11 62 -14 8 -3 14 -1 14 5 0 11 32 8 41 -3 11 -16
98 -21 130 -8 30 13 30 14 10 21 -12 5 -21 17 -21 29 0 18 6 20 73 20 65 0 73
2 82 23 l10 22 395 0 c368 0 395 1 398 17 5 25 42 23 42 -2 0 -21 20 -24 153
-23 59 0 64 -22 7 -37 -22 -6 -40 -15 -40 -21 0 -6 8 -5 21 2 12 6 36 8 54 5
33 -7 212 29 230 45 12 12 -17 12 -50 -1 -17 -7 -24 -7 -20 0 3 6 -1 10 -10
10 -9 0 -14 -4 -10 -9 9 -16 -28 -21 -75 -12 -42 9 -42 10 -10 9 51 0 53 1 36
17 -8 8 -31 15 -50 15 -20 0 -36 5 -36 10 0 6 -40 10 -100 10 l-99 0 -3 58
c-3 55 -4 57 -30 55 -22 -1 -28 3 -28 18 0 13 -7 19 -24 19 -16 0 -23 -5 -22
-17 0 -10 -7 -19 -17 -21 -16 -3 -17 -1 -7 19 17 30 24 111 11 119 -7 3 0 21
16 45 l27 40 6 -35 c4 -19 10 -46 14 -58 7 -21 5 -23 -13 -17 -15 5 -21 2 -21
-9 0 -11 9 -16 30 -16 17 0 30 -3 30 -7 0 -13 76 -93 88 -93 6 0 16 -9 22 -20
6 -11 20 -20 32 -20 16 0 19 5 16 25 -5 22 -2 25 21 25 15 0 32 -5 39 -12 9
-9 12 -9 12 1 0 9 10 12 30 9 20 -3 30 0 30 9 0 7 5 13 10 13 6 0 10 -4 10 -9
0 -5 9 -11 21 -14 13 -4 20 -12 17 -24 -3 -15 -14 -18 -61 -20 -35 -1 -57 2
-57 9 0 7 -5 6 -15 -2 -13 -10 -15 -9 -15 9 0 11 -4 21 -10 21 -5 0 -10 -9
-10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -5 -10 -11 0 -6 8 -8 19 -4 24 7 51 -4
51 -21 0 -10 12 -14 40 -14 29 0 40 4 40 15 0 15 11 17 93 17 15 1 27 4 27 8
0 4 8 10 18 14 29 10 82 53 74 61 -4 4 2 5 13 3 13 -2 21 3 23 17 5 26 22 46
33 39 5 -3 9 2 9 11 0 9 -4 13 -10 10 -13 -8 -13 -8 5 40 17 48 19 145 4 198
-8 28 -7 42 1 53 7 7 15 12 19 10 8 -5 5 -225 -3 -234 -10 -9 -7 -44 4 -37 6
4 8 -17 6 -55 l-3 -60 91 0 c50 0 98 -5 106 -10 12 -8 11 -10 -7 -10 -13 0
-23 -5 -23 -10 0 -15 70 -1 92 17 9 8 25 13 35 11 14 -3 11 -7 -14 -18 -18 -8
-33 -18 -33 -22 0 -5 -12 -8 -27 -8 -16 0 -35 -5 -43 -11 -26 -18 60 0 102 22
l38 19 0 84 c0 55 4 87 13 93 10 7 100 10 147 4 6 0 10 -19 10 -40 0 -29 5
-41 18 -44 16 -4 16 -5 0 -6 -10 0 -18 -7 -18 -15 0 -12 4 -12 30 0 16 9 32
19 35 24 3 5 11 8 18 6 7 -1 9 -1 5 1 -18 10 -6 23 16 17 14 -3 31 -2 38 2 9
6 4 10 -17 14 -21 4 -30 11 -31 26 -1 16 6 21 32 22 19 2 34 -1 34 -5 0 -11
86 -11 96 0 4 4 2 7 -4 7 -7 0 -12 4 -12 9 0 5 15 8 33 7 29 -1 32 2 31 26 0
18 7 30 20 36 12 5 32 15 46 21 23 10 22 11 -12 6 -40 -6 -53 9 -18 20 11 4
18 11 15 16 -4 5 5 9 19 9 16 0 26 -6 26 -15 0 -17 16 -20 25 -5 4 6 11 8 16
4 5 -3 17 0 25 7 13 11 15 26 9 84 -5 54 -3 74 7 82 19 14 156 9 171 -6 9 -11
9 -14 0 -18 -7 -3 -13 -9 -13 -15 0 -7 4 -7 13 -1 20 17 77 33 77 22 0 -5 5
-9 10 -9 14 0 12 27 -2 33 -7 3 2 6 20 6 25 1 32 5 32 20 0 25 17 36 34 22 9
-7 16 -7 24 1 19 19 14 29 -12 22 -30 -7 -39 7 -31 48 6 33 7 33 62 34 31 0
51 -2 44 -4 -17 -6 -13 -22 5 -22 14 0 15 -2 3 -17 -8 -9 10 1 38 21 60 43 71
56 49 56 -9 0 -16 -7 -16 -16 0 -9 -5 -14 -12 -12 -17 6 -22 28 -7 28 7 0 20
7 30 16 19 17 44 10 33 -8 -4 -6 -3 -8 3 -5 5 3 10 22 11 42 1 20 4 41 8 47 4
5 0 14 -7 19 -7 4 -10 11 -6 16 5 4 11 3 15 -3 9 -14 54 -13 65 1 5 6 7 1 5
-10 -2 -12 -11 -20 -23 -20 -12 0 -21 -8 -23 -20 -3 -11 -9 -23 -16 -27 -6 -4
-6 -8 -1 -8 15 1 102 79 88 80 -7 0 -13 5 -13 11 0 6 6 9 13 6 9 -3 11 10 9
54 l-4 59 55 0 c47 0 58 4 75 25 24 30 29 31 35 8 2 -10 1 15 -1 55 l-5 72 57
0 c42 0 58 4 63 16 4 12 7 13 14 3 13 -22 22 -2 9 21 -6 12 -9 29 -5 37 3 9 0
20 -7 24 -7 4 -9 11 -5 16 5 4 11 2 15 -4 6 -9 17 -10 37 -4 25 8 26 9 8 10
-30 1 -33 41 -4 41 11 0 23 9 26 20 3 11 14 20 23 20 16 -1 16 -2 0 -14 -19
-14 -23 -52 -9 -74 6 -10 11 -9 22 4 8 10 9 15 3 11 -17 -10 -25 20 -9 33 10
8 14 7 17 -4 2 -12 7 -13 18 -5 11 8 12 12 3 16 -15 6 -17 30 -3 35 5 1 13 37
16 78 l7 76 36 -1 c32 0 36 -3 41 -29 l5 -30 28 33 c19 23 27 44 28 74 1 23 5
53 10 67 9 25 9 25 5 -3 -3 -28 17 -39 27 -14 4 9 6 8 6 -2 1 -8 9 -4 21 12
27 34 26 53 -2 25 -24 -24 -39 -9 -18 17 8 9 8 15 2 15 -6 0 -13 -6 -16 -12
-3 -9 -9 -7 -20 6 -20 25 -22 151 -3 163 6 4 45 8 84 8 75 1 88 -7 77 -42 -4
-12 1 -10 14 7 20 24 20 25 1 38 -18 13 -18 14 8 54 14 23 29 54 33 68 4 14
12 24 18 23 7 -2 19 11 27 27 14 27 14 30 0 30 -8 0 -15 -7 -15 -17 0 -9 -3
-14 -6 -10 -11 11 6 57 21 57 8 0 15 -5 15 -12 0 -6 9 -1 20 12 11 13 20 31
20 41 0 10 7 25 16 34 9 9 13 19 9 23 -4 4 -10 1 -12 -5 -8 -23 -23 -13 -23
15 0 62 22 93 35 51 5 -14 10 -17 19 -10 9 7 16 7 24 -1 8 -8 13 -7 17 8 4 10
13 34 21 52 8 18 9 32 4 32 -6 0 -10 5 -10 10 0 6 7 10 15 10 17 0 32 51 40
142 4 44 11 68 21 72 8 3 14 1 14 -4 0 -23 20 -7 28 23 6 17 13 40 17 50 4 9
3 17 -3 17 -6 0 -13 -18 -17 -40 -7 -40 -19 -51 -28 -27 -4 9 -6 9 -6 0 -1 -7
-6 -13 -11 -13 -6 0 -8 11 -4 26 4 14 1 35 -5 48 -7 12 -11 52 -9 96 l3 75 40
-3 c22 -2 43 -8 46 -14 4 -7 9 -6 14 1 5 9 10 9 21 0 9 -7 14 -8 14 -1 0 6 -5
12 -11 14 -16 5 0 78 17 78 10 0 14 13 14 45 0 27 5 45 11 45 8 0 10 19 6 65
-4 41 -3 64 3 60 6 -3 10 -15 10 -26 0 -11 5 -17 10 -14 16 10 12 35 -10 62
-23 30 -25 93 -8 416 10 192 10 208 -6 226 -14 16 -17 34 -14 90 3 75 -5 87
-47 70 -11 -4 -36 -8 -56 -8 l-37 -1 -7 -75 c-6 -69 -9 -75 -29 -75 -12 0 -27
5 -33 11 -8 8 -19 6 -41 -5 -17 -9 -38 -16 -47 -16 -8 0 -15 -4 -15 -9 0 -5
-9 -11 -20 -14 -12 -3 -20 -14 -20 -26 0 -12 -5 -21 -11 -21 -5 0 -8 4 -4 9 3
5 1 12 -4 15 -13 8 -21 -19 -21 -68 0 -41 -1 -42 -32 -41 -60 1 -60 3 -53 164
4 80 8 180 10 221 2 41 6 91 10 110 5 26 4 31 -4 20 -9 -12 -11 -11 -11 8 0
13 4 21 8 18 11 -6 9 140 -1 151 -5 4 -6 -4 -2 -20 4 -16 3 -27 -3 -27 -5 0
-13 28 -16 63 -13 119 -14 132 -26 182 -13 55 -30 82 -30 48 0 -12 5 -25 10
-28 6 -4 8 -14 6 -23 -3 -10 1 -30 9 -46 9 -16 13 -31 11 -34 -10 -9 -36 27
-36 50 0 59 -13 98 -31 98 -17 0 -19 -8 -19 -76 0 -72 -1 -75 -22 -72 -22 3
-23 8 -23 78 1 65 10 215 15 230 1 3 7 -13 15 -35 7 -22 13 -31 14 -21 1 20 1
20 17 0 14 -18 15 -18 8 6 -4 14 -13 29 -21 33 -8 5 -11 16 -8 27 7 23 -15 72
-31 66 -8 -3 -13 13 -16 52 -5 66 -62 200 -71 167 -5 -19 -5 -19 -12 3 -14 44
-25 21 -25 -53 0 -68 -2 -75 -19 -75 -30 0 -34 14 -26 91 5 49 11 69 21 69 8
0 14 5 14 11 0 6 -7 9 -15 5 -9 -3 -15 0 -15 10 0 9 6 14 13 11 6 -3 20 -8 30
-12 9 -4 17 -3 17 1 0 15 -14 30 -35 38 -15 5 -16 9 -6 15 10 7 11 13 2 30
-15 27 -16 27 -19 -6 -4 -46 -12 -38 -12 12 0 51 -41 156 -48 123 -2 -10 -8
-18 -13 -18 -13 0 -11 38 3 42 7 3 8 10 4 17 -7 11 -10 11 -17 0 -7 -11 -10
-9 -15 9 -13 45 -24 20 -24 -54 0 -72 -1 -75 -22 -72 -20 3 -23 9 -22 48 2 89
7 131 15 118 4 -7 11 -10 14 -6 4 4 -3 20 -16 37 -12 17 -19 34 -14 38 4 5 10
3 12 -4 2 -6 13 -21 24 -34 10 -12 19 -27 19 -32 0 -6 3 -8 6 -4 7 7 -77 147
-88 147 -5 0 -8 -5 -8 -12 0 -6 -7 -3 -15 8 -19 25 -19 40 0 24 25 -21 26 -6
1 24 l-23 29 3 -54 c1 -39 -1 -48 -7 -31 -4 12 -15 22 -24 22 -9 0 -15 9 -15
23 0 13 -9 32 -20 42 -11 10 -20 23 -20 29 0 6 4 4 10 -4 5 -8 12 -11 15 -8 4
4 0 16 -8 28 -11 15 -16 18 -20 8 -3 -7 -6 -1 -6 13 -1 14 -10 34 -21 44 -11
10 -20 26 -20 36 0 10 -5 21 -10 24 -6 4 -10 12 -9 18 3 23 -1 38 -9 33 -13
-8 -32 9 -37 32 -2 13 -13 34 -23 47 l-20 25 1 -33 c1 -25 5 -34 16 -32 9 1
11 0 4 -2 -6 -2 -14 -16 -16 -31 l-4 -27 -2 26 c-1 25 -2 25 -73 24 -40 -1
-79 3 -85 8 -8 6 -13 36 -13 81 l0 72 -70 -1 c-45 -1 -75 3 -81 11 -5 7 -9 43
-7 80 3 67 3 68 36 76 40 11 40 18 2 46 -16 13 -30 18 -30 12 0 -6 7 -13 15
-16 8 -4 15 -12 15 -20 0 -18 -8 -18 -26 1 -10 10 -35 15 -70 15 l-54 0 0 54
c0 30 5 58 10 61 6 4 1 14 -12 25 -61 52 -87 70 -96 70 -6 0 -13 6 -16 13 -4
8 -5 7 -5 -3 3 -46 -2 -60 -21 -60 -22 0 -29 35 -7 43 7 3 0 9 -15 16 -16 6
-28 16 -28 22 0 6 7 8 15 5 8 -3 22 -9 30 -12 8 -3 15 -1 15 5 0 7 -14 18 -30
25 -17 7 -27 15 -23 19 3 4 1 7 -5 7 -7 0 -12 -4 -12 -10 0 -5 -11 -9 -25 -8
-14 1 -25 8 -25 14 0 7 -7 14 -15 18 -8 3 -15 1 -15 -3 0 -5 6 -12 13 -14 7
-3 7 -6 1 -6 -6 -1 -19 8 -29 19 -10 11 -28 20 -40 20 -29 0 -41 37 -15 45 9
3 26 2 36 -2 17 -6 17 -5 5 10 -7 9 -18 17 -23 17 -5 0 -24 11 -41 24 -18 12
-41 26 -52 30 -16 5 -16 3 5 -14 14 -12 35 -25 48 -30 35 -15 27 -31 -9 -18
-33 11 -80 53 -76 67 3 11 -42 51 -56 51 -6 0 0 -11 13 -25 13 -14 21 -28 18
-31 -4 -3 -49 -8 -101 -11 -102 -6 -114 -1 -103 43 5 19 13 24 36 24 27 0 35
9 31 34 -1 5 5 6 14 2 44 -18 48 -18 25 0 -37 29 -79 48 -87 40 -4 -4 3 -9 15
-13 26 -6 30 -26 5 -21 -10 2 -22 13 -28 26 -6 14 -18 22 -35 22 -14 0 -38 7
-54 15 -15 8 -37 15 -47 15 -11 0 -19 6 -19 13 0 6 -6 1 -13 -13 -11 -22 -19
-25 -81 -28 -77 -3 -92 4 -82 42 5 19 13 26 32 26 13 0 34 9 46 19 l20 20 -22
-6 c-16 -4 -21 -3 -16 5 10 17 -86 59 -122 53 -8 -1 -23 5 -33 15 -26 23 -119
54 -144 48 -12 -3 -32 3 -47 15 -14 12 -37 21 -50 21 -12 0 -32 7 -42 15 -11
8 -29 15 -40 14 -28 -1 51 -38 82 -39 15 0 22 -6 22 -19 0 -11 9 -23 20 -26
11 -3 20 -10 20 -15 0 -4 -9 -6 -20 -3 -14 4 -20 0 -20 -11 0 -15 -11 -16 -82
-14 l-83 3 0 51 c0 46 2 51 17 43 9 -5 27 -8 40 -8 20 1 21 2 3 6 -11 3 -24 9
-30 14 -5 5 -17 9 -27 9 -21 0 -24 16 -5 23 6 3 -3 6 -21 6 -20 1 -31 -3 -27
-9 3 -5 -1 -10 -9 -10 -9 0 -16 7 -16 15 0 11 -12 15 -47 16 -27 1 -52 1 -56
0 -5 -1 -6 3 -2 8 6 11 -42 27 -52 17 -4 -3 1 -6 10 -6 9 0 18 -4 21 -8 2 -4
-10 -8 -29 -8 -21 0 -31 4 -28 12 6 16 -4 21 -52 28 -38 6 -39 5 -8 -4 21 -7
30 -15 27 -24 -5 -12 -9 -12 -32 0 -29 15 -72 19 -72 7 0 -12 -431 -19 -800
-14 -47 1 -100 0 -117 -2 -18 -3 -30 -1 -27 4 3 5 0 9 -5 9 -6 0 -11 -4 -11
-8 0 -4 -11 -8 -25 -8 -14 0 -25 3 -25 7 0 9 66 19 134 19 77 0 67 9 -22 18
-52 6 -69 5 -65 -4z m-320 -70 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m1659 -33 c-5 -8 -56 -3 -56 5 0 2 14 4 31 4 16 0 28 -4 25 -9z m-1663
-8 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m2009 -65 c51 -19
52 -23 10 -24 -20 -1 -40 4 -44 10 -6 8 -8 8 -8 0 0 -13 -47 -6 -56 8 -9 14
17 29 41 24 11 -2 37 -11 57 -18z m128 -58 c12 -8 12 -10 -5 -10 -11 0 -27 5
-35 10 -12 8 -12 10 5 10 11 0 27 -5 35 -10z m65 -20 c0 -10 -12 -16 -34 -18
-22 -2 -31 0 -26 8 4 6 15 8 26 5 21 -7 27 10 7 19 -7 3 -4 5 7 3 11 -1 20 -9
20 -17z m-2952 -97 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m-123 -63 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m3393 -17 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-2333
-82 l25 -3 -27 -8 c-14 -4 -41 -6 -60 -5 -18 2 -37 4 -41 4 -5 1 -6 5 -3 10 5
8 36 9 106 2z m2597 -77 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-2728 -8 c-11 -9 -69 -14 -69 -6 0 6 17 10 37 10 20 0 34 -2 32 -4z m584 -3
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m2207 -63 c0 -5 -9
-10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m242 -112
c2 -15 10 -23 22 -23 11 0 21 -5 23 -12 3 -7 -8 -10 -29 -9 -18 2 -36 -2 -40
-8 -4 -6 -11 -7 -15 -2 -4 5 -3 11 4 13 8 3 10 15 6 34 -4 22 -2 29 10 29 9 0
17 -10 19 -22z m-82 -38 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20
20 20 13 0 20 -7 20 -20z m-3235 -10 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10
3 6 8 10 10 10 2 0 7 -4 10 -10z m3395 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3480 -26 c0 -22 -2 -25 -15 -14 -8
7 -15 19 -15 26 0 8 7 14 15 14 10 0 15 -10 15 -26z m1047 4 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1047 -113 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m3480 -5 c0 -13 -7 -20 -20
-20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-3320 -250 c0 -5
-7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m880 -105
c0 -5 -5 -3 -10 5 -8 13 -10 13 -10 -1 0 -9 -7 -23 -16 -30 -15 -12 -16 -11
-9 11 12 39 26 54 36 38 5 -7 9 -17 9 -23z m2963 -67 c10 -38 7 -43 -18 -40
-20 3 -25 -1 -25 -17 0 -14 -6 -21 -20 -21 -13 0 -20 7 -20 20 0 14 7 20 21
20 15 0 20 6 19 23 -3 34 -1 37 19 37 11 0 21 -9 24 -22z m97 -218 c0 -11 -4
-20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m-3220 -10
c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z m3340 -60 c13 -8
12 -10 -2 -10 -10 0 -18 -4 -18 -10 0 -5 -7 -10 -15 -10 -18 0 -19 12 -3 28
14 14 19 15 38 2z m144 -269 c-7 -8 -33 33 -27 43 3 5 12 -2 19 -15 7 -13 10
-26 8 -28z m152 -315 c-2 -6 -7 -2 -11 9 -3 11 -11 28 -16 37 -5 10 -9 26 -8
35 0 10 9 -2 20 -26 11 -24 18 -49 15 -55z m64 -151 c0 -8 -4 -15 -10 -15 -5
0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-7008 -195 c-8 -33 -19 -60
-24 -60 -4 0 -6 11 -3 25 3 14 8 37 11 52 4 22 18 40 30 43 0 0 -6 -27 -14
-60z m3503 20 c4 -6 12 -7 22 -1 9 5 29 5 44 1 15 -5 30 -6 33 -2 3 3 6 -2 6
-11 0 -9 7 -17 15 -17 12 0 15 -12 13 -57 -3 -51 -1 -57 19 -62 12 -3 34 -17
49 -30 21 -18 24 -25 13 -32 -7 -4 -11 -11 -7 -14 3 -4 16 3 28 15 16 16 19
25 11 34 -15 18 -3 26 33 25 30 -1 30 -1 6 -9 l-25 -8 28 -1 c15 -1 27 3 27 7
0 5 10 8 23 7 35 -3 107 -27 107 -37 0 -10 0 -10 -46 5 -23 8 -54 10 -74 6
-19 -3 -27 -7 -17 -8 26 -2 10 -21 -18 -21 -13 0 -37 -12 -54 -26 -17 -14 -31
-21 -31 -15 0 6 -11 11 -25 11 -16 0 -25 -6 -25 -16 0 -14 3 -14 15 -4 24 20
28 5 9 -31 -24 -47 -29 -144 -11 -196 16 -48 15 -51 -10 -25 -15 14 -17 32
-15 107 2 49 0 87 -4 83 -4 -4 -8 -47 -8 -95 -2 -74 1 -91 18 -110 10 -12 23
-20 28 -17 5 3 17 -3 26 -13 9 -10 30 -27 45 -37 27 -17 28 -18 10 -31 -10 -8
-13 -15 -7 -15 6 0 17 7 24 15 7 8 22 15 34 14 19 -1 18 -2 -4 -9 l-25 -8 27
-1 c15 -1 30 -6 33 -11 4 -6 13 -7 23 -1 25 12 82 22 82 13 0 -4 32 -7 70 -8
39 0 70 4 70 9 0 4 10 5 22 1 21 -6 21 -5 9 10 -10 12 -17 14 -25 6 -15 -15
-143 -13 -149 3 -4 8 -8 9 -14 1 -10 -13 -83 -26 -83 -15 0 5 16 11 36 14 20
3 44 13 54 22 17 15 18 15 16 -2 -1 -16 7 -18 59 -18 l60 0 5 250 c3 138 7
239 9 225 l3 -25 8 25 c7 22 8 20 9 -14 1 -31 4 -37 13 -28 7 7 23 11 37 11
24 -2 24 -2 4 -6 -13 -2 -23 -11 -23 -19 0 -18 25 -18 44 1 8 8 24 15 35 15
12 0 21 4 21 8 0 5 21 9 48 9 31 0 36 -1 15 -4 -18 -2 -33 -8 -33 -14 0 -5 -8
-10 -17 -10 -17 -1 -17 -2 0 -6 26 -7 21 -23 -8 -23 -14 0 -25 5 -25 10 0 6
-7 10 -15 10 -18 0 -20 -16 -2 -23 6 -3 -1 -6 -16 -6 -15 -1 -30 -7 -33 -15
-9 -23 -33 -8 -26 16 3 13 1 19 -5 15 -5 -3 -10 -30 -10 -59 0 -39 2 -47 7
-28 l8 25 1 -27 c1 -16 -3 -28 -7 -28 -4 0 -9 -12 -10 -26 -2 -23 0 -25 13
-14 23 19 31 3 9 -17 -16 -15 -20 -30 -19 -83 2 -89 24 -125 90 -148 l30 -10
-28 -1 c-17 -1 -43 11 -67 31 l-40 31 -3 201 -2 201 -3 -218 c-1 -122 2 -216
7 -213 4 3 21 -6 37 -19 22 -19 40 -25 77 -25 26 0 47 5 47 10 0 6 12 10 26
10 14 0 34 4 44 10 24 13 68 -9 64 -33 -4 -29 -29 -45 -73 -48 -34 -3 -45 1
-54 16 -10 17 -25 20 -85 23 l-73 3 7 -36 c8 -45 -1 -72 -20 -56 -11 9 -11 5
-2 -24 10 -33 12 -34 18 -13 5 18 6 14 8 -17 1 -22 4 -34 7 -27 2 6 20 12 39
12 32 0 33 -1 28 -32 -4 -27 -3 -29 5 -12 5 12 14 25 20 29 6 3 8 11 5 17 -5
7 2 9 20 6 18 -4 26 -1 26 8 0 8 5 13 11 11 6 -1 13 3 16 10 3 7 -3 9 -16 6
-22 -6 -27 9 -15 41 7 19 103 23 135 6 16 -9 19 -7 19 10 0 11 -7 20 -15 20
-8 0 -15 5 -15 10 0 6 5 10 10 10 6 0 10 11 10 24 0 21 5 25 38 28 l37 3 3
118 c1 64 7 117 12 117 5 0 11 12 13 28 2 15 5 -29 6 -98 1 -69 6 -116 9 -105
4 11 5 68 2 128 -3 69 -1 106 5 104 6 -2 11 -45 11 -98 3 -139 3 -149 12 -149
4 0 6 55 6 122 l-1 123 6 -127 c5 -109 9 -129 25 -141 28 -21 131 -49 158 -44
17 3 14 5 -12 7 l-35 2 30 8 c17 4 38 11 48 15 12 5 17 2 17 -9 0 -9 -5 -16
-11 -16 -5 0 -7 -5 -4 -10 5 -8 12 -7 22 1 11 9 16 10 22 1 4 -6 12 -8 20 -3
16 10 102 2 116 -10 5 -5 -4 -9 -23 -9 -25 0 -32 -4 -32 -19 0 -12 -9 -21 -25
-25 -31 -8 -54 2 -34 15 10 6 3 9 -21 10 -19 1 -39 1 -43 0 -5 -1 -6 3 -3 8 4
5 2 12 -4 16 -5 3 -10 1 -10 -5 0 -6 -14 -10 -32 -9 -60 4 -88 -1 -88 -15 0
-8 -12 -17 -27 -19 -16 -3 1 -3 37 0 103 7 129 4 150 -17 l20 -20 -42 1 c-24
0 -61 -2 -83 -5 -39 -5 -39 -4 -5 3 33 8 34 8 7 10 -38 1 -95 -26 -102 -50 -4
-11 -20 -34 -36 -52 -16 -17 -36 -52 -43 -77 l-13 -45 -3 70 c-1 39 -3 83 -3
98 0 20 -3 25 -10 18 -6 -6 -12 -53 -13 -104 l-1 -92 7 75 c4 41 4 5 1 -80 -8
-194 -6 -268 7 -300 10 -24 10 -23 10 10 0 19 1 53 1 75 0 22 -2 65 -4 95 -4
45 -3 50 5 25 5 -16 7 -38 4 -47 -3 -10 -2 -18 3 -18 8 0 43 -52 43 -64 0 -3
-4 -6 -10 -6 -5 0 -10 8 -10 17 0 14 -3 14 -12 5 -10 -10 -10 -15 2 -22 8 -6
20 -8 27 -5 6 2 21 -12 32 -30 14 -25 25 -33 37 -28 14 5 12 8 -10 20 -38 20
-32 25 12 8 46 -18 192 -46 203 -39 5 3 9 12 9 20 0 8 6 14 14 14 18 0 76 51
76 67 0 7 5 13 10 13 16 0 12 -14 -10 -38 -16 -17 -17 -22 -6 -22 8 0 20 8 26
18 8 12 13 13 16 5 11 -25 19 -12 19 30 0 23 -6 52 -13 64 -6 12 -12 28 -12
37 0 19 -40 32 -50 16 -6 -10 -13 -9 -31 4 -34 23 -60 19 -92 -13 -32 -33 -75
-47 -90 -32 -8 8 -1 11 21 11 22 0 32 5 32 15 0 14 -4 14 -36 0 -28 -11 -40
-12 -50 -4 -36 30 7 119 58 119 28 0 39 -22 18 -35 -10 -6 -14 -55 -5 -55 15
0 43 41 39 57 -5 16 0 18 40 15 34 -3 53 1 76 18 17 12 29 16 28 10 -4 -18 48
-31 105 -28 l49 3 0 -165 0 -164 -105 -1 c-58 0 -103 3 -100 8 2 4 -3 7 -12 7
-9 0 -14 -4 -10 -9 3 -6 -4 -8 -17 -5 -37 8 -235 11 -208 3 14 -3 -10 -6 -55
-5 -44 0 -82 4 -85 8 -3 4 -35 11 -70 16 -61 9 -62 10 -19 11 25 0 50 6 57 13
9 9 12 9 12 0 0 -7 3 -12 8 -12 4 0 6 111 4 246 -2 178 1 250 9 260 7 8 9 18
5 22 -20 20 -27 -47 -29 -266 l-1 -237 -7 240 c-4 132 -7 261 -8 288 0 26 -5
47 -10 47 -6 0 -9 -17 -8 -37 l2 -38 -77 1 c-43 1 -78 -3 -78 -8 0 -5 15 -9
33 -8 52 3 69 1 62 -10 -3 -5 -12 -7 -20 -4 -7 3 -18 -4 -25 -15 -6 -12 -18
-21 -26 -21 -8 0 -13 -4 -10 -8 3 -5 -10 -33 -29 -63 -19 -30 -40 -72 -46 -94
-10 -37 -12 -38 -25 -20 -13 18 -14 17 -9 -15 l5 -35 -14 30 c-10 22 -11 32
-3 37 18 11 -5 64 -26 60 -10 -2 -17 4 -17 13 0 8 -4 15 -8 15 -5 0 -14 11
-20 25 -7 15 -8 25 -2 25 6 0 10 5 10 11 0 6 -9 8 -22 4 -22 -7 -22 -7 -3 8
16 13 17 17 6 31 -7 9 -23 15 -35 15 -15 -1 -17 -2 -4 -6 9 -2 19 -11 22 -19
7 -18 3 -18 -40 5 -28 15 -32 20 -17 24 16 4 15 5 -2 6 -12 0 -27 7 -34 14
-10 10 -12 9 -9 -3 2 -9 -6 -16 -20 -18 -12 -2 -27 -12 -32 -23 -13 -23 -12
-422 1 -455 4 -13 18 -24 31 -27 19 -4 19 -4 -6 -6 -17 0 -34 6 -42 17 -7 9
-14 13 -14 7 0 -6 -89 -10 -247 -10 l-248 1 243 -6 c133 -3 242 -8 242 -12 0
-5 0 -12 0 -18 0 -5 -13 -10 -30 -10 -20 0 -30 5 -30 15 0 13 -30 15 -209 15
-173 0 -211 3 -221 15 -11 13 14 15 218 15 126 0 244 3 261 6 l31 7 0 219 c0
138 4 227 11 240 12 23 4 37 -12 21 -8 -8 -11 -75 -10 -202 1 -105 -2 -191 -6
-191 -5 0 -7 18 -5 40 3 31 1 40 -11 40 -23 0 -32 -21 -13 -32 19 -10 22 -38
4 -38 -9 0 -9 -3 0 -12 7 -7 12 -19 12 -28 -1 -12 -4 -11 -15 4 -9 12 -12 29
-9 40 4 12 3 18 -1 14 -13 -13 -49 54 -47 87 1 17 -2 46 -7 65 -5 19 -9 27
-10 17 -2 -27 -21 -10 -21 19 0 13 -5 24 -11 24 -7 0 -8 -12 -4 -32 5 -25 4
-29 -4 -18 -6 8 -11 24 -11 34 0 11 -3 16 -7 13 -3 -4 -20 19 -36 50 -16 32
-37 67 -47 78 -9 11 -15 26 -12 34 3 8 -1 12 -9 9 -8 -3 -13 -11 -11 -17 1 -6
-3 -11 -8 -11 -6 0 -16 -12 -23 -27 -6 -16 -22 -43 -34 -61 l-22 -34 -6 29
c-12 54 -16 60 -25 38 -5 -11 -9 -15 -9 -8 -1 7 -8 10 -16 7 -8 -4 -26 1 -40
10 -29 19 -61 21 -68 4 -4 -10 -8 -9 -16 2 -9 12 -11 12 -17 -3 -7 -19 -50
-23 -56 -5 -3 8 -25 13 -59 13 l-54 0 -3 45 c-2 33 0 41 8 30 6 -10 10 -11 10
-3 0 16 30 5 50 -17 17 -19 50 -23 50 -6 0 6 5 4 10 -4 6 -10 10 -11 10 -2 0
6 7 12 16 12 8 0 13 4 10 8 -2 4 9 14 26 21 28 12 32 12 44 -3 11 -15 12 -14
10 8 0 13 -4 22 -8 19 -5 -2 -8 5 -8 17 0 12 3 19 8 17 4 -3 6 89 4 205 -2
169 0 208 10 203 7 -4 5 0 -4 8 -18 14 -25 47 -10 47 4 0 7 20 8 45 0 25 -3
45 -8 45 -5 0 -7 4 -4 9 11 17 -21 33 -44 21 -11 -6 -25 -8 -30 -5 -16 10 -51
-5 -44 -17 5 -7 1 -9 -9 -5 -10 3 -17 5 -17 3 0 -3 -3 -12 -7 -22 -5 -13 -3
-16 10 -11 13 5 16 -2 19 -31 3 -34 6 -38 38 -43 31 -6 30 -9 -5 -13 -33 -4
-35 -18 -35 -205 0 -193 -7 -231 -36 -193 -12 15 -13 15 -14 -7 0 -16 -4 -21
-14 -18 -13 5 -26 -12 -26 -34 0 -16 18 -10 23 9 3 9 5 2 6 -16 1 -30 0 -31
-19 -20 -11 7 -20 18 -20 25 0 14 -83 28 -101 17 -8 -5 -10 -3 -4 6 6 9 1 10
-16 5 l-24 -6 24 19 c17 14 20 21 10 24 -9 3 -10 6 -1 12 7 4 14 -3 18 -19 6
-23 11 -25 53 -23 l46 1 -45 7 -45 7 58 1 58 2 -3 145 c-2 80 0 145 4 145 4 0
8 12 10 27 2 18 10 29 22 31 16 3 18 -1 12 -32 -3 -20 -8 -53 -11 -73 -4 -31
-3 -34 5 -13 6 17 8 -10 8 -88 0 -62 2 -116 6 -119 3 -4 6 92 6 211 0 185 -2
217 -14 213 -9 -4 -22 7 -36 30 -19 32 -19 37 -6 52 17 19 11 61 -10 61 -7 0
-16 5 -20 11 -8 14 173 4 191 -11 9 -7 16 -6 25 5 7 8 16 15 20 15 13 0 28
-46 23 -73 -4 -21 -2 -26 9 -22 25 10 38 48 22 63 -8 7 -14 18 -14 25 0 7 4 6
10 -3 8 -13 10 -12 10 3 0 19 44 25 55 7z m588 -14 c21 -5 27 -12 27 -34 0
-31 -9 -46 -29 -50 -8 -2 -11 3 -8 12 5 14 -19 27 -53 29 -32 1 -80 -15 -74
-25 4 -6 2 -8 -4 -4 -7 4 -8 17 -2 38 5 17 7 35 4 39 -3 5 4 9 16 9 12 0 18
-4 15 -10 -3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 11 10 16 10 6 0 7 -4 4 -10
-3 -6 1 -7 9 -4 9 3 16 11 16 18 0 8 2 8 8 0 4 -6 19 -14 35 -18z m2063 9 c4
-8 12 -15 20 -15 8 0 13 -3 12 -7 0 -5 3 -17 7 -27 6 -13 4 -21 -3 -24 -7 -2
-12 -20 -11 -40 0 -30 2 -33 9 -17 8 19 9 19 9 -2 1 -13 -3 -23 -8 -23 -6 0
-12 -19 -14 -42 -2 -24 -4 -4 -5 44 -2 84 -3 86 -29 95 -29 10 -90 9 -101 -1
-3 -4 -6 -25 -7 -49 0 -26 -9 -54 -21 -72 -20 -27 -22 -45 -23 -195 -2 -133 1
-171 13 -195 9 -17 16 -37 16 -47 0 -12 6 -15 22 -10 14 3 19 1 14 -6 -4 -7
-76 -11 -210 -11 -168 -1 -206 1 -216 14 -10 12 -14 13 -26 0 -17 -17 -194
-21 -194 -4 0 6 15 10 33 8 40 -2 102 24 112 48 13 32 16 65 20 225 2 87 0
160 -5 163 -5 3 -6 -54 -4 -127 3 -73 1 -151 -4 -173 l-10 -40 -3 50 c-1 28
-2 58 -1 67 1 12 -4 16 -16 12 -16 -5 -16 -5 1 6 24 16 22 33 -3 20 -15 -8
-30 -6 -63 9 -24 11 -34 20 -24 20 11 1 15 6 11 12 -4 8 3 9 23 5 26 -6 31 -3
48 31 19 38 28 76 16 69 -4 -3 -31 4 -61 14 -31 11 -45 19 -32 20 12 0 22 5
22 11 0 7 -25 10 -72 7 -42 -1 -73 1 -75 7 -3 10 32 14 130 15 68 1 97 -4 97
-15 0 -5 5 -3 10 5 23 34 30 -17 27 -197 -1 -99 1 -176 5 -170 4 7 9 59 10
117 l2 105 6 -105 c7 -138 35 -188 105 -190 29 -1 29 -2 6 -11 -13 -5 -28 -6
-35 -1 -6 5 -22 12 -36 15 -24 6 -24 5 -6 -8 22 -17 26 -17 111 -19 55 -1 85
10 85 30 0 5 -5 3 -12 -4 -7 -7 -28 -12 -47 -11 l-36 1 39 9 c22 5 45 14 52
19 13 12 27 -11 19 -32 -7 -18 129 -18 147 0 11 11 10 15 -4 23 -16 9 -18 29
-18 198 0 186 0 188 -23 200 -13 7 -39 11 -58 8 -31 -3 -31 -4 11 -7 l45 -3
-50 -6 -50 -6 -2 -105 c-2 -58 -5 -115 -8 -127 -7 -31 -25 -29 -25 2 0 15 -6
25 -14 25 -8 0 -17 6 -19 13 -3 6 -6 -2 -6 -20 -1 -35 15 -53 22 -26 4 14 5
13 6 -4 0 -12 7 -23 14 -26 6 -2 -5 -5 -25 -5 -21 0 -42 5 -47 12 -6 7 -12 70
-13 141 l-3 129 -59 -1 c-49 -1 -61 -4 -67 -19 -4 -11 -8 -13 -8 -6 -2 26 20
52 42 51 21 0 21 -1 2 -9 -15 -6 -9 -9 25 -9 34 0 40 3 25 9 -11 5 -14 9 -7 9
7 1 22 -4 33 -9 14 -8 19 -7 19 3 0 10 -15 14 -52 16 -28 1 -54 3 -56 6 -3 2
-13 0 -23 -6 -15 -7 -19 -6 -19 5 0 12 12 14 63 12 34 -2 75 -7 91 -11 21 -6
27 -5 22 4 -5 7 1 11 14 11 13 0 19 -4 14 -12 -5 -7 -3 -8 7 -3 18 12 200 14
193 3 -3 -4 -1 -8 5 -8 17 0 24 21 26 79 3 72 3 73 14 66 6 -3 15 4 21 15 13
25 87 29 96 5z m-1401 -60 c4 -35 5 -75 1 -89 -4 -16 -1 -26 10 -30 12 -5 14
-1 9 15 -5 16 -3 20 6 17 8 -3 15 -11 15 -19 1 -8 2 -20 3 -26 2 -26 21 -12
21 15 l0 29 72 -4 c86 -4 104 -13 27 -13 -34 0 -49 -4 -41 -9 13 -8 40 -7 161
5 40 4 50 8 40 16 -10 7 -7 8 11 4 14 -3 34 -6 45 -7 13 -1 9 -4 -10 -8 -28
-6 -29 -7 -7 -13 14 -4 28 -1 37 9 8 8 15 11 15 7 0 -5 5 -3 12 4 18 18 82 15
106 -7 12 -10 19 -21 15 -25 -3 -3 1 -6 10 -6 10 0 17 -4 17 -10 0 -5 -4 -10
-9 -10 -5 0 -17 -3 -27 -7 -13 -5 -15 -3 -9 7 5 9 4 11 -4 6 -6 -4 -9 -12 -6
-17 4 -5 -1 -19 -9 -31 -18 -26 -21 -100 -5 -129 13 -25 46 -59 51 -53 3 2 32
-5 66 -17 44 -15 59 -24 55 -35 -6 -15 -46 -18 -78 -4 -19 8 -19 9 3 9 12 1
22 5 22 11 0 5 -17 9 -37 9 -21 -1 -47 0 -58 2 -11 2 -20 0 -20 -6 0 -5 -8
-21 -18 -35 -16 -22 -18 -22 -15 -5 2 11 8 23 13 27 6 5 5 8 -2 8 -8 0 -13 13
-13 33 1 29 2 30 14 15 7 -10 21 -18 30 -18 15 0 15 2 4 13 -16 16 -58 40 -63
35 -1 -2 -5 -47 -7 -101 -4 -79 -3 -100 9 -104 8 -3 12 -12 9 -19 -3 -8 0 -11
10 -7 18 7 18 19 -1 27 -18 6 -20 42 -3 52 6 4 9 3 5 -2 -3 -5 14 -18 38 -27
48 -20 51 -43 7 -50 -15 -2 3 -3 41 -2 37 1 67 -2 67 -6 0 -5 -92 -8 -205 -8
-112 1 -202 6 -199 11 3 4 17 8 32 8 28 0 60 15 51 24 -2 3 -23 -1 -44 -9 -48
-17 -65 -18 -65 -5 0 6 12 10 28 10 15 0 39 6 54 14 23 12 27 12 32 0 3 -9 22
-15 53 -15 l48 -2 -57 -6 c-31 -3 -60 -10 -65 -15 -5 -5 0 -6 10 -3 10 4 46 7
80 7 34 0 56 4 50 8 -10 6 -10 17 -1 47 6 25 8 79 4 143 -4 67 -2 101 4 97 6
-3 10 -18 10 -32 0 -15 3 -23 7 -20 3 4 12 2 18 -3 9 -8 11 -4 7 16 -2 14 -11
28 -18 31 -19 7 -18 23 1 23 9 0 15 9 14 23 -1 18 -2 18 -6 4 -5 -18 -5 -18
-25 0 -20 18 -21 18 -14 -4 5 -14 3 -23 -3 -23 -6 0 -11 6 -11 14 0 7 -6 26
-14 41 -18 35 -70 54 -151 57 l-60 1 60 7 60 7 -73 1 c-43 1 -71 -2 -67 -7 3
-5 -5 -12 -17 -16 -13 -3 -35 -11 -49 -16 l-27 -10 19 -50 c10 -27 15 -49 9
-49 -14 0 -30 35 -30 64 0 41 -19 39 -22 -2 -3 -39 -3 -39 -23 -19 -17 17 -17
19 -2 14 9 -4 17 -4 17 0 0 3 -8 12 -17 19 -17 13 -17 13 -1 14 16 0 15 3 -4
25 -12 14 -28 23 -35 21 -12 -5 -17 6 -14 27 0 4 -5 7 -13 7 -9 0 -13 -7 -11
-20 2 -11 0 -20 -5 -20 -5 0 -10 21 -12 48 l-3 47 -62 3 -63 3 0 -49 c0 -45
-2 -50 -25 -54 -31 -6 -33 7 -2 17 19 6 19 8 5 12 -10 2 -18 9 -18 15 0 5 7 8
15 4 12 -4 15 3 15 29 0 18 5 37 10 40 6 3 10 16 10 27 0 18 4 20 35 15 19 -3
34 -3 34 1 -7 44 1 52 50 52 l49 0 7 -65z m-1578 29 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m1446 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14
0 19 -2 13 -5z m-4606 -35 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m1910 -14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m2620
-32 c2 -34 1 -36 -13 -18 -13 18 -14 17 -14 -18 0 -37 -18 -47 -28 -16 -3 7 0
10 7 6 7 -5 11 3 11 23 0 65 34 87 37 23z m-4592 -31 c3 -5 1 -12 -5 -16 -5
-3 -10 1 -10 9 0 18 6 21 15 7z m695 -13 c0 -4 -14 -8 -31 -8 -16 0 -28 4 -25
8 2 4 16 8 30 8 14 0 26 -4 26 -8z m3815 2 c4 -6 1 -18 -7 -27 -11 -14 -11
-16 2 -9 8 4 4 -1 -10 -12 -27 -23 -20 -43 9 -25 19 12 51 0 38 -14 -5 -4 -12
-1 -18 7 -7 11 -8 6 -3 -15 8 -39 24 -56 24 -27 0 14 6 22 18 22 14 0 15 -2 2
-10 -13 -9 -13 -11 0 -20 8 -5 10 -10 4 -10 -6 0 -19 -15 -28 -32 l-16 -33 0
30 c-1 53 -46 106 -103 120 -15 4 -27 11 -27 16 0 5 -18 9 -41 9 -22 0 -38 4
-35 9 8 12 63 3 98 -15 15 -8 29 -10 32 -5 3 5 -8 14 -24 20 -42 14 -37 22 10
15 27 -4 40 -3 40 5 0 14 27 15 35 1z m1949 4 c12 -5 14 -10 6 -19 -8 -9 -8
-15 0 -20 5 -3 10 -1 10 4 0 6 18 11 39 11 44 0 52 -11 50 -68 -1 -27 -3 -30
-6 -13 -4 19 -7 21 -13 10 -7 -11 -12 -11 -22 -1 -6 6 -22 12 -35 12 -14 0
-23 6 -23 15 0 15 -30 35 -52 35 -9 -1 -7 -5 5 -14 9 -7 17 -17 17 -22 0 -5 9
-15 20 -22 11 -7 20 -18 20 -25 -1 -8 -5 -6 -13 4 -7 9 -30 28 -51 43 -21 14
-33 26 -25 26 23 0 -4 17 -38 23 -19 4 -33 2 -33 -3 0 -6 10 -11 23 -11 12 -1
0 -5 -26 -10 -39 -8 -51 -7 -64 6 -22 23 -7 32 17 10 16 -14 23 -16 32 -7 9 9
9 12 -2 13 -8 0 -4 5 10 10 13 6 43 7 65 4 29 -5 36 -3 25 4 -12 8 -8 11 17
11 17 0 39 -3 47 -6z m-1397 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m2083 -3 c0 -5 3 -23 6 -40 8 -41 -11 -41 -25 0 -14 42 -14 49 4 49
8 0 15 -4 15 -9z m-5804 -36 c-22 -17 -46 -20 -46 -6 0 8 21 16 55 19 6 1 1
-5 -9 -13z m2054 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m561 -2 c23 -6 39 -17 39 -26 0 -13 -3 -13 -18 1 -10 10 -27
17 -38 18 -10 0 -28 4 -39 9 -19 8 -19 9 -1 9 10 1 36 -4 57 -11z m1484 2
c-28 -12 -45 -12 -45 0 0 6 15 10 33 9 27 0 29 -2 12 -9z m-5449 -37 c-10 -10
-19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m6073 -153 l-5 -175 -2 155
c-1 125 4 216 12 198 0 -2 -1 -82 -5 -178z m-4712 154 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m3223 2 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-1764 -43 c-4 -4 -11 -1 -16 7 -16 25
-11 35 7 17 9 -9 13 -20 9 -24z m-879 -45 c-8 -5 -6 -8 4 -8 10 0 19 -11 21
-25 5 -22 2 -25 -21 -24 -17 0 -21 3 -11 7 19 9 24 32 8 32 -31 1 -39 15 -26
51 l12 34 12 -30 c9 -23 9 -32 1 -37z m2276 0 c-10 -18 -18 -5 -20 30 l-2 37
13 -30 c7 -16 11 -33 9 -37z m-3188 42 c-3 -5 -23 -9 -43 -9 l-37 1 30 8 c44
11 57 11 50 0z m1825 -94 c0 -39 -3 -47 -11 -36 -8 12 -10 9 -6 -12 2 -15 0
-33 -4 -40 -5 -7 -9 28 -9 82 1 86 2 93 15 76 9 -12 15 -41 15 -70z m-1845 64
c7 -11 -9 -11 -45 0 -24 8 -24 8 7 9 17 1 35 -3 38 -9z m-71 -8 c2 -4 -15 -8
-40 -8 -24 0 -44 4 -44 8 0 5 18 8 39 8 22 0 42 -3 45 -8z m2116 -9 c0 -15
-85 -19 -94 -5 -3 5 8 6 25 4 16 -2 29 0 29 6 0 6 9 10 20 9 11 -1 20 -7 20
-14z m1286 8 c9 7 14 8 14 1 0 -6 12 -13 28 -15 15 -2 -4 -5 -43 -6 -38 -1
-81 -4 -95 -8 -14 -3 -22 -2 -17 3 12 13 91 31 95 21 2 -4 10 -3 18 4z m429
-78 c0 -72 3 -88 15 -88 8 0 16 10 18 23 2 12 7 22 12 22 5 0 14 10 21 21 6
13 7 19 1 15 -6 -3 -13 0 -16 6 -3 7 -3 2 0 -11 4 -14 2 -21 -4 -17 -5 3 -10
30 -11 59 0 46 3 53 19 54 14 1 20 -5 20 -18 0 -15 5 -18 24 -13 13 3 27 1 30
-5 4 -6 18 -11 32 -11 14 0 23 4 20 9 -3 5 1 12 10 15 24 9 84 7 84 -3 0 -5 8
-12 18 -14 9 -3 -5 -5 -33 -5 -36 0 -45 2 -30 8 17 8 16 9 -9 9 -21 1 -27 -3
-22 -14 3 -10 -1 -15 -13 -15 -11 0 -30 -13 -44 -29 -14 -17 -28 -27 -31 -24
-7 8 -9 -4 -10 -84 -1 -35 4 -65 9 -67 6 -2 7 -7 3 -11 -11 -12 -48 25 -48 47
0 10 7 18 15 18 9 0 15 9 15 23 0 17 -2 19 -10 7 -7 -11 -12 -12 -21 -3 -9 9
-18 9 -31 2 -39 -21 -48 -5 -48 84 0 68 4 97 14 97 1 0 1 -39 1 -87z m295 77
c0 -5 8 -10 18 -10 9 0 23 -9 30 -20 7 -11 8 -20 3 -20 -6 0 -13 5 -16 11 -4
5 -18 14 -31 19 -24 9 -33 30 -14 30 6 0 10 -4 10 -10z m380 -5 c0 -8 8 -15
18 -16 11 0 12 -2 4 -6 -14 -5 -42 14 -42 28 0 5 5 9 10 9 6 0 10 -7 10 -15z
m50 5 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20
-10z m840 -23 c0 -16 7 -35 15 -43 10 -10 13 -24 9 -41 -5 -18 -3 -24 5 -19
16 10 14 61 -4 76 -8 7 -15 19 -15 28 0 13 3 12 20 -3 30 -27 28 -98 -5 -130
-25 -26 -69 -33 -95 -14 -11 8 -7 9 16 4 16 -3 41 -1 55 4 21 9 19 9 -16 8
-32 -2 -40 2 -43 18 -2 11 -13 29 -25 39 -20 20 -20 20 -14 -13 4 -20 3 -30
-3 -26 -11 7 -13 48 -3 83 4 18 8 20 14 10 6 -9 9 -7 9 9 0 25 25 43 58 40 17
-1 22 -7 22 -30z m-2340 -21 c0 -5 -7 -3 -15 4 -8 7 -15 20 -15 28 0 13 3 12
15 -4 8 -10 15 -23 15 -28z m800 -37 c0 -51 -4 -78 -10 -74 -18 11 -12 155 7
155 2 0 3 -36 3 -81z m1749 65 c1 29 8 16 14 -29 5 -28 4 -34 -3 -20 -6 15 -8
4 -8 -40 0 -48 2 -54 8 -30 4 17 8 23 9 15 1 -8 4 -26 8 -39 5 -19 3 -23 -9
-18 -12 4 -18 -3 -22 -26 -5 -25 -3 -35 14 -46 14 -9 20 -24 20 -48 0 -35 0
-35 -36 -28 -20 3 -46 17 -59 31 -21 23 -24 23 -51 9 -16 -8 -39 -15 -50 -15
-12 0 -38 -7 -57 -15 -41 -17 -113 -20 -122 -5 -4 6 5 10 19 10 14 0 26 3 26
8 0 4 -18 6 -40 4 -22 -2 -37 -8 -34 -13 10 -16 -28 -23 -40 -8 -6 9 -19 18
-28 22 -12 4 -18 16 -17 34 l1 28 7 -25 c6 -22 13 -25 61 -28 43 -3 57 1 67
14 7 10 13 12 13 6 0 -7 3 -11 8 -10 4 0 14 -2 22 -5 8 -3 24 -8 35 -10 11 -3
5 -5 -14 -6 -25 -1 -32 -4 -24 -12 11 -11 63 -2 63 12 0 5 11 9 25 9 37 0 93
26 115 52 l19 23 -6 -25 c-3 -14 -3 -22 1 -18 12 10 36 140 36 194 0 54 10 76
21 47 7 -17 8 -17 8 1z m-2582 -29 c13 -41 8 -44 -19 -10 -24 30 -58 33 -65 5
-3 -11 -13 -21 -22 -21 -13 -1 -12 -2 2 -6 11 -3 24 3 32 14 11 14 18 16 32 7
10 -6 19 -21 21 -33 3 -18 6 -20 18 -10 7 6 21 9 29 5 8 -3 15 -1 15 3 0 5 -6
12 -12 14 -8 3 -6 6 5 6 23 1 22 -31 -3 -54 -11 -10 -20 -24 -20 -31 0 -15
-42 -29 -62 -21 -7 3 -1 6 15 6 22 1 27 6 27 26 0 22 -2 24 -14 13 -8 -7 -12
-16 -9 -20 2 -5 1 -8 -4 -8 -4 0 -15 15 -23 33 -16 34 -47 47 -61 25 -7 -10
-9 -8 -9 7 0 11 9 35 20 53 17 27 26 32 58 32 35 0 40 -3 49 -35z m-2042 15
c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m2495
-8 c0 -4 -21 -6 -47 -4 -83 5 -90 9 -20 11 37 0 67 -2 67 -7z m-170 -15 c-14
-7 -33 -21 -42 -32 -10 -11 -17 -16 -18 -11 0 18 44 56 64 55 19 0 19 -1 -4
-12z m-1375 -92 c-6 -45 -4 -60 14 -88 21 -35 57 -57 92 -57 11 0 17 -4 14
-10 -4 -6 6 -10 22 -10 22 0 25 2 13 10 -12 8 -11 10 6 10 27 0 27 -29 -1 -36
-11 -3 -32 -1 -45 4 -14 5 -41 8 -60 6 -32 -3 -33 -3 -6 4 l30 7 -27 12 c-15
7 -36 27 -47 45 l-20 33 6 -32 c5 -23 3 -33 -6 -33 -13 0 -9 81 7 160 10 50
15 33 8 -25z m1013 23 c12 -12 17 -48 6 -48 -10 0 -42 45 -37 52 6 11 17 10
31 -4z m-860 -27 c-11 -18 -11 -23 2 -31 8 -5 23 -7 33 -4 9 3 17 1 17 -5 0
-15 -53 -14 -68 1 -17 17 -15 45 5 57 24 13 27 7 11 -18z m60 23 c23 -7 30
-38 11 -50 -8 -4 -9 0 -4 15 5 18 2 21 -19 21 -14 0 -26 5 -26 10 0 11 6 12
38 4z m1297 -3 c3 -5 -9 -11 -27 -13 -45 -4 -47 -25 -3 -34 24 -4 33 -10 27
-16 -25 -25 -94 15 -76 44 13 21 69 34 79 19z m715 -2 c0 -29 -54 -52 -63 -26
-2 6 -8 9 -13 4 -4 -4 0 -15 9 -24 16 -16 19 -16 47 0 17 11 30 14 30 7 0 -17
-46 -33 -75 -26 -21 5 -26 12 -23 34 3 24 7 27 38 24 26 -1 31 0 20 8 -12 8
-11 10 8 10 12 0 22 -5 22 -11z m-680 -19 c0 -15 -6 -20 -27 -19 -16 0 -37 4
-48 9 -15 7 -10 9 23 9 23 1 42 6 42 11 0 6 2 10 5 10 3 0 5 -9 5 -20z m-1340
-10 c0 -5 -12 -10 -26 -10 -14 0 -23 4 -19 10 3 6 15 10 26 10 10 0 19 -4 19
-10z m-243 -52 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m-904
10 l82 -2 -70 -3 c-81 -5 -119 2 -109 19 6 9 9 9 12 0 2 -8 32 -13 85 -14z
m303 -73 c-19 -19 -37 -19 -30 0 4 8 13 15 21 15 10 0 13 6 9 16 -3 9 -3 21 1
27 5 7 9 1 11 -16 2 -15 -3 -34 -12 -42z m4331 43 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-1628 -44 c12 -7 18 -15 13 -18 -6 -3 -14 -2 -17
4 -4 6 -14 8 -24 5 -14 -4 -17 2 -18 31 -1 34 0 35 11 13 6 -13 22 -29 35 -35z
m667 29 c-8 -24 -57 -53 -88 -53 -18 1 -14 5 20 22 24 12 49 29 55 39 14 22
21 17 13 -8z m433 -58 c-2 -1 -29 0 -59 3 l-55 7 55 5 c47 4 72 -2 59 -15z
m-3836 1 c9 -7 17 -16 17 -19 0 -7 -129 -1 -136 6 -26 26 86 38 119 13z m2794
8 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m436 -1 c-7 -2 -21
-2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m1043 -7 c8 -8 14 -17 14 -19 0
-2 -33 -4 -72 -4 -47 -1 -66 2 -53 7 11 5 32 9 48 9 15 1 27 6 27 11 0 15 19
12 36 -4z m-6462 -98 c9 -36 7 -76 -5 -83 -5 -4 -21 -4 -35 -1 -29 8 -33 44
-8 81 20 31 40 32 48 3z m4964 -16 c2 -20 -1 -32 -9 -32 -8 0 -8 -4 1 -15 7
-8 19 -15 26 -15 8 0 14 -8 14 -17 0 -10 3 -28 6 -40 4 -14 2 -23 -5 -23 -6 0
-11 9 -11 20 0 21 -44 70 -81 91 -13 7 -44 16 -69 20 l-45 6 50 1 c30 1 58 -5
69 -13 22 -16 36 -19 36 -7 0 4 -15 13 -32 20 l-33 13 25 14 c37 21 55 15 58
-23z m-495 -9 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m262 -14
c-16 -3 -39 -10 -50 -14 -17 -6 -16 -4 4 12 15 13 32 18 50 15 l26 -6 -30 -7z
m-5015 -19 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20
-7 20 -20z m-203 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m5520 -10 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2323 -15 c-1
-18 -18 -35 -24 -24 -4 6 -4 16 -1 21 7 12 25 13 25 3z m-3826 -43 c3 0 3 6 0
14 -3 8 -1 17 4 20 26 16 16 -40 -18 -110 -44 -86 -46 -89 -54 -76 -3 5 8 28
25 50 17 23 27 42 23 42 -4 0 -3 5 4 12 7 7 12 19 12 28 0 13 -1 13 -10 0 -7
-11 -10 -4 -9 25 0 34 2 37 9 18 5 -13 12 -23 14 -23z m1206 -4 c0 -2 -7 -7
-16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-540 -37 c0 -6 -4 -7 -10 -4 -5
3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-109 -64 c4 3 9 -6 11 -20
4 -20 3 -23 -8 -14 -15 12 -17 9 -8 -15 3 -8 11 -13 17 -10 7 2 17 -6 22 -18
6 -13 20 -27 33 -33 27 -12 27 -17 2 -33 -18 -11 -20 -10 -20 8 0 11 -4 20
-10 20 -5 0 -10 7 -10 15 0 8 -2 15 -4 15 -2 0 -13 3 -23 6 -17 6 -17 6 0 -7
10 -8 18 -23 18 -34 -1 -147 -3 -166 -17 -172 -8 -3 -23 2 -33 11 -10 9 -20
13 -23 10 -3 -3 -9 -1 -13 5 -3 7 2 11 14 11 29 0 34 29 29 192 -3 111 -1 132
7 99 5 -23 13 -40 16 -36z m594 65 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m-300 -84 c0 -46 -6 -80 -14 -90 -9 -10 -10
-16 -2 -16 19 0 14 -77 -6 -85 -10 -3 -19 -2 -21 4 -2 5 -10 7 -19 4 -13 -5
-15 2 -12 40 2 26 1 48 -3 49 -4 2 1 14 12 28 10 14 22 21 27 17 4 -5 0 -15
-10 -22 -10 -7 -14 -16 -9 -19 5 -3 7 -17 4 -31 -2 -14 0 -25 6 -25 14 0 22
24 15 48 -2 11 0 23 6 26 6 4 11 22 10 39 0 27 -2 29 -9 12 -15 -35 -11 -1 6
48 9 26 17 47 17 47 1 0 2 -33 2 -74z m-1001 58 c13 -5 16 -22 16 -80 0 -43 4
-74 10 -74 6 0 10 6 10 14 0 8 4 17 9 21 14 8 1 -66 -16 -93 -9 -14 -12 -35
-8 -56 7 -33 25 -48 70 -60 11 -3 2 -3 -20 -1 -22 3 -41 9 -43 14 -2 5 -12 7
-22 4 -11 -3 -20 0 -20 6 0 6 8 11 17 11 14 0 15 2 4 13 -10 10 -15 8 -27 -7
-13 -19 -14 -18 -14 8 0 21 4 27 15 23 8 -4 17 -2 20 3 4 6 1 10 -4 10 -6 0
-11 5 -11 11 0 5 4 8 9 4 11 -6 44 55 35 64 -4 3 -9 1 -11 -5 -2 -6 -11 -20
-19 -30 -15 -18 -15 -18 -10 5 3 14 0 31 -5 38 -13 15 -24 125 -16 147 7 18 9
18 31 10z m106 -1 c0 -5 -10 -19 -21 -33 l-21 -25 5 27 c2 15 -1 29 -7 32 -6
2 1 5 17 5 15 1 27 -2 27 -6z m1164 -8 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15
0 8 4 15 10 15 5 0 7 -7 4 -15z m-824 -44 c-1 -13 -4 -12 -15 3 -8 11 -15 27
-15 35 1 13 4 12 15 -3 8 -11 15 -27 15 -35z m801 -36 l-9 -50 -1 46 c0 25 2
49 6 52 11 12 12 3 4 -48z m-966 25 c-34 -5 -40 -7 -19 -8 16 -1 36 -8 43 -14
10 -11 14 -10 22 5 11 19 36 22 59 7 11 -7 5 -8 -19 -3 -23 4 -32 3 -28 -4 4
-6 0 -17 -9 -24 -13 -10 -17 -10 -24 0 -5 9 -10 10 -14 2 -4 -6 -24 -12 -44
-13 -21 -1 -7 -4 33 -8 l70 -6 -86 -2 c-73 -2 -90 1 -110 18 -19 15 -20 19 -8
20 9 0 32 7 50 16 33 15 13 14 -26 -2 -18 -7 -18 -6 -6 9 11 14 29 17 90 15
72 -1 73 -1 26 -8z m-215 -19 c0 -11 4 -21 9 -23 5 -1 3 -14 -5 -28 l-14 -25
6 26 c3 17 0 32 -10 42 -20 20 -20 27 -1 27 8 0 15 -9 15 -19z m1550 -53 c0
-19 5 -28 16 -28 16 0 16 -20 -1 -23 -11 -2 -45 89 -37 98 9 9 22 -18 22 -47z
m-453 -90 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-626 -21
c-13 -13 -14 5 -3 43 l7 25 3 -29 c2 -16 -1 -33 -7 -39z m244 11 c8 -33 -3
-88 -16 -88 -5 0 -9 10 -10 23 -1 21 -1 21 -10 -3 -7 -19 -7 -16 -3 15 3 22 8
48 11 59 3 12 1 16 -6 12 -6 -4 -11 -3 -11 3 0 5 9 9 20 8 12 -1 21 -11 25
-29z m653 -25 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m62
-33 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-903 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m48 -4 c19
-8 19 -9 -2 -9 -12 -1 -25 4 -28 9 -7 12 2 12 30 0z m452 2 c7 -4 -20 -8 -60
-8 -48 0 -62 2 -42 7 42 9 88 10 102 1z m3143 -442 c0 -22 -3 -40 -7 -40 -5 0
-8 18 -8 40 0 22 3 40 8 40 4 0 7 -18 7 -40z m-7780 -380 c0 -11 -4 -20 -10
-20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m7629 -70 c6 8 13
12 16 9 10 -11 -6 -37 -26 -42 -15 -4 -19 0 -19 21 0 21 2 24 10 12 9 -13 11
-13 19 0z m-69 -90 c0 -24 -6 -25 -26 -6 -19 20 -18 26 6 26 13 0 20 -7 20
-20z m47 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-7447
-148 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10
-20z m7229 -196 c-16 -31 -29 -30 -29 2 0 14 4 23 9 19 5 -3 13 5 17 17 7 21
8 21 12 4 2 -10 -2 -29 -9 -42z m-6909 -284 c0 -13 -7 -20 -20 -20 -13 0 -20
7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m6686 -149 c-9 -8 -18 -12 -22
-9 -9 10 3 25 21 25 14 0 14 -2 1 -16z m-246 -171 c0 -13 -7 -20 -20 -20 -13
0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-3480 -159 c11 -14 20
-17 35 -11 11 5 16 9 12 9 -5 1 -2 7 5 14 10 10 99 12 413 11 220 -1 403 -2
408 -3 4 0 7 -10 7 -21 0 -20 -7 -20 -395 -20 -330 0 -395 -2 -395 -14 0 -22
-36 -26 -51 -5 -12 17 -13 17 -27 1 -7 -9 -11 -12 -8 -6 3 7 -3 9 -19 6 -14
-2 -25 -9 -25 -14 0 -5 4 -6 10 -3 6 4 7 -1 3 -11 -4 -10 -8 -13 -10 -7 -3 7
-10 11 -16 9 -7 -1 -9 -1 -4 1 13 8 8 33 -8 39 -8 4 -15 14 -15 24 0 13 -9 20
-27 23 -16 2 -1 2 32 1 44 -3 64 -9 75 -23z m-160 -25 c0 -31 -5 -46 -16 -50
-22 -8 -14 -51 9 -50 18 2 24 -21 7 -31 -5 -3 -10 -1 -10 4 0 6 -4 10 -9 9 -4
-2 -18 8 -29 22 l-20 25 5 -28 c3 -16 1 -27 -6 -27 -12 0 -14 8 -11 65 1 22 9
45 18 53 13 10 13 12 2 7 -12 -5 -12 -4 1 7 9 7 14 19 12 26 -3 7 5 12 21 12
24 0 26 -3 26 -44z m3480 24 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7
20 20 20 13 0 20 -7 20 -20z m-3280 -70 c8 -5 11 -12 7 -17 -5 -4 -12 -1 -18
7 -7 13 -10 13 -18 1 -5 -8 -11 -9 -15 -3 -3 6 0 13 6 15 19 8 22 8 38 -3z
m44 -5 c-4 -8 -7 -15 -9 -15 -2 0 -5 7 -9 15 -3 9 0 15 9 15 9 0 12 -6 9 -15z
m50 -1 c4 -11 2 -15 -8 -11 -7 3 -19 0 -25 -6 -6 -6 -11 -7 -11 -3 0 7 28 34
36 36 1 0 5 -7 8 -16z m42 -1 c13 -17 13 -17 14 0 0 9 4 17 9 17 5 0 7 -9 4
-20 -6 -22 -33 -28 -34 -7 0 6 -4 3 -9 -8 -8 -17 -8 -16 -7 8 2 32 5 33 23 10z
m82 10 c-10 -2 -15 -10 -11 -19 3 -8 -1 -14 -8 -14 -7 0 -9 3 -6 7 4 3 2 12
-4 20 -9 10 -6 13 17 12 16 -1 21 -3 12 -6z m56 -5 c5 -10 7 -10 12 0 3 6 10
12 15 12 6 0 7 -4 4 -10 -3 -5 -1 -10 4 -10 6 0 10 -15 10 -32 -2 -32 -2 -32
-6 -4 -4 23 -8 27 -28 24 -17 -4 -25 0 -28 14 -6 20 8 25 17 6z m191 3 c38 -8
39 -13 5 -15 -9 -1 -16 -13 -18 -28 -2 -16 -7 -28 -11 -28 -5 0 -6 11 -3 25 4
24 3 25 -52 25 -49 0 -55 2 -44 15 14 16 62 18 123 6z m232 -5 c-8 -10 -17
-11 -30 -4 -14 7 -22 5 -34 -9 -8 -10 -12 -13 -8 -6 4 9 -5 11 -32 8 -21 -2
-43 -4 -50 -4 -6 -1 -13 -9 -15 -18 -3 -15 -5 -14 -10 5 -3 12 -2 20 3 16 5
-3 9 1 9 8 0 10 24 14 90 15 75 2 88 0 77 -11z m46 0 c3 -8 -2 -16 -14 -19
-16 -4 -17 -2 -8 14 12 23 15 23 22 5z m81 -8 c9 -12 16 -18 17 -13 1 6 4 -1
8 -15 6 -23 12 -25 69 -26 34 -1 67 -2 72 -3 6 0 10 -24 10 -53 0 -40 -3 -49
-12 -40 -7 7 -19 12 -27 12 -11 -1 -7 -7 11 -20 19 -15 25 -26 20 -42 -4 -15
-2 -19 8 -13 10 6 11 -1 6 -31 -4 -22 -8 -59 -9 -84 l-2 -45 50 0 c28 1 53 -4
57 -10 5 -6 8 -4 8 5 0 13 1 13 10 0 5 -8 10 -10 10 -4 0 15 142 14 152 -1 5
-6 8 -5 8 3 0 10 3 10 15 -2 19 -19 20 -56 1 -56 -8 0 -17 6 -20 13 -5 10 -7
10 -12 0 -3 -7 -10 -13 -15 -13 -6 0 -7 5 -4 10 3 6 2 10 -4 10 -5 0 -17 3
-27 7 -13 5 -16 3 -11 -10 3 -9 2 -17 -3 -17 -6 0 -10 5 -10 10 0 6 -29 10
-67 10 -67 -1 -68 -1 -38 -16 17 -8 23 -12 14 -10 -9 2 -37 8 -63 11 -25 4
-43 11 -41 16 3 4 13 6 23 3 12 -4 11 -2 -3 7 -11 7 -24 18 -28 26 -5 9 -16
11 -29 7 -21 -6 -21 -6 0 17 27 29 19 39 -11 13 -36 -31 -65 -44 -97 -44 -38
0 -41 30 -5 47 20 9 25 8 25 -3 0 -8 -7 -14 -15 -14 -8 0 -15 -4 -15 -10 0 -5
9 -10 20 -10 11 0 20 5 20 10 0 6 9 10 19 10 29 0 35 16 38 106 3 70 0 83 -16
98 -11 10 -28 16 -38 13 -14 -4 -12 -5 7 -7 l25 -2 -25 -7 c-22 -6 -25 -12
-23 -46 l1 -40 -8 35 c-4 21 -8 27 -9 15 -2 -19 -3 -19 -10 -1 -4 11 -16 23
-27 27 -16 7 -15 8 6 6 26 -2 36 16 11 20 -22 3 -38 23 -18 23 9 0 17 6 17 13
0 9 -13 14 -37 13 -97 -1 -226 5 -220 10 3 4 57 6 120 5 93 -2 116 1 127 14 7
8 10 18 6 22 -3 3 -6 1 -6 -5 0 -7 -4 -12 -9 -12 -5 0 -7 9 -4 20 3 12 -1 22
-11 26 -17 7 -22 34 -7 34 6 0 17 -10 25 -22z m-919 -8 c2 -3 -21 -6 -50 -6
-30 0 -49 4 -42 8 13 9 86 7 92 -2z m345 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-240 -31 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m405 5 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m155 -16 c0 -8 -7 -14 -15 -14
-17 0 -18 2 -9 24 7 18 24 12 24 -10z m210 -6 c0 -5 -4 -8 -10 -8 -5 0 -10 10
-10 23 0 18 2 19 10 7 5 -8 10 -18 10 -22z m-896 14 c3 -5 -8 -6 -25 -4 -16 2
-29 6 -29 8 0 8 49 4 54 -4z m86 -8 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0
5 5 9 10 9 6 0 10 -7 10 -16z m80 -5 c0 -5 -7 -9 -15 -9 -9 0 -12 6 -9 15 6
15 24 11 24 -6z m27 -1 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m60 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m60 0 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m43 7 c0 -8 -4 -15 -9 -15 -13 0
-22 16 -14 24 11 11 23 6 23 -9z m50 0 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15
15 0 8 7 15 15 15 8 0 15 -7 15 -15z m210 0 c0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m200 0 c0 -8 -7 -15 -15 -15 -9 0
-12 6 -9 15 4 8 10 15 15 15 5 0 9 -7 9 -15z m35 6 c3 -5 1 -12 -5 -16 -5 -3
-10 1 -10 9 0 18 6 21 15 7z m-83 -13 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12
19 0 21 -3 12 -12z m2468 -28 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13
7 20 20 20 13 0 20 -7 20 -20z m-3278 -20 c6 0 7 -12 3 -30 -5 -23 -3 -30 8
-30 8 0 20 -7 27 -15 16 -19 7 -19 -32 1 -18 10 -48 14 -77 12 l-46 -3 -3 43
-3 42 33 -4 c47 -6 66 -15 71 -34 2 -10 3 -1 1 18 -1 19 0 27 2 18 3 -10 10
-18 16 -18z m698 20 c3 0 -2 -5 -10 -10 -8 -5 -32 -10 -52 -10 -21 0 -38 -4
-38 -10 0 -5 17 -10 38 -11 20 0 46 -4 56 -8 19 -7 19 -8 0 -27 -15 -15 -19
-33 -19 -94 0 -77 -10 -130 -26 -130 -4 0 -5 8 -2 18 6 15 5 16 -12 2 -17 -14
-18 -13 -11 5 5 11 11 30 13 42 3 13 10 20 18 17 10 -4 12 1 8 15 -4 16 -9 19
-18 11 -9 -8 -12 -3 -11 20 1 16 4 30 9 30 4 0 7 7 7 16 0 14 -9 16 -56 12
-31 -3 -59 -10 -64 -17 -5 -9 -7 -5 -7 11 0 19 4 24 21 23 14 -2 15 -1 3 2
-30 7 -13 28 24 30 31 2 32 1 9 -7 -19 -7 -15 -8 20 -4 25 2 55 6 67 7 12 1
19 5 15 8 -4 4 -79 8 -167 11 -88 2 -140 2 -115 0 32 -2 40 -6 30 -13 -13 -8
-13 -11 1 -22 22 -17 32 -48 13 -40 -7 3 -14 0 -14 -6 0 -6 5 -11 10 -11 6 0
10 -26 10 -59 0 -37 5 -63 13 -70 8 -6 42 -10 78 -8 54 2 64 -1 67 -15 3 -14
-3 -18 -27 -18 -22 0 -31 5 -31 17 0 15 -1 15 -18 0 -24 -22 -29 -22 -68 8
-18 14 -37 25 -42 25 -5 0 -9 28 -9 63 -1 84 -12 137 -29 137 -17 0 -18 -12
-3 -27 6 -6 13 -47 16 -92 l6 -81 -51 0 c-44 0 -53 4 -71 29 -23 32 -27 51
-11 51 6 0 10 7 10 15 0 22 37 37 72 30 28 -7 30 -5 23 16 -4 13 -11 25 -16
27 -4 2 -6 9 -3 16 4 10 -6 13 -35 11 -23 -1 -41 2 -41 6 0 5 -9 9 -21 9 -11
0 -17 4 -14 10 3 5 25 7 50 3 29 -4 50 -1 59 7 11 8 37 10 93 5 43 -4 75 -4
71 0 -4 4 -96 8 -205 9 -109 1 -179 0 -155 -2 23 -2 42 -7 42 -12 0 -4 -18 -6
-40 -3 -26 4 -40 2 -40 -6 0 -6 18 -11 43 -11 23 0 49 -5 57 -11 13 -9 12 -10
-2 -5 -12 3 -15 1 -11 -10 7 -18 -4 -18 -23 0 -13 14 -45 14 -139 3 -15 -1
-20 4 -22 23 -1 14 0 18 3 9 3 -11 13 -15 30 -11 35 6 39 20 10 34 -14 6 -26
14 -26 17 0 4 580 5 660 1z m30 -14 c0 -8 6 -17 13 -19 6 -3 -4 -5 -23 -5 -19
0 -29 2 -22 5 6 2 12 11 12 19 0 8 5 14 10 14 6 0 10 -6 10 -14z m145 -16 c47
-7 45 -7 -32 -9 -50 0 -83 3 -83 9 0 11 43 12 115 0z m-115 -30 c0 -5 8 -7 18
-4 13 5 14 4 3 -4 -10 -7 -13 -30 -12 -81 2 -77 -13 -97 -22 -29 -4 27 -2 45
6 50 6 4 8 10 5 13 -16 16 -28 -10 -28 -61 0 -47 2 -54 20 -54 11 0 20 -7 20
-16 0 -12 -9 -14 -37 -12 l-38 3 0 75 c0 73 1 75 25 75 18 0 26 6 28 23 2 12
-2 22 -8 22 -6 0 -8 -4 -5 -10 3 -5 -1 -10 -11 -10 -13 0 -14 3 -4 15 14 17
40 20 40 5z m173 -3 c20 -11 23 -17 15 -25 -9 -9 -6 -12 13 -12 22 -1 21 -2
-10 -15 -40 -18 -41 -18 -41 -4 0 5 5 7 11 3 8 -4 8 0 0 15 -9 16 -9 21 0 21
32 0 -7 14 -40 15 -31 1 -38 -2 -33 -15 5 -13 3 -13 -11 -1 -9 7 -14 17 -11
22 8 14 78 11 107 -4z m-3213 -7 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m2800 6 c0 -2 -16 -6 -35 -8 -19 -2 -35 0 -35 4
0 4 16 8 35 8 19 0 35 -2 35 -4z m-370 -16 c8 13 11 12 24 -5 8 -11 12 -24 8
-29 -3 -6 -6 -42 -6 -80 l-1 -70 -45 3 c-53 3 -66 12 -73 48 -8 40 8 69 43 82
44 15 34 37 -16 33 -26 -2 -36 0 -29 7 14 13 68 18 78 6 4 -6 11 -4 17 5z
m161 -12 c-13 -4 -27 -6 -32 -2 -5 3 -9 1 -9 -5 0 -7 17 -8 45 -4 28 3 45 2
45 -5 0 -5 -9 -8 -19 -5 -10 3 -28 0 -39 -6 -16 -8 -25 -6 -46 14 l-26 24 43
3 c51 4 68 -2 38 -14z m199 -9 c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0 21
-6 21 -21z m319 3 c8 -15 5 -18 -15 -20 -18 -1 -24 3 -24 18 0 25 26 26 39 2z
m76 9 c3 -5 1 -12 -5 -16 -5 -3 -7 -19 -3 -35 4 -20 1 -30 -7 -30 -8 0 -11
-14 -8 -42 2 -34 0 -43 -14 -46 -15 -3 -18 5 -20 50 -3 78 0 98 13 114 13 16
35 18 44 5z m-1005 -21 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
6 0 11 -4 11 -10z m120 -10 c27 -27 27 -93 -1 -121 -15 -15 -30 -19 -62 -17
l-42 3 -3 64 c-2 35 -1 70 2 77 8 22 82 18 106 -6z m99 16 c-2 -2 -21 -11 -42
-20 -27 -11 -37 -12 -35 -4 3 7 15 15 29 19 28 8 54 11 48 5z m121 -10 c0 -8
7 -17 16 -20 16 -6 16 -8 0 -24 -17 -20 -22 -72 -6 -72 6 0 10 -7 10 -15 0 -9
-9 -15 -25 -15 -23 0 -25 4 -25 43 0 24 -5 48 -10 53 -7 7 -6 19 2 37 14 28
38 37 38 13z m842 -41 c-4 -56 -6 -60 -21 -50 -6 3 -11 29 -11 56 0 43 3 50
18 47 14 -3 16 -12 14 -53z m-1182 -46 c0 -38 -4 -69 -10 -69 -5 0 -6 7 -3 17
4 11 3 14 -5 9 -9 -5 -12 11 -12 58 0 54 3 64 15 60 11 -5 15 -23 15 -75z
m608 3 c3 -57 1 -62 -18 -62 -18 0 -20 6 -20 58 0 32 3 62 7 66 17 17 28 -5
31 -62z m106 46 c14 -23 2 -32 -23 -18 -12 6 -21 7 -21 2 0 -5 11 -14 25 -20
31 -14 41 -37 25 -57 -21 -26 -90 -16 -90 12 0 11 7 13 30 8 17 -4 30 -4 30
-1 0 3 -13 12 -30 21 -31 16 -39 42 -18 63 17 17 58 11 72 -10z m206 -43 c0
-37 -4 -65 -10 -65 -5 0 -10 -8 -10 -17 0 -15 2 -16 10 -3 8 13 10 12 10 -2 0
-10 -7 -18 -15 -18 -15 0 -19 29 -15 115 2 44 6 55 21 55 5 0 9 -29 9 -65z
m195 49 c9 -9 15 -26 13 -38 -2 -16 -11 -22 -40 -24 -21 -2 -38 -8 -38 -14 0
-6 13 -8 35 -4 27 5 35 4 35 -7 0 -33 -72 -36 -94 -5 -13 18 -16 19 -16 6 0
-22 27 -48 51 -48 10 0 19 -6 19 -13 0 -8 -7 -14 -15 -14 -8 0 -12 4 -9 9 3 4
0 8 -5 8 -6 0 -11 -4 -11 -8 0 -5 -7 -9 -15 -9 -21 0 -19 15 3 19 15 3 15 4 1
10 -10 4 -25 -1 -36 -10 -23 -21 -48 -27 -37 -11 5 8 2 10 -8 7 -8 -4 -15 -1
-15 5 0 7 14 12 31 12 29 0 30 3 42 59 7 35 19 65 30 73 24 18 59 16 79 -3z
m-3227 -1 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m5332 -13
c0 -13 7 -20 20 -20 13 0 20 -7 20 -20 0 -12 -7 -20 -18 -20 -10 0 -28 -7 -40
-16 -14 -10 -22 -11 -22 -4 0 7 9 14 20 17 11 3 20 14 20 24 0 12 -7 19 -20
19 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-5326 -19 c3 -4 3
-11 0 -14 -8 -8 -34 3 -34 14 0 11 27 12 34 0z m83 -47 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m3211 -17 c2 -12 -3 -17 -17 -17 -12 0 -21 6
-21 13 0 31 32 34 38 4z m-968 -37 c22 0 40 -4 40 -8 0 -5 -21 -7 -46 -6 -45
2 -89 28 -78 45 2 5 14 -1 24 -11 13 -13 34 -20 60 -20z m86 2 c-2 -4 11 -7
30 -7 21 0 34 5 34 13 0 6 4 12 8 12 4 0 8 -8 8 -18 0 -15 -9 -18 -51 -18 -27
1 -49 4 -47 9 1 4 2 17 2 30 1 20 2 20 11 4 5 -9 8 -20 5 -25z m-2341 17 c11
-17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m2145 -19 c-8 -5 -25
-10 -37 -10 -13 0 -23 -5 -23 -10 0 -7 -13 -8 -35 -4 l-35 6 0 -74 0 -75 55
-6 c31 -3 74 -4 98 -2 38 4 40 3 23 -10 -13 -10 -17 -21 -12 -39 8 -33 -23
-53 -55 -36 -11 7 -24 9 -27 5 -11 -10 -8 -59 3 -67 6 -5 6 -11 -2 -16 -6 -4
-13 -30 -16 -57 l-3 -50 -2 53 c-1 29 -9 67 -18 84 -9 17 -14 41 -11 54 2 13
-1 25 -7 27 -13 5 -9 -61 4 -78 5 -6 11 -39 14 -75 l6 -65 -13 66 c-7 37 -18
74 -26 82 -7 9 -10 21 -6 27 3 5 2 10 -4 10 -5 0 -8 13 -6 28 4 34 -8 57 -24
48 -8 -5 -7 -12 4 -22 8 -9 15 -21 15 -27 0 -7 -14 2 -30 20 -16 18 -32 28
-35 23 -3 -5 9 -25 27 -43 110 -111 97 -312 -28 -426 -69 -63 -138 -86 -244
-78 -123 8 -215 68 -267 175 -37 75 -38 182 -3 254 53 112 174 187 301 188 45
0 135 -27 171 -52 29 -20 38 -22 38 -9 0 5 -23 21 -52 35 -29 15 -43 26 -32
26 10 0 34 -9 52 -20 43 -27 41 -11 -3 18 -37 23 -75 29 -75 11 0 -7 -7 -8
-17 -4 -31 11 -165 16 -158 5 3 -5 0 -7 -9 -4 -8 3 -33 -4 -56 -16 -23 -12
-44 -19 -47 -16 -3 2 12 13 32 24 l37 19 -35 -1 c-19 -1 -33 -4 -30 -8 2 -5
-7 -8 -21 -8 -14 0 -28 5 -31 10 -3 6 2 10 12 10 10 0 24 9 31 20 7 11 18 20
25 20 8 0 13 10 13 23 -1 18 4 22 29 21 129 -6 204 -6 213 0 6 3 13 2 17 -4 4
-6 12 -7 18 -3 7 4 48 9 92 10 44 2 107 7 140 11 33 5 65 10 70 10 6 1 3 -3
-5 -8z m377 -16 c0 -8 -7 -14 -14 -14 -8 0 -11 5 -7 11 4 7 -3 10 -17 9 -41
-4 -55 2 -24 9 38 9 62 3 62 -15z m33 16 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m262 -12 c-7 -7 -12 -8 -12 -2 0 14 12 26
19 19 2 -3 -1 -11 -7 -17z m-637 2 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
2 10 4 10 3 0 8 -4 11 -10z m400 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
2 10 4 10 3 0 8 -4 11 -10z m305 0 c-19 -13 -30 -13 -30 0 0 6 10 10 23 10 18
0 19 -2 7 -10z m2330 -20 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-1726 -51 c14 -7 23 -16 20 -20 -3 -5 0 -9 5 -9 6 0 11 5
11 10 0 6 11 10 25 10 29 0 33 -14 8 -26 -24 -10 -36 -11 -48 -5 -20 11 -135
11 -135 0 0 -12 -42 -8 -51 4 -3 5 -24 4 -45 -2 -47 -13 -64 -14 -64 -3 0 12
97 30 113 21 7 -4 39 -5 71 -2 42 4 56 9 52 19 -7 17 5 18 38 3z m136 -29 c0
-11 -7 -20 -15 -20 -8 0 -15 -5 -15 -11 0 -6 7 -9 15 -5 9 3 15 0 15 -9 0 -9
9 -15 21 -15 12 0 28 -8 35 -17 7 -10 14 -13 14 -6 0 7 16 12 40 11 40 0 40
-1 40 -39 0 -32 -4 -39 -19 -39 -11 0 -21 -6 -23 -12 -6 -16 -26 3 -53 50 -11
17 -25 32 -31 32 -7 0 -19 6 -26 13 -7 8 -25 20 -38 27 -19 10 -21 15 -10 18
21 7 40 28 33 36 -4 3 -1 6 5 6 7 0 12 -9 12 -20z m-2150 -51 c0 -11 7 -19 18
-20 14 0 14 -2 2 -7 -25 -11 -70 -55 -70 -67 0 -7 -8 -27 -17 -46 -18 -35 -18
-37 -22 -79 l-3 -25 -10 28 c-6 17 -6 32 -1 40 10 13 9 63 -2 89 -10 24 -2 71
13 76 16 5 16 -30 0 -46 -10 -10 -10 -17 0 -32 6 -10 9 -27 5 -37 -5 -15 -3
-16 8 -5 7 6 12 27 11 46 -2 34 18 63 37 52 5 -4 12 -2 16 4 3 5 2 10 -3 10
-5 0 -7 11 -4 25 5 28 22 24 22 -6z m1786 6 c-22 -17 -46 -20 -46 -6 0 11 18
19 45 20 20 0 20 0 1 -14z m219 -15 c3 -5 3 -21 -1 -35 -5 -20 -12 -25 -40
-25 -42 0 -82 -34 -90 -77 -9 -46 38 -93 93 -93 53 0 82 23 89 71 5 28 11 39
24 39 10 0 25 3 34 6 13 5 16 -1 16 -33 0 -42 -11 -66 -121 -260 -13 -23 -33
-44 -44 -47 -31 -9 -73 -7 -80 3 -3 5 11 36 30 68 19 32 35 63 35 68 0 6 -17
17 -37 24 -142 50 -153 220 -18 282 43 20 100 25 110 9z m-3072 -9 c4 -1 7
-10 7 -21 0 -17 -7 -20 -40 -20 -29 0 -40 4 -40 15 0 8 -6 16 -12 16 -7 1 1 5
17 8 17 4 37 6 45 5 8 -1 18 -3 23 -3z m1837 -1 c13 -8 13 -10 -2 -10 -9 0
-20 5 -23 10 -8 13 5 13 25 0z m230 6 c-3 -3 -34 -7 -68 -9 -35 -3 -66 -8 -70
-12 -4 -4 25 -5 64 -1 60 6 73 4 85 -10 13 -16 12 -17 -6 -9 -11 4 -72 8 -135
8 -63 0 -98 1 -77 4 20 2 37 10 37 16 0 8 28 13 88 15 48 2 85 1 82 -2z m138
-4 c14 -1 22 -7 19 -14 -2 -7 -11 -12 -19 -10 -29 5 -52 -35 -49 -87 1 -19 4
-22 13 -13 8 8 8 15 -2 27 -7 8 -9 15 -4 15 15 0 30 -14 37 -32 4 -12 2 -15
-9 -11 -7 3 -14 1 -14 -5 0 -6 4 -12 10 -14 6 -2 5 -17 -3 -39 -7 -20 -18 -34
-25 -31 -6 2 -12 -1 -12 -7 0 -6 6 -11 13 -12 9 0 9 -2 -1 -6 -10 -4 -10 -8
-1 -19 7 -8 9 -18 5 -21 -12 -13 -26 21 -26 65 0 42 -15 76 -54 119 -10 13
-16 26 -12 30 4 4 11 2 15 -6 5 -7 12 -13 17 -12 5 0 18 -25 28 -55 20 -53 38
-65 45 -29 2 13 -1 16 -13 12 -11 -4 -16 -1 -16 11 0 9 -11 38 -24 65 -13 26
-20 47 -15 47 6 0 7 5 3 11 -13 21 10 26 94 21z m84 -11 c23 -13 41 -16 58
-11 24 8 24 8 -5 9 -23 1 -27 3 -15 11 22 14 72 11 94 -5 19 -14 18 -15 -10
-15 -46 0 -123 -26 -149 -50 -25 -24 -64 -18 -44 7 7 7 18 12 25 9 7 -3 18 2
26 11 10 13 10 17 0 20 -6 3 -10 9 -6 14 3 5 0 9 -5 9 -6 0 -11 -9 -11 -21 0
-11 -5 -17 -10 -14 -7 4 -7 13 0 26 13 23 11 23 52 0z m242 8 c-4 -8 1 -9 17
-5 21 6 22 6 6 -4 -14 -9 -26 -8 -50 4 l-32 16 33 0 c22 0 31 -4 26 -11z m191
-6 c-10 -10 -11 -18 -2 -37 8 -16 16 -21 21 -16 6 6 4 15 -7 23 -17 11 -17 13
0 27 10 8 29 14 43 13 58 -3 56 -2 40 -13 -8 -6 -27 -7 -42 -4 -21 4 -28 2
-28 -10 0 -9 5 -16 10 -16 15 0 12 -15 -9 -39 -14 -16 -20 -18 -24 -8 -3 9
-17 12 -40 9 -25 -3 -34 -8 -30 -18 6 -17 -42 -20 -53 -3 -12 20 -98 69 -120
69 -13 0 -25 4 -28 9 -6 9 42 0 77 -14 10 -4 17 -3 17 4 0 6 7 11 15 11 8 0
15 5 15 10 0 6 10 10 23 10 15 0 26 -9 35 -30 8 -16 20 -30 28 -30 17 0 17 5
4 31 -15 27 -6 37 34 36 27 -1 31 -3 21 -14z m-2833 -10 c27 -24 22 -33 -12
-22 -30 11 -36 20 -23 32 9 10 15 9 35 -10z m3267 -9 c17 -14 31 -33 31 -42 0
-14 -4 -12 -18 6 -10 12 -31 32 -47 42 -20 13 -25 20 -13 20 9 0 30 -12 47
-26z m1394 19 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-2743
-29 c0 -9 -5 -14 -12 -12 -18 6 -21 28 -4 28 9 0 16 -7 16 -16z m1330 -19 c34
-36 21 -39 -18 -4 -18 16 -26 29 -19 29 7 0 24 -11 37 -25z m-1080 5 c0 -5 -4
-10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-29 -26 c22
-14 48 -38 57 -55 10 -16 20 -29 25 -29 4 0 7 -13 7 -30 0 -16 -2 -30 -4 -30
-3 0 -22 10 -43 22 -32 19 -56 24 -148 26 -105 3 -112 2 -169 -26 l-58 -30 6
32 c8 40 33 73 85 114 40 30 48 32 122 32 68 0 85 -4 120 -26z m1081 6 c12
-11 19 -22 16 -26 -6 -5 -48 31 -48 41 0 10 11 5 32 -15z m-1402 -14 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m9 -38 c-8
-14 -14 -18 -17 -10 -4 13 17 46 25 38 3 -2 -1 -15 -8 -28z m511 22 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m841 0 c27 -15
24 -56 -5 -75 -49 -32 -109 24 -70 66 19 21 45 24 75 9z m-421 -41 c0 -6 -4
-7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-2327 -18 c4
-1 7 -10 7 -21 0 -18 -7 -20 -75 -20 -68 0 -75 2 -75 19 0 15 8 21 33 23 28 3
88 2 110 -1z m2147 -10 c8 -16 5 -25 -16 -48 -15 -15 -21 -20 -14 -11 6 9 10
24 8 32 -3 14 -6 14 -32 1 -34 -18 -58 -13 -49 10 13 35 86 46 103 16z m-2315
-1 c-3 -5 -1 -10 4 -10 6 0 11 -4 11 -10 0 -5 -7 -10 -15 -10 -8 0 -15 2 -15
4 0 2 -3 11 -6 20 -4 11 -1 16 11 16 9 0 13 -4 10 -10z m2525 -31 c0 -24 -4
-38 -10 -34 -5 3 -10 22 -10 41 0 19 5 34 10 34 6 0 10 -18 10 -41z m30 21 c0
-11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z
m-870 -42 c7 -18 9 -19 9 -5 1 19 0 19 90 19 38 0 67 -5 71 -12 5 -8 9 -6 14
6 5 12 10 14 17 7 8 -8 8 -21 -1 -47 -12 -37 -40 -50 -40 -18 -1 16 -1 16 -11
0 -16 -29 -32 -21 -25 12 7 38 -19 42 -28 5 -7 -29 -29 -24 -24 5 5 30 -16 24
-25 -7 -8 -26 -13 -29 -58 -30 -48 -1 -49 0 -43 26 15 62 38 78 54 39z m574
-13 c4 -10 -1 -15 -14 -15 -20 0 -26 11 -13 23 10 11 20 8 27 -8z m-2099 -25
c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z
m3815 -11 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
-16z m-2460 -29 l8 25 1 -25 c1 -14 8 -34 16 -44 8 -11 15 -24 15 -29 0 -14
-21 4 -29 25 -7 19 -8 19 -25 -7 -15 -23 -16 -29 -5 -36 8 -5 19 -8 24 -8 6 0
4 3 -2 6 -13 5 -19 33 -7 33 17 0 31 -29 26 -58 -3 -20 0 -32 7 -32 7 0 11 11
9 26 -2 15 3 29 10 31 7 3 10 -1 6 -10 -6 -16 33 -50 47 -41 5 3 22 -2 38 -11
17 -8 43 -15 60 -15 16 0 32 -4 35 -9 3 -5 -17 -8 -44 -8 -28 0 -46 4 -40 7 5
3 -4 11 -20 17 -26 10 -44 2 -22 -10 4 -2 0 -2 -8 1 -51 15 -53 15 -47 0 4
-12 1 -14 -14 -11 -10 3 -19 11 -19 18 0 7 -7 18 -15 25 -8 7 -12 17 -8 24 3
6 3 8 -2 4 -4 -4 -14 0 -22 10 -11 15 -12 13 -3 -11 8 -22 6 -34 -6 -52 -15
-23 -42 -26 -29 -4 4 5 2 9 -4 9 -5 0 -13 -4 -16 -10 -3 -5 -2 -10 4 -10 5 0
6 -11 2 -27 -8 -29 -38 -42 -78 -35 -19 4 -20 6 -8 18 16 17 29 19 20 3 -5 -6
0 -6 12 1 28 18 103 146 103 176 0 8 4 13 9 9 6 -3 8 9 7 27 -2 21 -1 23 2 8
l5 -25 7 25z m55 20 c13 -21 83 -41 160 -47 87 -6 183 14 218 44 16 15 17 14
17 -14 0 -40 -36 -91 -90 -127 -59 -40 -148 -48 -206 -18 -70 36 -124 107
-124 165 0 11 18 9 25 -3z m448 -23 c4 3 7 0 7 -8 0 -8 12 -32 26 -53 24 -34
24 -37 5 -27 -11 7 -24 23 -27 36 -6 23 -15 30 -36 26 -5 0 -8 9 -8 20 0 17 3
19 13 10 8 -6 17 -8 20 -4z m207 -17 c0 -17 -5 -30 -12 -31 -7 0 -21 -2 -30
-4 -10 -2 -20 1 -23 6 -10 15 15 51 31 45 8 -3 14 -1 14 4 0 6 5 10 10 10 6 0
10 -13 10 -30z m65 19 c4 -6 14 -8 23 -4 8 3 19 0 24 -7 6 -10 8 -10 8 0 0 6
5 12 10 12 6 0 10 -15 10 -32 -1 -30 -2 -31 -14 -15 -7 9 -19 17 -27 16 -10 0
-11 -2 -1 -6 24 -10 11 -23 -20 -19 -20 2 -34 10 -38 22 -6 16 -8 15 -15 -7
l-8 -24 -4 23 c-6 33 37 67 52 41z m1445 -53 c0 -3 -7 -6 -15 -6 -9 0 -15 9
-15 21 0 18 2 19 15 5 8 -8 15 -17 15 -20z m40 18 c0 -8 -4 -12 -10 -9 -5 3
-10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-1779 -18 c5 3 9 -2 9 -12 0
-13 -3 -14 -15 -4 -12 10 -15 10 -15 -3 0 -9 5 -19 11 -23 6 -3 9 -15 6 -25
-3 -10 0 -22 7 -27 14 -12 46 -12 46 0 0 4 -5 8 -11 8 -5 0 -8 4 -4 9 3 6 -2
14 -11 19 -9 5 -14 14 -10 20 9 15 86 -24 86 -44 0 -8 4 -12 10 -9 11 7 60
-23 60 -37 0 -6 -11 -8 -27 -4 -37 9 -35 9 -145 3 -54 -3 -100 -2 -103 2 -2 5
8 6 23 5 15 -2 21 -1 14 2 -10 5 -10 9 -1 20 8 9 8 14 1 14 -6 0 -14 -4 -17
-10 -3 -5 -11 -10 -17 -10 -6 0 10 22 36 50 25 27 46 55 47 62 0 10 2 10 6 0
2 -6 9 -10 14 -6z m492 -26 c3 -13 1 -18 -6 -13 -7 4 -8 0 -4 -11 10 -25 -2
-66 -19 -66 -17 0 -18 17 -1 23 6 2 9 8 5 12 -4 4 -19 -2 -34 -14 -15 -11 -34
-21 -43 -21 -14 0 -14 -2 -1 -10 11 -7 7 -10 -17 -10 -17 0 -35 5 -38 10 -3 6
2 10 12 10 10 0 43 16 73 35 30 19 51 35 47 35 -4 0 -3 9 3 20 13 25 16 25 23
0z m-2003 -30 c-13 -9 -13 -11 0 -19 22 -14 5 -24 -19 -12 -25 14 -17 41 13
41 17 0 18 -2 6 -10z m1372 -57 c-6 -2 -10 -9 -6 -14 3 -5 -13 -9 -35 -9 -23
0 -41 4 -41 10 0 5 6 7 13 4 7 -2 24 0 38 5 13 5 29 10 34 10 6 -1 4 -3 -3 -6z
m573 -21 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m555 -18 c0
-19 -4 -33 -8 -30 -8 5 -4 66 4 66 2 0 4 -16 4 -36z m-2170 0 c0 -8 -9 -14
-20 -14 -23 0 -25 6 -7 24 14 16 27 11 27 -10z m1906 -11 c-10 -10 -19 5 -10
18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m231 -10 c-3 -10 -5 -4 -5 12 0 17 2 24
5 18 2 -7 2 -21 0 -30z m91 20 c-2 -10 -13 -19 -26 -21 -17 -3 -22 2 -22 17 0
16 6 21 26 21 19 0 25 -5 22 -17z m-2304 -39 c3 -8 2 -12 -4 -9 -6 3 -10 10
-10 16 0 14 7 11 14 -7z m716 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m1400 -130 c0 -5 -9 -10 -20 -10 -11 0 -20 5
-20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m-1240 -60 c0 -13 -40 -13 -60 0
-11 7 -5 10 23 10 20 0 37 -4 37 -10z"/>
<path d="M5280 5991 c0 -6 5 -13 11 -17 8 -5 8 -8 0 -14 -13 -7 -18 -123 -7
-157 4 -12 2 -24 -4 -28 -5 -3 -10 -15 -10 -26 0 -10 -7 -19 -15 -19 -12 0
-15 -18 -15 -100 0 -60 4 -100 10 -100 6 0 10 32 10 76 0 54 3 73 11 68 8 -5
10 -39 5 -119 -4 -68 -3 -122 3 -139 9 -23 16 -26 58 -26 27 0 53 4 59 8 8 5
12 83 11 267 -1 293 6 272 -81 274 -36 1 -38 2 -24 19 9 9 13 23 11 30 -5 14
-33 17 -33 3z m40 -191 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-50 -90 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z"/>
<path d="M5423 5885 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M5424 5818 c-5 -13 -7 -104 -4 -203 l6 -180 2 165 c1 95 6 159 11
152 7 -11 9 -11 13 0 3 7 3 19 0 27 -4 11 -6 11 -14 0 -7 -10 -8 -2 -4 24 6
43 3 46 -10 15z"/>
<path d="M5233 5804 c-3 -8 -2 -19 3 -24 5 -5 9 1 9 16 0 28 -3 30 -12 8z"/>
<path d="M5013 5693 c-14 -29 -19 -273 -5 -273 4 0 5 57 4 126 -3 90 0 129 8
134 7 4 10 15 8 24 -2 11 -6 9 -15 -11z"/>
<path d="M5203 5605 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M6314 5493 c1 -111 0 -113 -56 -113 -37 0 -28 -14 10 -18 97 -9 107
-8 110 16 3 17 -2 22 -17 22 -12 0 -21 6 -21 16 0 8 -4 13 -10 9 -5 -3 -11 29
-13 82 -3 84 -4 83 -3 -14z m26 -117 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4
0 6 7 10 15 10 8 0 15 -2 15 -4z"/>
<path d="M5450 5450 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5420 5419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5415 5390 c-3 -5 -35 -10 -71 -10 -35 0 -64 -3 -64 -7 0 -5 31 -8
70 -8 53 0 74 4 87 18 16 15 16 17 1 17 -9 0 -20 -4 -23 -10z"/>
<path d="M5525 5381 c-4 -14 -17 -21 -43 -23 -35 -3 -37 -5 -40 -40 -3 -30 0
-38 14 -38 21 0 54 -37 54 -62 0 -10 7 -18 15 -18 8 0 15 -5 15 -11 0 -5 -5
-7 -12 -3 -7 4 -8 3 -4 -4 12 -20 43 -13 49 9 3 13 11 19 19 17 10 -4 13 6 12
41 -2 88 -17 110 -66 102 -5 0 -8 6 -8 14 0 8 7 15 15 15 8 0 15 5 15 10 0 18
-29 10 -35 -9z"/>
<path d="M6400 5388 c8 -9 32 -18 53 -21 42 -7 42 8 0 15 -16 3 -37 9 -48 13
-18 7 -18 6 -5 -7z"/>
<path d="M6115 5381 c-11 -5 -23 -7 -27 -4 -5 2 -8 1 -8 -4 0 -4 25 -8 55 -8
55 0 70 7 39 19 -20 8 -34 7 -59 -3z"/>
<path d="M5816 5339 c4 -7 -4 -10 -22 -7 -23 2 -30 -1 -32 -18 -2 -17 2 -21
26 -19 15 1 34 5 40 9 17 10 15 33 -4 40 -9 3 -12 2 -8 -5z"/>
<path d="M5267 5323 c-4 -3 -7 -19 -7 -34 0 -20 4 -27 14 -23 8 3 13 16 12 30
-3 27 -10 37 -19 27z"/>
<path d="M6538 5293 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5735 5270 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5818 5273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6405 5269 c-4 -6 -4 -13 -1 -16 3 -4 6 -1 6 5 0 6 8 13 18 15 15 4
15 5 0 6 -9 0 -20 -4 -23 -10z"/>
<path d="M5366 5254 c-9 -24 -2 -26 12 -4 7 11 8 20 3 20 -5 0 -12 -7 -15 -16z"/>
<path d="M5440 5255 c0 -9 6 -12 15 -9 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0
-15 -7 -15 -15z"/>
<path d="M5470 5231 c0 -16 44 -85 47 -74 5 14 -26 83 -37 83 -6 0 -10 -4 -10
-9z"/>
<path d="M5338 5202 c-10 -2 -18 -8 -18 -13 0 -13 27 -11 35 2 9 13 4 16 -17
11z"/>
<path d="M6073 5193 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
0 -8 -3 -14 -6z"/>
<path d="M6342 5180 c-18 -29 -15 -36 8 -15 20 18 26 35 12 35 -4 0 -13 -9
-20 -20z"/>
<path d="M6029 5148 c-1 -7 -1 -17 0 -22 1 -5 -5 -6 -12 -3 -11 4 -13 0 -10
-14 3 -10 7 -19 9 -19 4 0 34 57 34 65 0 11 -20 4 -21 -7z"/>
<path d="M5581 5104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5975 5110 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5990 5066 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26 -6
4 -10 -5 -10 -19z"/>
<path d="M6528 5043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5641 4993 c-1 -22 -6 -33 -16 -33 -8 0 -15 -4 -15 -10 0 -14 19 -12
34 3 7 7 9 24 5 42 l-7 30 -1 -32z"/>
<path d="M6473 4981 c1 -14 9 -26 17 -28 10 -3 11 -2 4 4 -7 4 -14 17 -17 28
-5 16 -5 15 -4 -4z"/>
<path d="M6792 4930 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6691 4769 c-23 -9 -24 -10 -5 -24 10 -8 24 -14 29 -15 6 0 4 4 -4 9
-12 8 -12 12 -2 25 15 18 15 18 -18 5z"/>
<path d="M6465 4740 c3 -5 13 -10 21 -10 8 0 12 5 9 10 -3 6 -13 10 -21 10 -8
0 -12 -4 -9 -10z"/>
<path d="M6528 4733 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7265 5830 c11 -5 27 -9 35 -9 13 -1 13 0 0 9 -8 5 -24 9 -35 9 l-20
0 20 -9z"/>
<path d="M7630 5830 c0 -3 5 -12 10 -20 9 -13 12 -13 23 0 8 10 26 14 57 12
25 -2 43 -1 40 2 -7 6 -130 13 -130 6z"/>
<path d="M7300 5800 c-24 -8 -24 -8 7 -9 17 -1 35 4 38 9 7 11 -9 11 -45 0z"/>
<path d="M7608 5785 c-3 -14 -2 -25 3 -25 4 0 10 -8 12 -17 2 -10 3 -1 2 20
-3 46 -11 57 -17 22z"/>
<path d="M7560 5795 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z"/>
<path d="M7644 5675 c0 -60 1 -84 3 -52 2 32 2 81 0 110 -2 29 -3 3 -3 -58z"/>
<path d="M7334 5675 c-6 -24 -13 -28 -44 -26 -8 0 -5 -4 7 -10 17 -9 25 -8 36
3 19 19 31 58 18 58 -6 0 -13 -11 -17 -25z"/>
<path d="M7610 5640 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
<path d="M7561 5583 c1 -10 5 -29 9 -43 7 -21 8 -19 9 18 1 23 -3 42 -9 42 -5
0 -10 -8 -9 -17z"/>
<path d="M7328 5363 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6498 5913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6526 5905 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"/>
<path d="M6418 5903 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6551 5864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6654 5785 c-24 -18 -19 -18 31 0 33 12 36 14 14 15 -14 0 -34 -7
-45 -15z"/>
<path d="M6930 5796 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M7000 5790 c0 -6 7 -13 15 -16 8 -4 15 -16 16 -28 0 -20 1 -20 8 -1
5 11 6 26 3 34 -4 9 -1 12 7 7 6 -4 9 -16 6 -27 -7 -22 10 -26 19 -4 3 8 -2
21 -10 30 -17 17 -64 21 -64 5z"/>
<path d="M6995 5750 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7120 5540 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M7205 5510 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M7064 5395 c22 -17 46 -20 46 -6 0 5 -10 11 -22 15 -37 9 -44 6 -24
-9z"/>
<path d="M6640 5608 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M6606 5455 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M8065 5630 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8083 5595 c3 -24 -20 -31 -26 -8 -4 16 -5 16 -6 1 0 -10 8 -22 18
-28 16 -8 21 -6 26 11 4 12 2 26 -3 31 -7 7 -10 4 -9 -7z"/>
<path d="M8282 5585 c-1 -28 -6 -66 -12 -85 -6 -20 -6 -38 -1 -43 12 -12 24
83 18 138 -3 38 -4 37 -5 -10z"/>
<path d="M7895 5370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3845 5375 c23 -8 83 -10 79 -3 -3 4 -24 8 -47 7 -23 0 -37 -2 -32
-4z"/>
<path d="M5831 4744 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5358 4953 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5418 4953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5330 4930 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4820 2546 c0 -18 1 -19 20 -1 11 10 20 19 20 19 0 1 -9 2 -20 2 -13
0 -20 -7 -20 -20z"/>
<path d="M5458 2543 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6044 2295 c0 -36 4 -65 10 -65 5 0 6 3 3 6 -3 4 -2 15 4 26 11 20 3
98 -10 98 -4 0 -7 -29 -7 -65z"/>
<path d="M6335 2130 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M4823 2393 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5570 2309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5346 2295 c-3 -9 -6 -26 -5 -38 1 -16 4 -12 10 12 10 34 6 55 -5 26z"/>
<path d="M5234 2274 c-8 -29 7 -49 30 -41 21 8 21 53 0 61 -23 9 -23 8 -30
-20z m32 -9 c-4 -24 -16 -30 -16 -7 0 12 4 22 9 22 5 0 8 -7 7 -15z"/>
<path d="M5571 2214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5495 2170 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M5060 2345 c-7 -9 -10 -18 -7 -22 8 -7 37 15 37 28 0 14 -16 11 -30
-6z"/>
<path d="M5014 2276 c-9 -24 4 -48 23 -44 12 2 18 12 18 28 0 29 -32 41 -41
16z m30 -26 c-6 -6 -10 -2 -11 12 -2 16 1 18 10 9 7 -7 8 -14 1 -21z"/>
<path d="M4870 2280 c0 -44 3 -50 20 -50 29 0 47 42 32 75 -7 15 -20 25 -32
25 -17 0 -20 -6 -20 -50z m40 0 c0 -6 -6 -17 -13 -24 -10 -10 -12 -6 -12 24 0
30 2 34 12 24 7 -7 13 -18 13 -24z"/>
<path d="M4803 2280 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M5875 2290 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M4453 2143 c4 -3 2 -12 -4 -20 -9 -10 -7 -13 10 -13 11 0 21 2 21 4
0 14 -14 36 -23 36 -6 0 -7 -3 -4 -7z"/>
<path d="M4690 2070 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4423 2003 c-13 -2 -23 -8 -23 -13 0 -4 -11 -11 -24 -14 -34 -8 -97
-73 -123 -128 -16 -32 -23 -63 -23 -107 0 -107 45 -179 142 -231 53 -29 71
-33 132 -32 86 1 146 24 199 77 48 48 77 115 77 178 0 50 -18 117 -31 117 -7
0 7 -76 17 -92 3 -4 3 -8 -1 -8 -3 0 -10 11 -15 25 -6 15 -17 25 -29 25 -16 0
-20 -10 -26 -70 -8 -78 -18 -90 -78 -90 -44 0 -59 18 -68 87 -7 54 3 103 23
103 5 0 3 6 -4 14 -9 10 -15 11 -20 2 -4 -6 -8 3 -8 21 0 22 -6 36 -21 43 -16
9 -19 19 -17 48 3 32 7 38 33 43 35 8 -75 9 -112 2z m105 -184 c5 -102 -6
-179 -24 -179 -11 0 -14 23 -14 123 0 68 3 127 7 131 18 18 28 -7 31 -75z
m-234 25 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m167 -119
c-2 -97 -4 -112 -23 -127 -22 -19 -48 -24 -48 -9 0 5 7 11 15 15 30 11 17 36
-20 36 -54 0 -75 28 -75 98 1 84 19 101 104 99 l50 -2 -3 -110z"/>
<path d="M4360 1784 c-15 -38 -12 -100 5 -114 8 -6 23 -9 35 -5 18 6 20 15 20
76 0 67 -1 69 -25 69 -18 0 -28 -7 -35 -26z"/>
<path d="M4850 1899 c7 -11 14 -19 16 -16 7 7 -7 37 -17 37 -6 0 -5 -9 1 -21z"/>
<path d="M4603 1803 c-9 -3 -13 -27 -13 -69 0 -51 4 -66 16 -71 30 -11 49 11
52 60 5 68 -15 97 -55 80z"/>
<path d="M5578 1993 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5810 1909 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5465 1530 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6360 1489 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4730 8590 c-27 -6 -21 -7 28 -5 34 1 65 5 69 8 8 9 -51 7 -97 -3z"/>
<path d="M6088 8563 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4290 8490 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
<path d="M4200 8460 c-24 -8 -23 -8 8 -9 17 -1 32 4 32 9 0 11 -5 11 -40 0z"/>
<path d="M4123 8436 c-18 -6 -33 -14 -33 -18 0 -12 28 -10 36 3 3 6 18 8 33 5
14 -4 22 -3 16 1 -5 5 -12 11 -15 13 -3 3 -20 1 -37 -4z"/>
<path d="M4218 8433 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3893 8349 c-25 -9 -30 -19 -10 -19 15 0 43 17 37 23 -3 3 -15 1 -27
-4z"/>
<path d="M3831 8303 c1 -22 2 -23 6 -8 3 11 10 23 17 27 7 5 5 8 -6 8 -13 0
-18 -7 -17 -27z"/>
<path d="M6828 8323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6890 8290 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"/>
<path d="M6960 8260 c13 -11 30 -20 39 -19 13 0 14 2 1 6 -8 4 -21 12 -28 20
-7 7 -18 13 -24 13 -6 0 -1 -9 12 -20z"/>
<path d="M3610 8210 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3570 8190 c-14 -11 -20 -20 -15 -19 13 0 50 28 44 33 -2 3 -15 -4
-29 -14z"/>
<path d="M3518 8163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3460 8130 c-27 -17 -1 -23 32 -7 31 16 31 17 8 17 -14 0 -32 -4 -40
-10z"/>
<path d="M3426 8111 c-5 -8 -3 -16 5 -22 19 -12 52 -11 44 1 -3 5 -12 7 -19 4
-9 -3 -16 2 -18 13 -3 14 -5 15 -12 4z"/>
<path d="M7200 8099 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3410 8057 c-23 -11 -20 -12 34 -15 33 -2 64 2 69 7 8 8 6 11 -5 11
-8 0 -28 2 -44 5 -16 2 -40 -1 -54 -8z"/>
<path d="M3316 8039 c-13 -10 -28 -19 -33 -19 -4 0 -24 -14 -43 -30 -19 -16
-30 -30 -24 -30 6 0 17 6 23 14 6 8 21 16 31 19 32 8 24 -20 -10 -35 -34 -15
-37 -23 -14 -32 23 -9 24 -8 17 11 -5 13 -2 15 11 10 19 -7 40 32 30 57 -3 8
3 19 16 26 29 15 40 2 15 -17 -20 -15 -20 -15 2 -8 18 5 22 3 17 -9 -5 -13 2
-16 37 -16 39 1 42 2 27 14 -10 7 -15 16 -11 20 4 4 1 5 -7 2 -24 -9 -46 10
-32 26 18 22 -23 20 -52 -3z"/>
<path d="M4807 8033 c-4 -3 -2 -12 4 -19 8 -10 16 -10 35 -2 18 8 21 14 12 20
-16 9 -42 10 -51 1z"/>
<path d="M3853 8014 c0 -7 4 -11 8 -8 4 2 10 0 14 -6 3 -5 -3 -10 -14 -10 -11
0 -22 4 -25 8 -2 4 -22 9 -43 10 -28 2 -34 0 -23 -8 12 -8 11 -10 -7 -10 -13
0 -22 5 -20 13 1 7 -11 13 -32 15 -27 3 -33 0 -28 -12 5 -13 -2 -16 -41 -15
l-47 2 40 7 c34 6 29 7 -33 9 -53 1 -78 -3 -88 -14 -14 -13 8 -15 194 -15
l210 0 0 27 c0 21 -2 23 -10 10 -4 -9 -15 -14 -23 -11 -8 4 -13 9 -10 13 2 4
-2 8 -9 8 -7 0 -13 -6 -13 -13z"/>
<path d="M4860 8000 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5055 8000 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3970 7990 l-35 -8 37 1 c20 1 40 -2 44 -6 14 -14 26 -7 14 8 -7 8
-15 14 -19 14 -3 -1 -22 -5 -41 -9z"/>
<path d="M4960 7991 l-45 -6 64 -8 c35 -4 67 -5 69 -2 3 3 -8 5 -24 6 -29 1
-29 1 -4 9 31 10 8 10 -60 1z"/>
<path d="M3320 7974 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M7440 7984 c0 -8 43 -43 54 -44 5 0 3 7 -4 16 -15 17 -50 38 -50 28z"/>
<path d="M4640 7959 c0 -5 -4 -8 -8 -5 -4 2 -14 1 -22 -5 -12 -7 -11 -9 6 -9
12 0 27 7 34 15 9 10 9 15 1 15 -6 0 -11 -5 -11 -11z"/>
<path d="M4753 7963 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M3351 7941 c-10 -18 -113 -280 -156 -396 -12 -33 -50 -127 -83 -210
-33 -82 -88 -222 -122 -310 -34 -88 -90 -232 -125 -320 -74 -188 -121 -309
-164 -422 -18 -44 -38 -84 -46 -87 -8 -3 -54 -10 -102 -17 -285 -38 -394 58
-293 256 24 47 26 54 9 39 -33 -30 -105 -146 -127 -207 -41 -110 -20 -181 66
-220 40 -19 64 -22 167 -21 220 2 516 85 895 252 232 102 590 292 760 402 25
17 52 33 60 36 26 12 322 215 404 277 329 250 564 489 653 664 66 132 53 221
-42 268 -89 44 -328 28 -550 -36 -83 -25 -112 -45 -34 -23 66 17 248 29 311
20 31 -5 58 -18 84 -40 37 -32 39 -37 39 -93 -1 -49 -7 -70 -39 -128 -59 -107
-121 -181 -287 -341 -81 -77 -246 -214 -258 -214 -8 0 -59 123 -216 525 -47
121 -90 229 -95 240 -5 11 -18 43 -28 70 l-18 50 -326 3 c-313 2 -328 1 -337
-17z m428 -1015 l79 -199 -21 -17 c-53 -39 -413 -231 -420 -223 -3 2 31 93 75
201 196 482 186 458 198 446 5 -5 45 -99 89 -208z"/>
<path d="M3691 7054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3637 6985 c-18 -38 -16 -43 7 -19 14 14 15 12 10 -14 -5 -25 -1 -33
25 -51 l31 -22 0 21 c0 14 -8 24 -21 27 -14 4 -19 11 -16 25 3 10 0 30 -7 43
l-13 24 -16 -34z"/>
<path d="M3688 7004 c-8 -7 2 -44 12 -44 4 0 5 11 2 25 -5 27 -6 28 -14 19z"/>
<path d="M3722 6931 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z"/>
<path d="M3597 6893 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M3639 6896 c-10 -10 3 -85 16 -90 9 -3 14 7 17 35 2 26 -1 39 -8 39
-7 0 -14 5 -16 11 -2 5 -6 8 -9 5z"/>
<path d="M3730 6890 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3580 6849 c0 -3 -11 -33 -24 -67 -14 -34 -21 -62 -16 -62 5 0 16 23
25 50 9 28 18 50 19 50 2 0 1 -22 -2 -50 -2 -28 0 -50 5 -50 11 0 10 116 0
127 -4 3 -7 4 -7 2z"/>
<path d="M3790 6800 c0 -8 5 -22 10 -30 9 -13 10 -13 10 0 0 8 -5 22 -10 30
-9 13 -10 13 -10 0z"/>
<path d="M3508 6675 c-3 -11 -9 -29 -13 -40 -3 -11 1 -7 10 8 9 16 15 33 12
40 -2 6 -6 3 -9 -8z"/>
<path d="M3543 6633 c-13 -2 -23 -9 -23 -15 0 -6 10 -8 23 -4 12 3 28 6 35 6
6 0 12 5 12 10 0 10 -8 11 -47 3z"/>
<path d="M3630 6630 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3165 7937 c-14 -11 -15 -16 -5 -22 9 -5 11 -4 6 3 -4 7 -2 12 3 12
6 0 11 -5 11 -11 0 -8 5 -8 15 1 8 7 12 16 9 21 -7 12 -17 11 -39 -4z"/>
<path d="M5155 7940 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M4050 7935 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M3194 7908 c-4 -6 4 -21 20 -35 15 -13 25 -27 21 -32 -3 -5 -1 -12 5
-16 5 -3 10 -1 10 5 0 7 8 9 20 5 16 -5 20 -2 19 17 -1 17 -2 19 -6 5 -4 -16
-6 -15 -22 5 -10 13 -20 31 -24 41 -7 19 -32 23 -43 5z"/>
<path d="M4428 7913 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3094 7885 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z"/>
<path d="M4598 7863 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4683 7863 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4783 7863 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4649 7834 c-12 -15 -10 -16 19 -10 21 5 32 4 32 -4 0 -6 5 -8 10 -5
15 9 -7 35 -30 35 -10 0 -24 -7 -31 -16z"/>
<path d="M3031 7833 c-1 -6 -10 -14 -20 -18 -11 -3 -22 -12 -25 -21 -5 -12 -2
-13 17 -3 12 7 25 17 29 23 5 8 8 7 8 -1 0 -7 -9 -22 -20 -33 l-20 -20 20 0
c18 0 20 -7 20 -76 0 -67 2 -76 20 -81 18 -5 20 -14 20 -84 0 -46 -4 -79 -10
-79 -5 0 -10 -6 -10 -14 0 -8 -5 -18 -10 -21 -6 -4 -10 5 -9 22 1 15 -2 60 -6
98 l-7 71 -67 1 c-129 1 -131 1 -131 23 0 10 9 24 20 30 11 6 19 16 17 23 -1
7 12 12 37 13 42 1 60 19 29 27 -16 4 -15 6 5 12 12 4 22 11 22 16 0 15 -19
10 -40 -11 -11 -11 -24 -20 -30 -20 -16 0 -82 -69 -76 -79 4 -5 -1 -16 -9 -24
-8 -9 -15 -20 -15 -26 0 -6 -10 -16 -22 -22 -28 -15 -73 -61 -70 -72 1 -4 -5
-10 -13 -13 -19 -8 -20 -24 -1 -24 7 0 16 10 19 22 4 12 9 19 13 16 15 -16 -9
-46 -41 -52 -25 -5 -35 -12 -35 -26 0 -33 -11 -52 -24 -40 -9 7 -19 4 -34 -10
-27 -25 -41 -7 -16 21 18 20 44 26 44 9 0 -5 5 -10 10 -10 6 0 10 19 10 43 0
31 3 38 10 27 6 -9 10 -10 10 -3 0 6 -4 14 -9 18 -11 6 -25 -13 -26 -35 0 -8
-7 -14 -16 -12 -9 1 -29 -14 -45 -35 -15 -21 -34 -42 -41 -46 -18 -11 -16 -27
4 -27 13 0 14 -3 5 -12 -9 -9 -15 -9 -24 0 -9 9 -14 7 -24 -6 -8 -9 -13 -21
-13 -27 0 -5 5 -3 12 6 7 9 23 16 37 15 22 -1 25 3 22 27 -3 29 12 37 22 11 9
-23 7 -204 -1 -205 -5 -1 -39 -2 -76 -3 -52 -1 -71 2 -77 14 -7 11 -9 7 -10
-16 0 -17 -8 -38 -19 -48 -12 -11 -16 -25 -13 -41 5 -30 -20 -35 -45 -9 -15
15 -19 13 -48 -18 -18 -18 -31 -36 -29 -39 2 -4 -4 -13 -12 -20 -9 -8 -25 -33
-35 -56 -10 -24 -23 -43 -29 -43 -5 0 -10 -5 -10 -11 0 -19 45 -5 48 16 2 11
11 26 20 33 14 11 14 12 -2 12 -16 0 -16 2 -5 16 12 14 14 14 19 -3 4 -10 8
-70 9 -133 1 -63 5 -118 8 -123 2 -4 37 -5 76 -2 52 5 68 3 57 -4 -11 -8 -5
-9 23 -4 l37 6 0 -42 c0 -34 -3 -41 -19 -41 -18 0 -19 -8 -17 -117 l1 -118 63
3 c57 4 62 2 62 -17 0 -16 -6 -21 -27 -21 -22 0 -25 -2 -13 -10 23 -15 70 -12
70 4 0 8 -6 17 -12 19 -10 4 -10 6 0 6 7 1 12 15 12 36 0 34 1 35 39 35 34 0
42 -4 55 -32 9 -17 13 -40 10 -51 -4 -13 -2 -17 5 -13 15 10 14 56 -3 89 -8
15 -11 27 -6 27 5 0 12 -11 16 -25 3 -14 10 -25 15 -25 13 0 11 24 -3 39 -11
10 -8 12 11 9 15 -2 26 3 28 12 4 13 3 13 -7 0 -8 -11 -10 8 -5 80 9 142 11
150 45 150 16 0 31 4 35 9 3 6 6 43 7 83 1 46 4 65 9 53 6 -17 8 -17 8 -2 1
20 17 23 24 5 2 -7 2 11 -1 40 -4 47 -3 52 16 52 16 0 22 9 27 40 4 21 4 44 0
50 -4 6 -1 18 6 26 9 11 10 17 2 22 -7 4 -14 58 -19 128 -7 134 -6 138 54 139
l32 1 0 -52 c0 -29 3 -60 6 -69 6 -14 8 -13 18 7 7 13 9 25 4 28 -15 11 5 50
29 56 17 5 22 11 18 22 -8 21 5 103 15 97 5 -3 11 8 15 25 4 16 11 33 16 36 6
3 14 17 19 30 14 35 30 29 24 -8 -4 -27 -1 -24 16 17 38 89 57 159 63 235 2
23 24 57 32 49 2 -2 -4 -22 -12 -44 -28 -77 -23 -89 7 -15 33 83 39 116 15 84
-8 -10 -15 -14 -15 -8 0 5 -7 19 -16 30 -15 18 -15 17 -10 -13 6 -35 -6 -42
-56 -35 -18 3 -28 10 -28 21 0 25 -20 41 -51 41 -15 0 -30 6 -32 13 -4 8 -6 9
-6 0z m133 -260 c-4 -16 -12 -39 -19 -52 -12 -24 -12 -24 -19 13 -4 21 -5 41
-2 44 3 3 6 -3 6 -14 1 -15 4 -14 16 9 8 15 17 27 20 27 2 0 1 -12 -2 -27z
m-370 -38 c-4 -8 -14 -15 -23 -15 -14 1 -13 3 3 15 25 19 27 19 20 0z m-394
-485 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m-96 -56 c9 -3 16 -10 16 -16 0 -5 -4 -6 -10 -3 -5 3 -10 1 -10 -4 0 -6 -7
-11 -15 -11 -17 0 -18 2 -9 24 7 18 7 18 28 10z m96 -14 c0 -13 -7 -20 -20
-20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m364 -176 c18 -7
23 -64 6 -64 -5 0 -10 -9 -10 -20 0 -28 -36 -26 -44 3 -8 30 -8 72 1 80 8 9
27 9 47 1z"/>
<path d="M4087 7820 c3 -11 9 -20 13 -20 5 0 6 9 3 20 -3 11 -9 20 -13 20 -5
0 -6 -9 -3 -20z"/>
<path d="M4117 7820 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0
-7 -9 -4 -20z"/>
<path d="M5230 7825 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M4460 7820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4380 7804 c-24 -13 -24 -14 -4 -14 12 0 24 7 28 15 3 8 4 15 3 14
-1 -1 -13 -7 -27 -15z"/>
<path d="M4901 7768 c-1 -10 3 -18 8 -18 5 0 11 -10 13 -22 3 -18 5 -16 9 10
4 24 2 32 -7 28 -7 -3 -15 0 -18 7 -2 7 -5 4 -5 -5z"/>
<path d="M7760 7721 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z"/>
<path d="M7786 7694 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M4895 7660 c-4 -12 -5 -24 -2 -27 3 -2 8 5 12 17 4 12 5 24 2 27 -3
2 -8 -5 -12 -17z"/>
<path d="M2737 7578 c-37 -37 -52 -58 -42 -58 9 0 19 9 22 20 3 11 16 23 29
26 13 3 24 11 24 16 0 6 7 16 16 23 9 6 15 16 13 21 -2 5 -30 -17 -62 -48z"/>
<path d="M2863 7633 c-18 -7 -16 -23 2 -23 8 0 15 7 15 15 0 8 -1 15 -2 14 -2
0 -9 -3 -15 -6z"/>
<path d="M5185 7620 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5169 7604 c5 -3 7 -14 4 -24 -3 -12 0 -20 7 -20 15 0 9 43 -8 46 -7
1 -8 0 -3 -2z"/>
<path d="M7936 7566 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6 -12z"/>
<path d="M5098 7531 c-10 -10 -18 -24 -18 -32 0 -8 6 -4 16 9 8 12 20 20 25
17 5 -3 6 1 3 9 -3 9 -7 16 -8 15 0 0 -9 -8 -18 -18z"/>
<path d="M7922 7539 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M7962 7539 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M5209 7518 c-1 -2 -2 -20 -3 -41 -3 -39 7 -56 20 -36 4 8 3 9 -4 5
-17 -10 -15 0 4 30 16 23 15 24 -1 17 -14 -5 -17 -3 -12 10 3 9 4 17 2 17 -3
0 -5 -1 -6 -2z"/>
<path d="M2656 7494 c-4 -10 -1 -14 6 -12 15 5 23 28 10 28 -5 0 -13 -7 -16
-16z"/>
<path d="M8000 7507 c0 -4 8 -15 19 -26 10 -10 21 -25 23 -32 3 -8 9 -10 14
-6 4 4 -6 22 -24 40 -18 17 -32 28 -32 24z"/>
<path d="M4758 7476 c-20 -14 -24 -36 -8 -36 6 0 10 7 10 15 0 7 8 19 18 24 9
6 13 10 7 10 -5 0 -18 -6 -27 -13z"/>
<path d="M5105 7477 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M5215 7399 c-217 -277 -666 -634 -1190 -945 -655 -390 -1302 -649
-1662 -666 -40 -2 -75 -7 -78 -10 -4 -3 15 -14 42 -24 39 -16 74 -19 193 -18
158 1 241 16 480 91 243 75 661 263 933 419 54 31 100 54 101 52 2 -1 20 -43
40 -93 20 -49 41 -97 45 -105 7 -13 54 -15 315 -18 354 -3 327 -13 275 109
-17 41 -52 130 -79 199 -27 69 -58 149 -69 178 -12 29 -21 56 -21 61 0 5 35
35 78 67 301 225 571 507 646 676 27 60 42 118 29 117 -4 0 -39 -41 -78 -90z"/>
<path d="M5331 7480 c0 -8 4 -22 9 -30 12 -18 12 -2 0 25 -6 13 -9 15 -9 5z"/>
<path d="M5311 7454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8001 7454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5147 7424 c3 -8 9 -14 13 -14 4 0 13 6 19 14 9 11 7 14 -13 14 -17
0 -23 -5 -19 -14z"/>
<path d="M5122 7399 c1 -27 3 -29 16 -15 13 13 13 16 -1 30 -14 15 -16 13 -15
-15z"/>
<path d="M5313 7393 c-1 -18 3 -33 8 -33 5 0 9 9 9 19 0 11 5 23 10 26 7 4 9
-3 5 -22 -5 -21 -4 -24 4 -13 6 8 11 25 11 36 0 17 -6 22 -23 20 -19 -1 -23
-7 -24 -33z"/>
<path d="M3083 7378 c-6 -24 -14 -51 -18 -60 -4 -10 -3 -18 2 -18 5 0 14 19
20 43 6 23 14 50 18 60 4 9 3 17 -2 17 -5 0 -14 -19 -20 -42z"/>
<path d="M4990 7398 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"/>
<path d="M5030 7409 c0 -7 -10 -20 -22 -30 -11 -11 -16 -19 -10 -19 15 0 42
29 42 46 0 8 -2 14 -5 14 -3 0 -5 -5 -5 -11z"/>
<path d="M5173 7396 c-15 -13 -26 -27 -23 -30 3 -3 18 8 34 24 34 36 28 39
-11 6z"/>
<path d="M8087 7399 c-8 -31 4 -47 15 -19 5 13 5 27 0 32 -6 6 -12 0 -15 -13z"/>
<path d="M4296 7393 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4640 7399 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4680 7389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5286 7373 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M8112 7379 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M5036 7367 c-16 -12 -16 -14 -3 -26 23 -18 64 4 46 25 -14 17 -20 18
-43 1z"/>
<path d="M4305 7350 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4606 7345 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14 0z"/>
<path d="M5127 7349 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4630 7329 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4937 7304 c-19 -25 -23 -64 -7 -64 6 0 10 8 10 18 0 9 5 24 10 32 9
13 11 13 19 0 6 -8 6 -19 2 -25 -13 -16 -19 -65 -9 -71 5 -3 7 -13 3 -21 -5
-14 -3 -14 11 -2 10 8 12 15 5 17 -16 5 -13 48 5 67 14 13 14 18 2 33 -30 39
-33 40 -51 16z"/>
<path d="M3041 7304 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5064 7284 c4 -11 2 -15 -7 -11 -7 3 -13 -3 -13 -14 0 -17 2 -17 18
-2 20 18 24 43 7 43 -6 0 -8 -7 -5 -16z"/>
<path d="M4498 7259 c-21 -22 -18 -23 16 -8 14 7 23 16 20 20 -8 13 -17 10
-36 -12z"/>
<path d="M4850 7253 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M2433 7238 c-19 -25 -24 -38 -14 -38 11 0 35 39 28 46 -2 3 -9 -1
-14 -8z"/>
<path d="M4820 7225 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M2450 7215 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M4385 7210 c-3 -5 -1 -10 5 -10 6 0 8 -6 4 -12 -4 -7 -4 -10 1 -6 4
4 16 -1 25 -12 16 -18 19 -18 30 -5 11 13 10 14 -2 10 -10 -4 -24 4 -36 20
-12 15 -23 21 -27 15z"/>
<path d="M4880 7200 c0 -11 7 -20 15 -20 8 0 15 6 15 14 0 8 -7 16 -15 20 -10
4 -15 -1 -15 -14z"/>
<path d="M5167 7180 c-9 -13 -14 -26 -11 -29 3 -3 9 1 14 9 5 8 12 11 16 7 4
-3 1 -13 -6 -22 -9 -11 -9 -15 -1 -15 17 0 23 23 13 50 l-9 23 -16 -23z"/>
<path d="M2380 7168 c-8 -11 -22 -31 -30 -44 -8 -12 -25 -36 -38 -52 -21 -29
-21 -31 -4 -29 13 1 18 7 14 19 -3 13 1 18 17 18 11 0 22 6 23 13 0 6 2 17 2
24 1 6 10 20 20 32 10 11 16 25 14 31 -1 5 -10 0 -18 -12z"/>
<path d="M3007 7184 c-4 -4 -7 -19 -6 -33 l0 -26 10 24 c10 26 8 47 -4 35z"/>
<path d="M8290 7167 c0 -17 38 -53 47 -44 3 4 1 7 -5 7 -7 0 -12 5 -12 11 0 6
-7 17 -15 25 -13 13 -15 13 -15 1z"/>
<path d="M4350 7161 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M4752 7158 c-15 -15 -16 -38 -1 -38 5 0 8 4 4 9 -9 16 15 24 30 11
19 -16 19 -3 1 16 -18 17 -19 17 -34 2z"/>
<path d="M4865 7150 c2 -11 1 -19 -3 -18 -4 1 -20 -4 -35 -10 -30 -13 -34 -41
-7 -50 13 -5 13 -3 1 12 -18 22 -6 33 16 15 15 -12 16 -12 10 4 -5 14 -2 16
17 11 21 -5 23 -3 19 20 -3 15 -9 29 -14 31 -4 3 -6 -3 -4 -15z"/>
<path d="M2986 7103 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2920 7076 c0 -19 -6 -36 -12 -39 -7 -2 -10 -7 -7 -11 3 -3 14 -4 23
0 18 7 24 84 6 84 -5 0 -10 -15 -10 -34z"/>
<path d="M4855 7080 c-10 -11 -15 -20 -11 -20 4 0 16 9 28 20 12 11 17 20 11
20 -5 0 -18 -9 -28 -20z"/>
<path d="M4687 7083 c-9 -9 -9 -43 1 -43 4 0 8 11 8 25 2 27 1 29 -9 18z"/>
<path d="M8362 7065 c6 -14 15 -25 19 -25 5 0 3 11 -3 25 -6 14 -15 25 -19 25
-5 0 -3 -11 3 -25z"/>
<path d="M5071 7066 c-15 -18 2 -31 25 -18 13 7 13 10 1 20 -11 10 -17 9 -26
-2z"/>
<path d="M4620 7050 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M4765 7030 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2923 6974 c3 -18 -2 -33 -14 -45 -21 -21 -25 -39 -9 -39 12 0 44 60
36 68 -3 3 1 14 11 24 15 17 15 18 -7 18 -19 0 -22 -4 -17 -26z"/>
<path d="M4744 6978 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M4992 6983 c-6 -2 -10 -9 -6 -14 8 -14 21 -11 21 6 0 8 -1 15 -1 14
-1 0 -7 -3 -14 -6z"/>
<path d="M4550 6964 c0 -34 13 -44 55 -44 l43 0 -11 30 c-11 29 -27 41 -27 20
0 -5 -7 -10 -16 -10 -8 0 -13 4 -9 9 3 6 -4 9 -15 8 -11 -1 -20 -7 -20 -13z"/>
<path d="M4647 6949 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4508 6914 c-5 -4 -8 -16 -8 -26 0 -10 -4 -18 -10 -18 -5 0 -10 5
-10 10 0 6 -12 8 -27 4 -22 -4 -25 -3 -13 5 13 9 12 11 -4 11 -13 0 -16 -4
-11 -17 4 -10 7 -27 7 -38 -1 -18 0 -18 9 -2 12 21 39 23 39 2 0 -8 5 -15 10
-15 6 0 10 6 10 14 0 8 10 18 21 21 12 4 17 10 11 14 -5 3 -11 14 -13 24 -2
11 -7 15 -11 11z"/>
<path d="M4900 6900 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2891 6864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4580 6870 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4335 6849 c-26 -17 -27 -19 -9 -19 12 0 27 7 34 15 19 23 9 25 -25
4z"/>
<path d="M4393 6857 c4 -10 2 -17 -6 -17 -7 0 -18 -9 -25 -20 -12 -20 -12 -20
11 -5 22 14 25 14 30 1 4 -10 1 -13 -9 -9 -7 3 -14 1 -14 -5 0 -21 42 -19 68
3 2 2 -11 18 -29 36 -18 19 -30 26 -26 16z"/>
<path d="M4500 6816 c-8 -16 -18 -23 -29 -19 -13 4 -14 3 -2 -5 21 -15 70 13
71 41 0 17 -28 5 -40 -17z"/>
<path d="M2200 6810 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2129 6786 c-9 -11 -19 -32 -23 -46 -5 -19 -1 -16 17 12 13 21 27 35
30 31 4 -3 7 -1 7 5 0 21 -15 20 -31 -2z"/>
<path d="M2857 6776 c-4 -9 -11 -15 -15 -12 -5 3 -7 -7 -6 -22 2 -15 1 -21 -2
-14 -8 16 -24 16 -24 -1 0 -8 7 -14 16 -14 17 0 48 51 41 70 -2 6 -6 3 -10 -7z"/>
<path d="M4430 6783 c-17 -7 -30 -23 -30 -38 0 -5 4 -4 9 4 5 8 16 11 29 7 12
-4 23 -2 28 5 5 9 2 10 -10 6 -14 -5 -17 -3 -12 8 3 8 4 15 3 14 -1 0 -9 -3
-17 -6z"/>
<path d="M4720 6745 c-19 -14 -30 -25 -24 -25 10 0 73 49 63 50 -3 0 -20 -11
-39 -25z"/>
<path d="M2141 6737 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M4236 6719 c3 -5 -1 -9 -8 -9 -10 0 -10 -2 1 -9 17 -11 5 -55 -14
-47 -9 3 -12 0 -8 -10 4 -12 1 -13 -19 -5 -18 7 -25 6 -25 -4 0 -7 9 -11 21
-8 21 4 21 4 2 -11 -11 -9 -16 -16 -10 -16 18 1 62 45 69 70 4 14 15 30 23 36
13 9 11 12 -11 16 -15 3 -24 1 -21 -3z"/>
<path d="M4349 6711 c11 -7 10 -11 -6 -20 -11 -5 -24 -7 -28 -3 -5 4 -5 1 -1
-5 6 -10 14 -9 38 3 24 12 28 18 17 25 -8 5 -18 9 -24 9 -5 0 -4 -4 4 -9z"/>
<path d="M4114 6695 c-10 -8 -14 -15 -7 -15 6 0 15 5 18 11 4 6 13 8 21 5 8
-3 14 -1 14 4 0 15 -23 12 -46 -5z"/>
<path d="M4675 6690 c-3 -5 3 -10 14 -10 11 0 23 5 26 10 3 6 -3 10 -14 10
-11 0 -23 -4 -26 -10z"/>
<path d="M2057 6661 c-15 -30 -12 -81 4 -81 14 0 11 -17 -7 -34 -8 -9 -14 -26
-12 -38 2 -18 10 -23 33 -25 17 -1 48 -6 70 -13 62 -17 70 -17 71 6 1 11 11
34 23 50 20 27 20 32 8 69 l-14 40 -67 0 c-62 1 -69 -1 -81 -25 -10 -17 -14
-20 -15 -8 0 9 -6 19 -12 21 -10 4 -10 8 -2 14 12 8 29 53 20 53 -2 0 -11 -13
-19 -29z m164 -133 c-34 -31 -38 -44 -18 -51 6 -2 9 -8 4 -13 -11 -12 -34 17
-42 53 -5 27 -4 32 9 27 8 -3 22 -2 31 3 37 21 46 9 16 -19z m-141 -23 c0 -8
-4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M4120 6665 c-10 -12 -10 -15 3 -15 8 0 20 7 27 15 10 12 10 15 -3 15
-8 0 -20 -7 -27 -15z"/>
<path d="M2806 6645 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M4247 6638 c-10 -11 -16 -22 -14 -25 8 -7 47 21 47 34 0 18 -12 15
-33 -9z"/>
<path d="M2756 6604 c-4 -9 -4 -19 -1 -22 6 -6 35 18 35 29 0 16 -28 10 -34
-7z"/>
<path d="M4581 6604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4205 6600 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2020 6586 c0 -15 18 -31 26 -23 7 7 -7 37 -17 37 -5 0 -9 -6 -9 -14z"/>
<path d="M3940 6581 c0 -10 121 -34 129 -26 2 3 -3 5 -12 5 -9 0 -17 5 -17 11
0 7 -10 9 -24 5 -14 -3 -36 -2 -50 4 -14 5 -26 6 -26 1z"/>
<path d="M2280 6549 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2740 6553 c0 -14 19 -38 25 -32 8 8 -5 39 -16 39 -5 0 -9 -3 -9 -7z"/>
<path d="M3920 6551 c0 -4 -15 -13 -32 -19 -31 -9 -30 -10 25 -11 31 -1 57 -3
57 -5 0 -2 3 -11 6 -20 4 -11 0 -16 -12 -17 -18 0 -17 -1 1 -9 19 -7 19 -8 1
-15 -10 -5 -21 -3 -25 3 -9 14 -90 42 -96 32 -3 -4 12 -11 32 -15 45 -8 67
-27 50 -45 -7 -6 9 -1 34 11 28 15 42 27 35 31 -6 4 -7 8 -1 8 6 0 11 17 11
38 1 20 0 31 -3 25 -3 -10 -8 -9 -20 2 -15 16 -63 21 -63 6z"/>
<path d="M4114 6550 c-23 -9 -41 -30 -27 -30 4 0 22 9 38 20 31 21 26 25 -11
10z"/>
<path d="M4590 6559 c0 -4 7 -22 15 -40 8 -19 14 -27 14 -19 0 14 -28 72 -29
59z"/>
<path d="M1983 6513 c-11 -35 -5 -86 9 -77 6 4 8 -1 3 -15 -5 -17 -2 -21 14
-21 18 0 21 6 21 41 0 33 -3 40 -17 37 -24 -4 -27 2 -19 30 5 13 6 26 3 28 -3
3 -9 -8 -14 -23z"/>
<path d="M2736 6501 c4 -21 2 -36 -6 -41 -8 -5 -9 -10 -1 -14 15 -10 24 35 11
64 -9 22 -9 22 -4 -9z"/>
<path d="M3810 6490 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4620 6475 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M3720 6455 c-10 -12 -10 -15 3 -15 19 0 31 16 18 24 -5 3 -14 -1 -21
-9z"/>
<path d="M1946 6442 c-8 -14 21 -48 32 -38 3 4 2 10 -4 14 -6 4 -13 14 -16 22
-3 10 -6 11 -12 2z"/>
<path d="M3756 6425 c-4 -13 -2 -14 14 -5 23 13 26 20 6 20 -8 0 -16 -7 -20
-15z"/>
<path d="M3666 6414 c-3 -7 1 -15 8 -17 7 -3 12 -19 11 -36 -1 -17 1 -31 5
-31 4 0 23 16 43 35 35 34 35 35 12 35 -14 0 -25 4 -25 9 0 14 -48 19 -54 5z"/>
<path d="M3793 6392 c-18 -9 -33 -19 -33 -23 0 -4 12 -1 28 7 15 7 34 14 42
13 8 0 -2 -9 -22 -19 -20 -11 -40 -25 -43 -31 -4 -6 21 4 56 22 77 39 82 43
46 34 -17 -5 -27 -3 -27 4 0 14 -6 13 -47 -7z"/>
<path d="M2095 6390 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4698 6393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4660 6385 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2131 6364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3535 6361 c-4 -7 4 -11 19 -11 22 0 54 -32 49 -51 -1 -3 15 -4 35
-2 20 1 39 -2 42 -8 4 -6 12 -4 20 6 11 13 10 14 -5 9 -10 -4 -16 -3 -13 2 4
5 -12 9 -35 9 -35 0 -42 3 -47 25 -4 18 -14 26 -32 28 -14 2 -29 -2 -33 -7z"/>
<path d="M1906 6345 c-3 -8 -2 -25 3 -37 7 -19 9 -17 8 15 -1 41 -2 44 -11 22z"/>
<path d="M2365 6350 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M4700 6346 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M2286 6333 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3484 6343 c15 -4 16 -7 5 -15 -10 -7 -8 -8 9 -5 12 3 22 1 22 -3 0
-4 10 -6 22 -2 20 5 19 7 -12 18 -19 7 -42 12 -50 12 -9 0 -7 -2 4 -5z"/>
<path d="M4681 6324 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2640 6320 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1934 6311 c-2 -2 -4 -18 -4 -34 0 -17 -3 -27 -7 -24 -3 4 -13 1 -21
-5 -13 -11 -14 -8 -8 22 l7 35 -16 -28 c-13 -22 -14 -31 -5 -43 7 -7 16 -11
21 -8 5 3 9 -10 9 -28 0 -34 0 -34 45 -33 33 1 45 -2 45 -13 0 -11 3 -12 13
-3 8 6 17 8 20 4 4 -3 7 15 7 40 0 35 4 47 15 47 8 0 15 9 15 19 0 11 7 26 15
35 8 8 15 17 15 19 0 6 -160 4 -166 -2z"/>
<path d="M4704 6307 c-7 -8 -8 -22 -3 -35 12 -31 15 -28 15 13 0 19 0 35 0 35
-1 -1 -6 -6 -12 -13z"/>
<path d="M3479 6288 c-11 -10 -28 -18 -36 -18 -8 0 -12 -4 -8 -9 6 -11 78 -2
83 11 2 4 14 8 27 8 12 0 28 6 34 13 17 22 -73 16 -100 -5z"/>
<path d="M4740 6275 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"/>
<path d="M2611 6291 c-9 -6 -6 -8 13 -8 14 0 23 3 20 8 -7 11 -15 11 -33 0z"/>
<path d="M3370 6290 c-21 -7 -21 -8 -5 -9 11 0 27 4 35 9 18 11 5 11 -30 0z"/>
<path d="M3615 6270 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M8802 6260 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2475 6260 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
<path d="M2557 6248 c-2 -9 -32 -13 -100 -13 -54 0 -97 -4 -97 -8 0 -5 46 -7
103 -6 90 3 103 6 104 21 2 21 -3 24 -10 6z"/>
<path d="M3310 6242 c0 -14 11 -21 26 -15 8 3 11 9 8 14 -7 11 -34 12 -34 1z"/>
<path d="M3978 6243 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2091 6224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3539 6219 l-24 -20 30 17 c39 24 40 24 28 24 -6 0 -21 -9 -34 -21z"/>
<path d="M4719 6226 c6 -7 17 -31 25 -52 14 -36 15 -37 15 -11 1 16 5 26 10
23 9 -6 21 16 21 40 0 24 -20 16 -21 -8 l0 -23 -16 23 c-8 12 -22 22 -30 22
-12 0 -13 -3 -4 -14z"/>
<path d="M3479 6217 c8 -11 5 -17 -15 -26 -36 -16 -25 -23 13 -9 35 13 42 48
10 48 -15 0 -17 -3 -8 -13z"/>
<path d="M1857 6207 c-4 -10 -7 -26 -7 -35 1 -15 2 -15 11 1 6 10 9 25 7 35
-3 16 -4 15 -11 -1z"/>
<path d="M3935 6210 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2403 6203 c32 -2 81 -2 110 0 29 2 3 3 -58 3 -60 0 -84 -1 -52 -3z"/>
<path d="M1880 6183 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z"/>
<path d="M3095 6173 c-18 -14 -18 -15 -2 -9 10 3 31 6 45 6 21 0 24 2 12 10
-21 13 -32 12 -55 -7z"/>
<path d="M3189 6182 c11 -8 10 -11 -5 -15 -15 -4 -15 -5 -3 -6 9 0 19 -10 22
-21 6 -24 39 -26 58 -4 12 15 10 16 -19 10 -20 -5 -32 -4 -33 3 -1 6 -2 16 -3
23 0 6 -8 13 -16 15 -12 4 -13 3 -1 -5z"/>
<path d="M3905 6180 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3980 6180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3240 6170 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3305 6170 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4046 6163 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3280 6144 c0 -9 12 -9 41 -3 23 5 49 7 58 5 9 -3 22 -2 30 3 8 5
-16 8 -58 7 -46 -1 -71 -5 -71 -12z"/>
<path d="M1824 6114 c-6 -19 -8 -34 -3 -34 8 0 24 59 17 65 -2 2 -8 -12 -14
-31z"/>
<path d="M2091 6114 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4760 6119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2001 6104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3736 6111 c-3 -5 -18 -12 -33 -16 -14 -3 -58 -24 -97 -45 -65 -36
-68 -39 -43 -45 16 -4 38 -1 58 9 18 9 38 16 46 16 7 0 13 7 13 16 0 13 -4 12
-28 -5 -28 -19 -82 -38 -82 -28 0 7 105 64 134 73 27 9 64 34 49 34 -7 0 -14
-4 -17 -9z"/>
<path d="M2993 6103 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3280 6099 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2855 6090 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M3025 6064 c15 -14 30 -22 34 -18 3 4 0 9 -6 11 -7 3 -13 11 -13 19
0 8 -10 14 -21 14 -21 0 -20 -1 6 -26z"/>
<path d="M2090 6070 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3078 6073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3152 6068 c17 -6 34 -6 44 0 12 7 5 9 -27 9 -40 0 -41 -1 -17 -9z"/>
<path d="M2866 6061 c-4 -5 -2 -12 4 -16 21 -13 10 -44 -17 -48 -18 -2 -10 -5
19 -6 l47 -1 -10 29 c-12 35 -34 57 -43 42z"/>
<path d="M2715 6050 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3102 6045 c-26 -14 -67 -55 -55 -55 4 0 21 9 37 19 17 11 34 17 39
14 6 -4 7 -1 2 7 -4 7 -2 16 6 21 21 13 -3 9 -29 -6z"/>
<path d="M8880 6031 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M2601 6021 c-20 -13 9 -20 87 -22 83 -3 101 11 33 26 -26 5 -32 4
-26 -5 8 -13 -6 -12 -60 4 -11 4 -26 2 -34 -3z"/>
<path d="M3655 6010 c-13 -6 -15 -9 -5 -9 13 -1 13 -2 0 -11 -18 -12 -2 -12
25 0 11 5 14 9 7 9 -7 1 -10 6 -7 11 7 11 7 11 -20 0z"/>
<path d="M4093 6013 c4 -3 7 -13 7 -24 0 -14 7 -19 30 -19 28 0 36 -11 31 -42
0 -5 4 -8 9 -8 7 0 10 19 9 45 -3 44 -4 45 -35 45 -18 0 -38 2 -46 3 -7 2 -10
2 -5 0z m42 -23 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13
-4 16 -10z"/>
<path d="M4598 6013 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
<path d="M3495 5991 c-11 -9 -26 -27 -33 -39 -11 -20 -10 -22 15 -23 25 -2 25
-2 5 -6 -13 -3 -30 1 -39 9 -14 11 -15 11 -9 -1 7 -10 3 -12 -14 -6 -16 5 -20
4 -15 -5 4 -7 -1 -13 -14 -17 -44 -11 -18 -23 53 -23 83 0 106 13 86 49 -6 12
-17 21 -25 21 -20 0 -19 10 1 25 9 6 15 17 13 23 -3 6 -12 4 -24 -7z"/>
<path d="M3720 6002 c0 -6 -9 -14 -20 -17 -11 -3 -20 -15 -20 -26 0 -16 3 -17
19 -7 15 9 23 9 35 -1 14 -12 16 -10 16 12 0 13 -7 30 -15 37 -9 8 -15 8 -15
2z"/>
<path d="M2960 5991 c0 -6 -15 -11 -32 -13 l-33 -3 37 -6 c20 -2 35 -2 32 2
-2 4 4 12 13 18 17 10 17 10 1 11 -10 0 -18 -4 -18 -9z"/>
<path d="M3000 5990 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4060 5990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4240 5980 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M4305 5990 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3397 5953 c-16 -8 -26 -17 -24 -20 6 -5 67 24 67 32 0 8 -13 4 -43
-12z"/>
<path d="M2730 5950 c0 -6 30 -10 70 -10 40 0 70 4 70 10 0 6 -30 10 -70 10
-40 0 -70 -4 -70 -10z"/>
<path d="M2910 5950 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2588 5913 c-16 -2 -28 -9 -28 -15 0 -6 12 -8 31 -4 17 3 42 3 57 -1
24 -6 24 -7 7 -19 -11 -8 -14 -14 -9 -14 12 0 34 21 34 32 0 4 -10 8 -22 9
-22 1 -22 1 2 9 22 7 21 8 -10 8 -19 -1 -47 -3 -62 -5z"/>
<path d="M2700 5916 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2805 5910 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M4371 5893 c1 -20 3 -22 6 -9 4 15 7 16 14 5 6 -11 9 -8 9 9 0 13 -6
22 -15 22 -10 0 -15 -9 -14 -27z"/>
<path d="M4140 5878 c0 -36 4 -35 14 5 4 16 2 27 -4 27 -5 0 -10 -14 -10 -32z"/>
<path d="M3243 5890 c-28 -12 -30 -24 -3 -15 11 4 20 2 20 -4 0 -6 4 -11 9
-11 6 0 9 9 8 20 -1 22 -3 23 -34 10z"/>
<path d="M2574 5865 c-16 -12 -17 -14 -2 -15 25 0 49 11 42 21 -7 13 -17 11
-40 -6z"/>
<path d="M3520 5870 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4157 5846 c-15 -12 -17 -35 -17 -220 0 -137 3 -205 10 -201 7 4 8
78 6 206 -5 197 -5 200 16 208 12 4 17 10 12 13 -5 4 -17 1 -27 -6z"/>
<path d="M3523 5805 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M3790 5830 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4391 5835 c0 -5 4 -17 9 -25 7 -12 12 -12 22 -2 9 9 9 12 -4 12 -9
0 -19 6 -21 13 -3 6 -6 8 -6 2z"/>
<path d="M4423 5833 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4880 5830 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M2258 5813 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2313 5813 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4455 5810 c4 -6 14 -8 24 -5 9 2 31 1 49 -4 17 -5 32 -6 32 -1 0 10
-37 20 -78 20 -20 0 -31 -4 -27 -10z"/>
<path d="M4868 5798 c36 -6 68 -6 73 -1 4 4 -24 9 -64 9 l-72 1 63 -9z"/>
<path d="M2932 5783 c-6 -2 -9 -9 -6 -15 4 -7 9 -6 14 2 5 8 16 9 30 5 20 -6
21 -9 7 -17 -10 -5 -17 -16 -17 -23 0 -20 7 -19 32 5 12 11 29 20 37 21 19 1
-48 27 -69 27 -8 0 -21 -2 -28 -5z"/>
<path d="M2240 5736 c0 -10 52 -29 75 -28 10 1 24 -3 31 -9 6 -5 19 -6 29 -3
13 6 5 11 -31 21 -69 20 -104 26 -104 19z"/>
<path d="M1737 5687 c-4 -10 -7 -26 -7 -35 1 -15 2 -15 11 1 6 10 9 25 7 35
-3 16 -4 15 -11 -1z"/>
<path d="M9553 5683 c-3 -10 -15 -31 -26 -47 -11 -16 -21 -41 -22 -57 -2 -16
-8 -36 -15 -44 -9 -11 -10 -15 -1 -15 23 0 40 27 44 70 2 23 8 45 14 48 5 4
13 19 17 35 7 30 -1 37 -11 10z"/>
<path d="M4660 5639 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4420 5620 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
<path d="M8980 5620 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M1721 5614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4384 5604 c3 -8 6 -49 6 -90 0 -43 4 -74 10 -74 6 0 10 35 10 84 0
68 -3 85 -16 90 -12 5 -15 2 -10 -10z"/>
<path d="M9555 5610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1240 5562 l5 -47 3 39 c2 21 0 42 -5 47 -4 4 -6 -13 -3 -39z"/>
<path d="M3380 5590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M9431 5514 c-29 -36 -51 -67 -49 -69 7 -7 46 33 52 53 4 12 13 22 19
22 7 0 20 14 29 30 29 51 5 33 -51 -36z"/>
<path d="M1320 5560 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8991 5545 c1 -16 -2 -65 -5 -107 -3 -46 -2 -78 4 -78 5 0 11 19 13
43 l4 42 1 -42 c1 -24 7 -43 13 -43 6 0 8 11 5 28 -3 15 -8 56 -12 92 -7 71
-23 117 -23 65z"/>
<path d="M9540 5540 c0 -11 7 -20 15 -20 17 0 18 2 9 24 -9 23 -24 20 -24 -4z"/>
<path d="M3400 5545 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M1310 5530 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1360 5510 c15 -28 60 -77 60 -64 0 5 -17 27 -36 49 -25 28 -32 32
-24 15z"/>
<path d="M9542 5418 c-1 -42 -7 -89 -13 -105 -7 -19 -7 -29 0 -31 6 -2 11 -12
11 -23 0 -10 4 -19 9 -19 13 0 23 33 12 40 -6 3 -12 53 -14 110 -2 91 -3 95
-5 28z"/>
<path d="M9568 5415 c-1 -35 0 -75 4 -87 3 -13 6 16 7 65 1 102 -6 116 -11 22z"/>
<path d="M3770 5450 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M9420 5435 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M3402 5410 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M4169 5436 c-11 -11 3 -56 17 -56 9 0 14 11 13 33 l-2 32 -5 -30 -5
-30 -6 28 c-3 16 -9 26 -12 23z"/>
<path d="M9523 5400 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M9325 5390 c-16 -17 -23 -30 -16 -30 14 0 51 37 51 52 0 13 -4 10
-35 -22z"/>
<path d="M1445 5400 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M4498 5373 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M9248 5312 l-25 -29 26 0 c21 0 26 5 28 29 1 15 1 28 -1 28 -1 0 -14
-13 -28 -28z"/>
<path d="M1530 5297 c-12 -21 -12 -21 6 -7 10 8 20 15 22 16 2 1 -1 4 -5 7 -5
3 -15 -4 -23 -16z"/>
<path d="M1231 5268 c1 -7 5 -24 9 -38 7 -22 8 -21 9 13 1 20 -3 37 -9 37 -5
0 -10 -6 -9 -12z"/>
<path d="M9165 5230 c-16 -17 -25 -30 -21 -30 11 0 61 49 55 55 -3 2 -18 -9
-34 -25z"/>
<path d="M9042 5190 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M9548 5178 c-2 -13 -8 -22 -15 -21 -7 2 -11 -49 -10 -167 0 -259 14
-214 42 138 3 43 2 72 -4 72 -5 0 -11 -10 -13 -22z m-1 -105 c-3 -10 -5 -2 -5
17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-3 -90 c-9 -78 -14 -79 -14 -5 0 35 4
62 10 62 6 0 8 -21 4 -57z"/>
<path d="M9480 4800 c0 -40 4 -70 10 -70 6 0 10 30 10 70 0 40 -4 70 -10 70
-6 0 -10 -30 -10 -70z"/>
<path d="M1271 4784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9522 4780 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1352 4700 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M9476 4671 c-18 -55 -21 -111 -7 -111 5 0 7 18 4 40 -6 40 11 59 20
23 2 -10 2 1 -1 23 -2 24 1 44 7 48 13 9 15 26 2 26 -5 0 -16 -22 -25 -49z"/>
<path d="M1324 4585 c-6 -15 3 -95 14 -123 5 -13 7 -12 12 4 4 11 1 28 -7 41
-10 14 -12 33 -7 57 6 36 -1 49 -12 21z"/>
<path d="M1380 4480 c0 -40 4 -70 10 -70 6 0 10 30 10 70 0 40 -4 70 -10 70
-6 0 -10 -30 -10 -70z"/>
<path d="M1352 4410 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M9441 4364 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1382 4300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M9412 4280 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M9367 4203 c-13 -13 -7 -32 8 -27 8 4 15 12 15 20 0 15 -12 19 -23 7z"/>
<path d="M9365 4129 c-10 -42 -2 -45 11 -3 6 19 8 34 3 34 -4 0 -10 -14 -14
-31z"/>
<path d="M1451 4068 c0 -7 4 -22 9 -33 8 -18 9 -17 9 7 1 15 -3 30 -9 33 -5 3
-10 0 -9 -7z"/>
<path d="M1480 4064 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M9300 4065 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M1481 4008 c-9 -14 -9 -23 -1 -33 6 -7 17 -32 25 -56 l14 -44 -6 45
c-3 25 -9 47 -14 48 -12 5 -12 32 0 32 5 0 12 -6 14 -12 3 -7 6 -1 6 13 1 31
-21 34 -38 7z"/>
<path d="M9301 3956 c-7 -8 -14 -31 -17 -53 -2 -21 -2 -31 2 -23 3 8 9 24 14
35 22 53 22 67 1 41z"/>
<path d="M1540 3830 c0 -19 23 -59 31 -54 5 3 2 18 -6 35 -16 29 -25 37 -25
19z"/>
<path d="M9161 3618 c0 -28 2 -30 9 -13 12 28 12 45 0 45 -5 0 -10 -15 -9 -32z"/>
<path d="M1650 3571 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M9130 3559 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M9100 3500 c-11 -11 -20 -28 -20 -39 0 -10 -6 -24 -14 -30 -8 -7 -18
-25 -21 -41 -3 -16 -14 -31 -23 -33 -12 -3 -10 -5 6 -6 14 -1 22 5 22 15 0 9
8 28 18 42 24 35 63 112 57 112 -3 0 -14 -9 -25 -20z"/>
<path d="M1700 3491 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21 -5
3 -10 -3 -10 -14z"/>
<path d="M1735 3465 c-4 -11 -10 -23 -12 -27 -2 -4 4 -5 14 -1 15 6 16 4 5 -9
-9 -11 -10 -18 -2 -23 5 -3 10 -1 10 5 0 6 5 8 10 5 12 -8 13 8 1 21 -5 5 -11
18 -13 29 l-5 20 -8 -20z"/>
<path d="M1816 3326 c-15 -33 -15 -37 1 -63 21 -33 63 -35 63 -2 0 18 -19 25
-57 20 -7 -1 -13 3 -13 9 0 5 4 10 9 10 9 0 25 49 18 57 -3 2 -12 -12 -21 -31z"/>
<path d="M1780 3335 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M8996 3314 c-9 -22 3 -32 14 -14 12 19 12 30 1 30 -5 0 -12 -7 -15
-16z"/>
<path d="M8880 3190 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M8916 3184 c-11 -28 -6 -39 9 -20 8 11 15 23 15 28 0 14 -18 9 -24
-8z"/>
<path d="M8880 3148 c0 -7 5 -20 10 -28 8 -12 10 -11 10 7 0 12 -4 25 -10 28
-5 3 -10 0 -10 -7z"/>
<path d="M1973 3026 c9 -7 17 -19 17 -26 0 -7 3 -11 6 -7 11 11 -7 47 -24 47
-16 -1 -16 -2 1 -14z"/>
<path d="M8800 3004 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M2110 2865 c-11 -13 -9 -20 10 -40 28 -29 42 -24 15 5 -18 20 -18 21
1 35 16 12 16 14 3 15 -9 0 -22 -7 -29 -15z"/>
<path d="M8675 2870 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8700 2859 c-14 -12 -26 -16 -28 -10 -5 16 -22 13 -22 -4 0 -8 5 -15
10 -15 7 0 7 -6 0 -20 -7 -14 -7 -20 0 -20 5 0 10 7 10 15 0 8 4 15 8 15 5 0
18 14 30 30 26 35 25 36 -8 9z"/>
<path d="M2151 2844 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2270 2701 c0 -6 5 -13 11 -17 6 -3 9 -17 6 -30 -2 -13 2 -29 9 -35
11 -9 14 -8 15 2 3 36 -4 75 -15 81 -15 10 -26 10 -26 -1z"/>
<path d="M8561 2693 c-1 -7 -10 -13 -20 -13 -24 0 -33 -13 -25 -33 4 -12 10
-10 30 10 14 14 23 30 20 36 -3 9 -5 8 -5 0z"/>
<path d="M2333 2615 c-3 -9 3 -25 12 -35 10 -11 15 -20 10 -20 -4 0 -2 -7 5
-15 14 -17 30 -20 30 -5 0 6 -7 10 -15 10 -9 0 -12 6 -9 15 8 20 -4 65 -17 65
-5 0 -12 -7 -16 -15z"/>
<path d="M8422 2537 c-25 -27 -30 -46 -6 -27 15 13 38 50 31 50 -2 0 -13 -10
-25 -23z"/>
<path d="M2523 2463 c-4 -10 -11 -21 -16 -24 -5 -4 -5 -9 2 -13 6 -4 11 -3 11
2 0 5 9 12 20 15 23 6 27 37 5 37 -9 0 -19 -8 -22 -17z"/>
<path d="M8320 2434 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M8248 2393 c6 -2 12 -11 12 -19 0 -9 5 -12 10 -9 13 8 -6 35 -24 34
-7 0 -6 -2 2 -6z"/>
<path d="M2610 2300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2912 2071 c-10 -6 -10 -10 3 -21 13 -11 15 -10 9 4 -7 19 7 21 24 4
9 -9 12 -8 12 5 0 18 -26 23 -48 8z"/>
<path d="M2994 1998 c-3 -4 2 -8 10 -8 8 0 17 4 20 8 3 5 -2 9 -10 9 -8 0 -17
-4 -20 -9z"/>
<path d="M7700 1929 l-35 -31 31 15 c17 9 36 23 43 31 22 27 -2 17 -39 -15z"/>
<path d="M7600 1904 c0 -14 3 -15 18 -3 9 7 19 15 21 16 2 2 -5 3 -17 3 -14 0
-22 -6 -22 -16z"/>
<path d="M7628 1887 c-16 -6 -28 -14 -28 -19 0 -14 17 -9 41 12 27 22 26 22
-13 7z"/>
<path d="M3248 1824 c13 -9 26 -13 30 -9 8 8 -17 25 -38 25 -9 0 -6 -6 8 -16z"/>
<path d="M7520 1825 c0 -13 4 -14 23 -4 33 17 33 19 4 19 -18 0 -27 -5 -27
-15z"/>
<path d="M7468 1797 c-24 -9 -39 -27 -23 -27 12 0 55 24 55 32 0 4 -1 8 -2 7
-2 -1 -15 -6 -30 -12z"/>
<path d="M7376 1743 c10 -10 17 -10 38 1 27 13 27 13 -12 12 -31 0 -36 -3 -26
-13z"/>
<path d="M5871 1664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7162 1669 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M3600 1660 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M7213 1655 c-20 -22 -15 -25 17 -9 17 9 30 17 30 20 0 10 -36 2 -47
-11z"/>
<path d="M3688 1593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6960 1585 c0 -8 7 -15 15 -15 8 0 15 4 15 9 0 5 -7 11 -15 15 -9 3
-15 0 -15 -9z"/>
<path d="M7005 1589 c-11 -17 0 -20 21 -4 17 13 17 14 2 15 -9 0 -20 -5 -23
-11z"/>
<path d="M6517 1564 c-4 -4 -7 -17 -7 -28 1 -19 1 -20 11 -3 11 19 8 43 -4 31z"/>
<path d="M5922 1548 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12
-6z"/>
<path d="M6978 1553 c-10 -2 -18 -8 -18 -13 0 -5 14 -4 30 1 45 16 36 25 -12
12z"/>
<path d="M3875 1513 c11 -3 26 -10 33 -15 8 -7 12 -7 12 0 0 12 -25 22 -48 21
-13 -1 -12 -3 3 -6z"/>
<path d="M6880 1510 c0 -12 8 -12 35 0 18 8 17 9 -7 9 -16 1 -28 -3 -28 -9z"/>
<path d="M3940 1490 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M6172 1488 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M6240 1485 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M6010 1482 c0 -4 5 -13 12 -20 9 -9 8 -12 -7 -12 -10 0 -16 -3 -12
-6 13 -14 35 5 30 25 -5 20 -23 30 -23 13z"/>
<path d="M6440 1480 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M6570 1470 c0 -6 30 -10 70 -10 40 0 70 4 70 10 0 6 -30 10 -70 10
-40 0 -70 -4 -70 -10z"/>
<path d="M6403 1443 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6470 1440 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M4168 1433 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6523 1428 c1 -8 -7 -12 -20 -11 -13 2 -23 -2 -23 -7 0 -6 9 -10 21
-10 11 0 17 -4 14 -10 -3 -5 1 -10 9 -10 9 0 16 6 17 13 0 6 2 20 4 30 2 9 -3
17 -11 17 -8 0 -13 -6 -11 -12z"/>
<path d="M6596 1425 c-3 -8 -12 -15 -21 -15 -8 0 -15 -4 -15 -9 0 -13 58 11
64 26 3 7 -1 13 -8 13 -8 0 -16 -7 -20 -15z"/>
<path d="M6650 1430 c-11 -8 -7 -9 15 -4 37 8 45 14 19 14 -10 0 -26 -5 -34
-10z"/>
<path d="M4296 1403 c-3 -4 -25 -6 -48 -4 -50 3 -45 -7 8 -19 37 -9 64 0 64
21 0 11 -14 12 -24 2z"/>
<path d="M4413 1393 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4348 1353 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4620 1340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4504 1321 c4 -6 23 -13 44 -16 20 -3 49 -8 65 -10 15 -3 27 0 27 5
0 5 -17 12 -37 15 -21 3 -53 8 -71 11 -22 3 -32 2 -28 -5z"/>
<path d="M5603 1302 c-7 -27 -84 -42 -215 -42 -61 0 -109 -2 -107 -5 3 -2 74
-6 158 -9 108 -2 156 -7 161 -16 5 -7 13 -9 21 -5 18 12 -15 23 -65 24 -57 1
-41 7 39 16 57 5 68 4 58 -6 -22 -22 -15 -26 45 -23 104 5 282 27 282 35 0 8
-20 7 -123 -6 -15 -2 -25 1 -21 6 3 5 23 9 45 9 21 0 39 5 39 10 0 6 32 10 75
10 43 0 75 4 75 10 0 6 -84 10 -231 10 -216 0 -231 -1 -236 -18z m207 -42
c-18 -11 -127 -14 -134 -2 -2 4 30 9 72 10 55 2 73 0 62 -8z"/>
<path d="M5253 1273 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z"/>
<path d="M6013 1273 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5053 1253 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5128 1253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5200 1250 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M5086 1228 c-6 -4 70 -7 169 -6 192 2 181 6 -29 10 -70 2 -133 0
-140 -4z"/>
<path d="M5458 1223 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
